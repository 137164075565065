import axios from "axios";
import React, { useEffect, useState } from "react";
import bc_logo from '../../assets/css/images/boss_calls_logo_clr.png'
import InfoIcon from '@mui/icons-material/Info';
import { formatBcDate } from "../../classes/global-functions";
import {  useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export const Bosscalls = ()=>{
  const navigate = useNavigate();

const [mainData, setMainData] = useState([])
const [featuredCalls, setFeaturedCalls] = useState([])
  const fetchData = async ()=>{
    let response = await axios.get('https://helpdeskforhr.com/wp-content/themes/thrive-theme-child/boss-call-cert-app/bosscalls_data.php')

    let js = JSON.parse(response.data)

          console.log(js.documents)
          let filter  = js.documents.filter(i=> {return (new Date(i.dateOfCall) < new Date())})
          let featured  = js.documents.filter(i=> {return (new Date(i.dateOfCall) > new Date())})
          setMainData(filter)
          setFeaturedCalls(featured)
  }

  useEffect(()=>{

    fetchData()
  },[])
    return (
        <Box>
          <Box>
            
            <Typography sx={{fontSize:22}} variant="button"><b>UPCOMING BOSS CALLs</b></Typography>
            <img style={{float:'right'}} width={'55px'} src={bc_logo}/>
            </Box>
            <br />
            <Box sx={{  maxHeight:'550px', overflowY:'scroll'}}>
           
            {featuredCalls.map(i=>{
                   let date = new Date(i.dateOfCall);
                   let finalDate = date.setDate(date.getDate()+1);
              return (
                <Paper  sx={{marginTop:3, padding:2,}}>
               <Box key={i.id}>
            <Typography sx={{fontSize:18}} variant="button"><b>{i.bosscallname}</b></Typography>
            
            <Typography className="mt-2"><b>DATE: </b>{formatBcDate(finalDate)} 1pm - 2:30pm CENTRAL</Typography>
           
            <Typography className="mt-2"><b>DESCRIPTION:</b></Typography>

            <div style={{fontSize:'14px !important'}} dangerouslySetInnerHTML={{ __html: i.description }}></div>

            <Link target='_blank' href={i.registerLink}><Typography sx={{fontSize:18}} variant="button"><b>REGISTER HERE</b></Typography></Link>
           <hr></hr>
            </Box>
            </Paper>
       
              )
            })}
           
             </Box>
           <Box sx={{ marginTop:2}}>
           <Typography sx={{fontSize:22}} variant="button"><b>BOSS CALL LIBRARY</b></Typography>

            <TableContainer sx={{padding:'10px'}} component={Paper}>
              
<Table sx={{ minWidth: 650 }} aria-label="simple table">
<TableHead>
          <TableRow>
   
          <TableCell><b>TITLE</b></TableCell>
          <TableCell><b>DATE OF CALL</b></TableCell>
          <TableCell><b>Actions</b></TableCell>
          </TableRow>
        </TableHead>
      <TableBody>

       {
        mainData.sort((a,b)=>{ return new Date(b.dateOfCall) - new Date(a.dateOfCall) }).map(i=>{
          let date = new Date(i.dateOfCall);
          let finalDate = date.setDate(date.getDate()+1);
          if(i.videoLink.toUpperCase() != 'NA'){
            
          return (
            <TableRow key={i.id}>
              <TableCell>{i.bosscallname}</TableCell>
              <TableCell>{formatBcDate(finalDate)}</TableCell>
              <TableCell><IconButton  title="BOSS CALL DETAIL" onClick={()=>{
                          navigate('/bosscall-replay', { replace: true, state:{i} })
                          }} sx={{padding:0.5}}> <InfoIcon  htmlColor='#3FA9E6' sx={{cursor:'pointer',width:'30px'}} /></IconButton></TableCell>
            </TableRow>
          )
        }
        
      }
        )
        
       }
       
        
       </TableBody>
      </Table>
    </TableContainer>
    </Box>
        </Box>

    )
}
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";




export const UploadVideo = (props) =>{
const [loading, setLoading] = useState(false)

  


if(props.VideoFile != undefined){
    return(
        <Box sx={{display:'flex', marginBottom:3}}>
      <Typography>
        {props.CourseName ? props.CourseName : props.CourseName}
        </Typography><Button onClick={()=>{props.SetVideoFile(''); console.log(props.VideoFile)}} variant="contained" sx={{padding:.5, marginLeft:5}}>Change</Button>
      </Box>
    )
   
} else {
    
    return (
        <Box>
         <InputGroup>
        <Form.Control onChange={(e)=>{
            console.log()
            props.SetVideoFile(e.target.files[0]);
            const objectUrl = URL.createObjectURL(e.target.files[0])
            console.log(props.VideoFile)
            }} type='file'/>
       </InputGroup>   
        <Typography variant='caption'><b>Must be under 500MBs </b></Typography>
        </Box>
        
    )


}
}
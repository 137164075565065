import React,{useState, useRef, useEffect} from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Modal from 'react-bootstrap/Modal';
import Form from "react-bootstrap/Form";
import { courseData } from "../data/CourseData";
import { audioCourseData } from "../data/CourseData";
import Table from "react-bootstrap/Table";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { LearnerTransferList } from "../components/LearnerTransferList";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API } from "../controllers/API";
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import InfoIcon from '@mui/icons-material/Info';
import { formatDate } from "../classes/global-functions";
import { Box, CircularProgress } from "@mui/material";
import SearchBox from "../components/SearchInput";

export const TrainingManager = ()=>{
  const data = JSON.parse(sessionStorage.getItem('profile-data'))
  const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [activeEventKey, setActiveEventKey] = useState("");
    const accordElem = useRef(null);
    const handleClose = () => {setShow(false); setCourse('')}
    const handleShow = () => navigate('/training-setup', { replace: true });;
    const [learnerList, setLearnerList] = useState([[{"name":"null","email":"null"}]])
   const [course, setCourse] = useState('')
   const [mainData, setMainData] = useState([])
   const [archivedTrainings, setArchivedTrainings] = useState([])
   const [showArchivedTrainings, setShowArchivedTrainings] = useState(false);
   const [activeTrainings, setActiveTrainings] = useState([])
   const [currentTrainings, setCurrentTrainings] = useState([])
   const [loading, setLoading] = React.useState(true);
   const [update, setUpdate] = useState(true);
   const [searchValue, setSearchValue] = useState('')
   

// Intial Loading
useEffect(()=>{
  async function fetchData() {
 const response = await axios.post(API + 'get-data',{id:data.id})
 
 
        console.log(response.data)
        setMainData(response.data)
        setActiveTrainings(response.data.activeTrainings)
        setArchivedTrainings(response.data.archivedTrainings)
        setCurrentTrainings(response.data.activeTrainings)
        setLoading(false)
  }
  fetchData()
},[])


//Update Data 
useEffect(()=>{
  async function fetchData() {
 const response = await axios.post(API + 'get-data',{id:data.id})
 
 
        console.log(response.data)
        setMainData(response.data)
        setActiveTrainings(response.data.activeTrainings)
        setArchivedTrainings(response.data.archivedTrainings)
        setCurrentTrainings(response.data.activeTrainings)
        setLoading(false)

        if(showArchivedTrainings){
          setCurrentTrainings(response.data.archivedTrainings)
        
        }
  }
  fetchData()
  
},[update])


const DeleteTraining = async (a)=>{
  let confirmDelete = window.confirm('Are you sure you would like to DELETE this training. This action will delete the entire training and there will be now way to recover it.') 
  console.log(showArchivedTrainings)
  if(confirmDelete) {
    let resp = await axios.post(API+'delete-training', {userId:data.id, trainingId: a,type: showArchivedTrainings ? 'archived' : 'active'})
    console.log(resp)
    if(resp.data === 'error'){
      window.alert('The deletion of the training failed. Please try again.')
    } else {
      
      setUpdate(!update)
   
    }
  }
}


const ArchiveTraining = async (training)=>{
  let confirmArchive = window.confirm('Are you sure you would like to Archive this training. This will move this training to the archived training section and will not be able to be returned to the acitve training section.') 

  if(confirmArchive) {
    let resp = await axios.post(API+'archive-training', {userId:data.id, training: training})
    console.log(resp)
    if(resp.data === 'error'){
      window.alert('Something went wrong. Please try again.')
    } else {
      setUpdate(!update)
    }
  }
}
//
if(!loading){
  
    return (
       <div>
        <div >
          <Grid container>
          <Grid container spacing={2}>
        <Grid item xs={6}>
        <Button  variant="contained" onClick={()=>{
          setShowArchivedTrainings(!showArchivedTrainings);
          if(!showArchivedTrainings){
            setCurrentTrainings(archivedTrainings)
          } else {
            setCurrentTrainings(activeTrainings)
          }
          
        }}>
       <b>Show {showArchivedTrainings ?'Active':'Archived'} Trainings</b>
      </Button>
        </Grid>
        <Grid item xs={6}>
     
         <Button sx={{float:'right'}} variant="contained" onClick={handleShow}>
       <b>+ Assign New Training</b>
      </Button>
        </Grid>
      
      </Grid>
       
      </Grid>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop:3 }}>
      <SearchBox
      onChange={(e)=>setSearchValue(e.target.value)}
      value={searchValue}
      placeholder="Search"
      />
    </Box>
      </div>
      
   
      <TableContainer sx={{padding:'10px', marginTop:2}} component={Paper}>
        
<Table sx={{ minWidth: 650 }} aria-label="simple table">
<TableHead>
          <TableRow>
   
          <TableCell><Typography variant="button"><b>Training Name</b></Typography></TableCell>
          <TableCell><Typography variant="button"><b>Date Created</b></Typography></TableCell>
          <TableCell><Typography variant="button"><b>Training Type</b></Typography></TableCell>
          <TableCell><Typography variant="button"><b>Status</b></Typography></TableCell>
          <TableCell><Typography variant="button"><b>Progress</b></Typography></TableCell>
          <TableCell align="center"><Typography variant="button"><b>Actions</b></Typography></TableCell>
          </TableRow>
        </TableHead>
      <TableBody>

        {
          currentTrainings.filter((i) =>
          i.course.courseName.toLowerCase().includes(searchValue.toLowerCase()) ||
          formatDate(i.dateCreated).includes(searchValue.toLowerCase()) ||
          i.type.toLowerCase().includes(searchValue.toLowerCase()) 
        ).map(i=>{
            let status = []
            console.log(i.learners)
            i.learners.forEach(o=>{
              if(o.status === 'complete'){
                status.push('c')
              }
            })
            return (

              <TableRow>
    
              <TableCell>{i.course.courseName}</TableCell>
              <TableCell>{<Typography variant="button">{formatDate(i.dateCreated)}</Typography>}</TableCell>
              <TableCell>{i.type === 'audio' && i.type != 'bosscall' ? <Typography variant="button">Audio</Typography> :i.type === 'module' && i.type != 'bosscall' ?  <Typography variant="button">Module Learning</Typography>:  i.type === 'bosscalll'?  <Typography variant="button">Video</Typography>:  <Typography variant="button">Video</Typography>}</TableCell>
              <TableCell>{status.length < i.learners.length ? <Typography sx={{color: 'orange', fontWeight:'bold'}} variant="button">In Progress</Typography> : <Typography sx={{color:'#199A71', fontWeight:'bold'}} variant="button">Complete √</Typography>}</TableCell>   
              <TableCell>{status.length}/{i.learners.length}</TableCell>
                      <TableCell align="center">
                    <Tooltip title='Training Details' ><IconButton  onClick={()=>{
                            navigate('/training-details', { replace: true, state:{i} })
                          }} sx={{padding:0.5}}> <InfoIcon  htmlColor='#3FA9E6' sx={{cursor:'pointer',width:'30px'}} /></IconButton></Tooltip> 
                  
                  <Tooltip title='Delete Training' ><IconButton sx={{padding:0.5}} onClick={()=>DeleteTraining(i.id)}> <DeleteIcon  htmlColor='tomato' sx={{cursor:'pointer',width:'30px'}} /></IconButton></Tooltip> 
                  <Tooltip title='Archive Training' ><IconButton sx={{padding:0.5, display:showArchivedTrainings?'none':'inline'}} onClick={()=>ArchiveTraining(i)}> <ArchiveIcon htmlColor='#3FA9E6'  sx={{cursor:'pointer',width:'30px'}} /></IconButton></Tooltip> 
  
                      </TableCell>
                    </TableRow>

            
            )
          })  
        }
       
        
       </TableBody>
      </Table>
    </TableContainer>

      <Modal size="lg" style={{zIndex:100000}} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Training Setup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group>
                <Form.Label><Typography variant="subtitle2">Select the Training you would like to conduct:</Typography></Form.Label>
            <Form.Select onChange={(e)=>{ 
              let val = e.target.value;

              console.log(JSON.parse(val))
              setCourse(JSON.parse(val))
             
            }}>
                <option>----</option>
                <optgroup label="Video Trainings">
                    {courseData.map(i=>{
                        return (<option value={JSON.stringify(i)}>{i.courseName}</option>)
                    })}

                </optgroup>

                <optgroup label="Audio Trainings">
                {audioCourseData.map(i=>{
                        return (<option>{i.courseName}</option>)
                    })}
                </optgroup>
               
            </Form.Select>
            </Form.Group>
            <div style={{display:course != ''?'block':'none'}}>
       <hr></hr>
       <Typography variant="button" sx={{fontSize:16}}><b>Course Details</b></Typography>
       <hr></hr>
       <Form.Group>
        <Form.Label><Typography variant="button"><b>Course Name</b></Typography></Form.Label>
        <br></br>
        <Form.Label><Typography variant="p">{course.courseName}</Typography></Form.Label>
       </Form.Group>

       <Form.Group>
        <Form.Label><Typography variant="button"><b>Course Time</b></Typography></Form.Label>
        <br></br>
        <Form.Label><Typography variant="p">{course.runtime}</Typography></Form.Label>
       </Form.Group>

       <Form.Group>
        <Form.Label><Typography variant="button"><b>Course Description</b></Typography></Form.Label>
        <br></br>
        <Form.Label><div dangerouslySetInnerHTML={{__html:course.courseDescription}}></div></Form.Label>
       </Form.Group>
       <hr></hr>
       </div>
        <Form.Group style={{marginTop:'10px'}}>
           
           <Accordion>
           <Accordion.Item eventKey="0">
        <Accordion.Header><b>LEARNERS</b></Accordion.Header>
        <Accordion.Body>
        <Form.Group className="mb-3 mt-3">
      <Form.Label><b>Select Learners:</b></Form.Label>
      <p>Click a person’s name to add them as a learner. If you would like to remove a person you can click their name in the right column.</p>
       <LearnerTransferList/>
      </Form.Group>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header onClick={()=>{
          if(learnerList != null){
            setLearnerList(JSON.parse("["+window.learnerList+"]")); 
            // console.log(reviewers)
            
            // setUpdate(!update)
          }
         
        }}><b>FINALIZE</b></Accordion.Header>
        <Accordion.Body>
        <hr></hr>
        <Typography variant="button" sx={{fontSize:16}}><b>Training Options</b></Typography>

        <hr></hr>
        <Form.Check 
        type="switch"
        id="custom-switch"
        label={<Typography variant="subtitle2"><b>Require the learner to watch or listen to the entire training without skipping any parts.</b></Typography>}
      />   
           <Typography sx={{fontSize:14}} variant="caption">This will disable the seek function on the video portion of the training. Instead a percentage will be used to monitor the progress of the training. The learner will be able to save their place and return if they can't finish the training in one sitting.</Typography>

            <hr></hr>

        <Form.Check 
        type="switch"
        id="custom-switch"
        label={<Typography variant="subtitle2"><b>Send Trainings Immdiately upon creation.</b></Typography>}
      />   
     <Typography sx={{fontSize:14}} variant="caption">If you leave this switch off you can mannually send the trainings to each person in the <b>TRAINING SETUP</b> section by clicking the email icon beside their name.</Typography>
            <hr></hr>

        
            <Typography variant="button"><b>Learners</b></Typography><br></br>
            <Typography sx={{fontSize:14}} variant="caption">Confirm the list of people you would like to include in the training. You can change this by going to the learners section and click on their name to remove them from the training.</Typography>
            <TableContainer sx={{padding:'10px', marginTop:5}} component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
<TableHead>
          <TableRow>
   
          <TableCell>Name</TableCell>
          <TableCell>Email</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
       {learnerList[0].map(i=>{
        return(
          <TableRow>
      
          <TableCell>{i.name}</TableCell>
          <TableCell>{i.email}</TableCell>
        </TableRow>
        )
       })}
        </TableBody>
      </Table>
    </TableContainer>
            <hr></hr>
            <Form.Group >
            <Button variant="contained">View Demo of Training</Button>
          
            <Button sx={{float:'right'}} variant="contained">Assign and Send Trainings</Button>
           </Form.Group>
         
        </Accordion.Body>
      </Accordion.Item>
     
           </Accordion>
           </Form.Group>
          
        </Modal.Body>
        
      </Modal>
       </div>
    )

} else {
  return (<Box sx={{textAlign:'center', marginTop:20}}>
  <CircularProgress/>
  <Typography><b>Loading</b></Typography>
</Box>)
}
}


import React, { useEffect, useState } from "react";
import { DocumentCard } from "../components/DocumentCard";
import { VideoCard } from "../components/VideoCard";
import {courseData} from '../data/CourseData'
import { TrainingDetail } from "../subscreens/TrainingDetail";
import SearchBox from "../components/SearchInput";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import '../assets/css/table-item-style.css'
import ViewToggle from "../components/training-builder-components/ViewToggle";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Avatar, makeStyles } from '@mui/material';

export const VideoTrainingLibrary = (props)=>{
  const navigate = useNavigate();
const [library, setLibrary] = useState(true)
const [detail, setDetail] = useState(<p>Mason</p>)
const [searchValue, setSearchValue] = useState('')
const [view, setView] = useState('cards');

const handleViewChange = (event, newView) => {
  setView(newView);
  console.log(newView);
};

const ClickBack = ()=>{
    console.log('ClickBack')
    setDetail('')
     setLibrary(true)
}
    const ShowTraining = (a,b,c,d,e,f,g,h)=>{
        console.log(a)
        console.log(b)
        window.scrollTo(0, 0)
        setDetail(<TrainingDetail 
            ClickBack={()=>ClickBack()}
            CourseName={a}
            CourseDescription={`${b}`}
            Image={c}
            Runtime={d}
            VideoUrl={f}
            VideoDownloadLink={g}
            AudioDownloadLink={h}
            Documents={e.map((i)=>{
                let color;
                if(i.fileType.toUpperCase() == 'PPTX'){
                    color = '#C24020'
                } 

                if(i.fileType.toUpperCase() == 'PDF'){
                    color = '#F31000'
                }

                if(i.fileType.toUpperCase() == 'DOCX'){
                    color = '#0F3C8D'
                }

                if(i.fileType.toUpperCase() == 'VTT'){
                    color = '#4648CF'
                }
                
                return (
                <DocumentCard
                DocumentName={i.documentName}
                FileType={i.fileType.toUpperCase()}
                DocumentColor={color}
                LinkUrl={i.link}
                DocumentTitle={`${a} - ${i.documentName}`}
                />)
            })}
            />)
         setLibrary(false)
    }
    if(library){
        return (
            <div>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',  marginBottom:3 }}>
      <SearchBox
      onChange={(e)=>setSearchValue(e.target.value)}
      value={searchValue}
      placeholder="Search"
      />
       <ViewToggle View={view} onChange={handleViewChange} />
    </Box>
         {
 view === 'cards' ? (
  <div>
                <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center' }}>

                {courseData.filter((i) =>
          i.courseName.toLowerCase().includes(searchValue.toLowerCase()) 
          
        ).map((i)=>{
                return (<VideoCard  
                    ClickCard={()=>navigate('/training-library', { replace: true, state:{state: i, library:'video'} })} Image={i.poster}/>)
               })} 


  </div>
  </div>
 ) : (
  <div>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell><Typography variant="body2"><b>COURSE NAME</b></Typography></TableCell>
            <TableCell><Typography variant="body2"><b>RUNTIME</b></Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {courseData.filter((i) =>
          i.courseName.toLowerCase().includes(searchValue.toLowerCase()) 
          
        ).map((item, index) => (
            <TableRow onClick={()=>navigate('/training-library', { replace: true, state:{state: item, library:'video'} })} className="table-item" key={index}>
              <TableCell>
                <Avatar sx={{ width: 125, height: 'auto' }} alt={`Image ${index + 1}`} variant="rounded"  src={item.poster} />
              </TableCell>
              <TableCell>{item.courseName}</TableCell>
              <TableCell>{item.runtime}</TableCell>
            </TableRow>
          ))}

        {/* {mainData.customTrainings.filter((i) =>
            i.courseName.toLowerCase().includes(searchValue.toLowerCase()) 
            
          ).map((item, index) => {
            if(item.type === 'single-audio'){
           return (
            <TableRow onClick={()=>handleShow(item)} className="table-item" key={index}>
              <TableCell>
                <Avatar sx={{ width: 125, height: 'auto' }} alt={`Image ${index + 1}`} variant="rounded"  src={item.poster} />
              </TableCell>
              <TableCell>{item.courseName}</TableCell>
              <TableCell>{item.runtime}</TableCell>
            </TableRow>
          )}})} */}
        </TableBody>
      </Table>
    </TableContainer>
  </div>
 )
}
            </div>
        )
    } else {
        return (
            detail
        )
       
    }
    
}
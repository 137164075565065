import React from "react";
import ReactPlayer from 'react-player/vimeo'
import '../assets/css/video-react.css'

export const VideoPlayer = (props)=>{

    return( 
        <div style={{width:'100%', height:'100%'}}>
        <ReactPlayer 
        controls={true}
        style={{position:'relative'}}
        width='100%'
       height='100%'
        url={props.Url} />
      </div>
    )
}
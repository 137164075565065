import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import uuid from "react-uuid";
import axios from "axios";
import { API } from "../../controllers/API";
import ModuleListItem from "../../components/module-components/ModuleListItem";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '../../assets/css/training-builder.css'
import ProgressModal from "../../components/training-builder-components/ProgressModal";
import { FileHolder } from "../../components/training-builder-components/FileHolder";
import { DeleteVideoVimeo } from "../../classes/TrainingBuilderFunctions/Delete";



const customConfiguration = {
  toolbar: {
    items: [
      'heading',
      '|',
      'bold',
      'italic',
      'link',
      'bulletedList',
      'numberedList',
      'blockQuote',
      'undo',
      'redo',
    ],
  },
};



export const EditModuleBuilder = (props)=>{
  const date = new Date()
    const [trainingId, setTrainingId] = useState(sessionStorage.getItem('trainingId'))
    const data = JSON.parse(sessionStorage.getItem('profile-data'))
    const courseName =  sessionStorage.getItem('courseName')
    const [shouldRunEffect, setShouldRunEffect] = useState(false);
    const [modules, setModules] = useState(props.Modules);
   const [fileName, setFileName] = useState('')
    // Module Variables
const [editorData, setEditorData] = useState(() => {
    if (modules.length > 0) {
      return modules[0].content;
    } else {
      return '';
    }
  });
  const [modulesUpdate, setModulesUpdate] = useState(true)
const [progressArray, setProgressArray] = useState([]);
const [showProgress, setShowProgress] = useState(false);
const [trainingData, setTrainingData] = useState()
const [moduleNumber, setModuleNumber] = useState(1)
const [moduleType, setModuleType] = useState(() => {
    if (modules.length > 0) {
      return modules[0].type;
    } else {
      return 'text';
    }
  })
const [progress,setProgress] = useState(0)
const [openProgress,setOpenProgress] = useState(false)
function capitalizeFirstLetter(str) {
    // Check if the input is not an empty string
    if (str.length === 0) return str;
  
    // Capitalize the first letter and concatenate the rest of the string
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const modList = useRef(null);
  const selectRef = useRef(null);
  const fileInputRef = useRef(null);
  const [moduleTitle, setModuleTitle] = useState(() => {
    if (modules.length > 0) {
      return modules[0].moduleTitle;
    } else {
      return '';
    }
  })
const [update, setUpdate] = useState(true)
const [checkComplete, setCheckComplete] = useState(true)
const [showFileInput, setShowFileInput] = useState(true)
const [selectedFile, setSelectedFile] = useState(null);

function isFile(variable) {
  return variable instanceof File;
}
//   useEffect(()=>{

//     async function fetchData() {
//       const response = await axios.post(API + 'get-data',{id:data.id})
//       console.log(trainingId)
      
//              console.log(response.data)

//              setTrainingData(response.data)
           
//              console.log(response.data)
//        }
//        fetchData()
//   },[update])

useEffect(()=>{
 modList.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
},[modulesUpdate])

  const checkCompletelion = (a)=>{
    setUpdate(!update)
    setProgress(progressArray.length/modules.length * 100)
    console.log(trainingData)
    setTimeout(()=>{
      if(a === modules.length){
        setOpenProgress(false);
      
        

        axios.post(API + 'get-data',{id:data.id})
    .then((response) => {
      if(response === 'error'){
        alert('Something went wrong. Try Again.')
      } else {
        let userData = response.data;
        console.log(userData)
      let filter =  userData.customTrainings.filter(i=>{
        return i.id === props.TrainingId;
      })

      filter[0].courseData = modules;
      filter[0].dateUpdated = date;

      axios.post(API+'update-training',{id:data.id, training: filter[0]})
      .then(async (resp)=>{
        if(resp === 'error'){
          alert('Something went wrong. Try Again.')
        } else {
          if(modules.length > 0 && modules[0].moduleTitle !== '' && filter[0].quiz.length > 0){
            let changeStatus = await axios.post(API+'change-course-status', {id:data.id,trainingId:props.TrainingId, status: true})
            if(changeStatus === 'error'){
              window.alert('Something went wrong. Please try again.')
            } else {
            
            }
          } else {
              
          }
          alert('Course Updated!')
          setProgress(0)
          setProgressArray([])
        }
        

      })
      }
      
    })

        

        
      }
    },500)
  
  }
  useEffect(()=>{
    console.log(modules[currentModule])
  },[])

  useEffect(()=>{
   if(shouldRunEffect){
    
    setModules(prevMods =>
      prevMods.map(item =>
      item.id === modules[currentModule].id ? { ...item, moduleTitle } : item
      )
    )


   }
    console.log(modules)
  },[moduleTitle])


  useEffect(()=>{
    if(shouldRunEffect){
    setModules(prevMods =>
      prevMods.map(item =>
      item.id === modules[currentModule].id ? { ...item, content: editorData } : item
      )
    )
      }
    console.log(modules)
  },[editorData])


  useEffect(()=>{

    if(shouldRunEffect){
  
   handleClearFile()
   if(moduleType === 'audio'){
    setModules(prevMods =>
      prevMods.map(item =>
      item.id === modules[currentModule].id ? { ...item,  videoLink: 'NA' } : item
      )
      
    )
    
   }

   if(moduleType === 'video'){
    setModules(prevMods =>
      prevMods.map(item =>
      item.id === modules[currentModule].id ? { ...item,  audioLink: 'NA' } : item
      )
    )
   }
   if (fileInputRef.current) {
    fileInputRef.current.value = '';
  }
    setModules(prevMods =>
      prevMods.map(item =>
      item.id === modules[currentModule].id ? { ...item, type: moduleType } : item
      )
    )
      }
    console.log(modules)
  },[moduleType])


  useEffect(()=>{
    if(shouldRunEffect){
if(moduleType === 'audio'){
  setModules(prevMods =>
    prevMods.map(item =>
    item.id === modules[currentModule].id ? { ...item, audioLink: selectedFile, videoLink: 'NA' } : item
    )
  )
}


if(moduleType === 'video'){
  setModules(prevMods =>
    prevMods.map(item =>
    item.id === modules[currentModule].id ? { ...item, videoLink: selectedFile, audioLink: 'NA' } : item
    )
  )
}
    }
    
    console.log(modules)
  },[selectedFile])

  const [editingModule, setEditingModule] = useState(false)
 const [currentModule, setCurrentModule] = useState(0)


 const handleClearFile = () => {
  // Clear the file input value by setting it to an empty string
  if (fileInputRef.current) {
    fileInputRef.current.value = '';
  }
};

const handleRemoveItem = (itemId) => {
  // Filter the dataArray to create a new array without the item with the specified ID
  if(modules.length > 1){
    
  const updatedArray = modules.filter(item => item.id !== itemId);

  // Set the new array as the updated state
  setModules(updatedArray);
  setCurrentModule(0)
  setShouldRunEffect(false)
  setCurrentModule(0)
  setModuleTitle(modules[0].moduleTitle)
  setModuleType(modules[0].type)
  setEditorData(modules[0].content)
  setSelectedFile(modules[0].moduleType === 'audio'? modules[0].audioLink : modules[0].videoLink )
  if(modules[0].audioLink !== '' || modules[0].videoLink !== ''){
    setShowFileInput(false)
  } else {
    setShowFileInput(true)
  }
} else {
  alert('You have to have at least one module in the course.')
}
};

const updateProgress = ()=>{
let progressArr = []
  modules.forEach((i,index)=>{
    console.log(i.audioLink?.name)
    if(i.type === 'audio' && i.audioLink?.name){
     
      const formData = new FormData();
  
      formData.append('file', i.audioLink);
      formData.append('folder', data.email)
      formData.append('courseId', sessionStorage.getItem('trainingId'))
      formData.append('courseName', `${courseName} Module ${index + 1} - ${i.moduleTitle}`)
      axios.post(API+'upload-audio',formData)
        .then((response) => {
          // Video uploaded successfully
          
        console.log(response.data)
        modules[index].audioLink = response.data.fileUrl
        progressArray.push('i')
      
        console.log('Prog array: ' +progressArray)
        
        checkCompletelion(progressArray.length)
        })
        .catch((error) => {
          // Error handling
  
     console.log(error)
         
          alert('Something went wrong try again')
       
  
        });
    } 
    if(i.type === 'audio' && isFile(i.audioLink) === false ){
      progressArray.push('i')
      checkCompletelion(progressArray.length)
    }

console.log(i.videoLink?.name)
    if( i.type === 'video' && i.videoLink?.name){

      const formData = new FormData();
      formData.append('video', i.videoLink);
      formData.append('folder', data.email)
      formData.append('title', `${courseName} Module ${index + 1} - ${i.moduleTitle}`)
        console.log(i)
        axios.post(API+'upload-video',formData)
    .then((response) => {
      // Video uploaded successfully
    
     
      const videoUri = response.data.link;
      modules[index].videoLink = videoUri;
      
      console.log(videoUri);
     


      console.log(modules[index].videoLink)
      progressArray.push('i')
    
      checkCompletelion(progressArray.length)
    })
    .catch((error) => {
      // Error handling
      console.log(error)
     alert('Something went wrong. Please try again.')
    });

    } 
    
    if(i.type === 'video' && isFile(i.videoLink) === false ){
      progressArray.push('i')
      checkCompletelion(progressArray.length)
    }

    if( i.type === 'text'){
     
      progressArray.push('i')
    
 
      checkCompletelion(progressArray.length)
    }

    

  })

  
}

const DeleteFile = (file, test)=>{
  console.log(file)
  console.log(modules)
  let conf = window.confirm('This will delete the current file for this module. Are you sure you would like to do this?')
  if(conf){
    
  if(modules[currentModule].type === 'video'){
    DeleteVideoVimeo(data.email, file, ()=>{
     
      modules[currentModule].videoLink = 'NA'

      axios.post(API + 'get-data',{id:data.id})
    .then((response) => {
      
      if(response === 'error'){
        alert('Something went wrong. Try Again.')
      } else {
        let userData = response.data;
        console.log(userData)
      let filter =  userData.customTrainings.filter(i=>{
        return i.id === sessionStorage.getItem('trainingId');
      })

      filter[0].courseData = modules;

      axios.post(API+'update-training',{id:data.id, training: filter[0]})
      .then((resp)=>{
        if(resp === 'error'){
          alert('Something went wrong. Try Again.')
        } else {
          alert('Video Deleted')
          setUpdate(!update)
        }
        

      })
      }
      
    })


    })

  }

  if(modules[currentModule].type === 'audio'){
    axios.post(API+'delete-file-aws',{email: data.email, trainingId: sessionStorage.getItem('trainingId'), fileName: file})
    .then((resp)=>{
      if(resp === 'error'){
        alert('Something went wrong. Try Again.')
      } else {
        modules[currentModule].audioLink = 'NA'

      axios.post(API + 'get-data',{id:data.id})
    .then((response) => {
      
      if(response === 'error'){
        alert('Something went wrong. Try Again.')
      } else {
        let userData = response.data;
        console.log(userData)
      let filter =  userData.customTrainings.filter(i=>{
        return i.id === sessionStorage.getItem('trainingId');
      })

      filter[0].courseData = modules;

      axios.post(API+'update-training',{id:data.id, training: filter[0]})
      .then((resp)=>{
        if(resp === 'error'){
          alert('Something went wrong. Try Again.')
        } else {
          alert('Audio Deleted')
          setUpdate(!update)
        }
        

      })
      }
      
    })
      }
      

    })
    
  }

}
}

const DeleteModule = (itemId, type,file)=>{
  console.log(itemId);
  console.log(file)
  console.log(type)
  if(modules.length > 1){
  console.log(file)
  let conf = window.confirm('This will delete the current file for this module. Are you sure you would like to do this?')
  if(conf){
    if(file.includes('https://') ){
      
  if(modules[currentModule].type === 'video'){
    DeleteVideoVimeo(data.email, file, ()=>{

    
        const updatedArray = modules.filter(item => item.id !== itemId);
      
        // Set the new array as the updated state
      
     
 

      axios.post(API + 'get-data',{id:data.id})
    .then((response) => {
      
      if(response === 'error'){
        alert('Something went wrong. Try Again.')
      } else {
        let userData = response.data;
        console.log(userData)
      let filter =  userData.customTrainings.filter(i=>{
        return i.id === sessionStorage.getItem('trainingId');
      })

      filter[0].courseData = updatedArray;

      axios.post(API+'update-training',{id:data.id, training: filter[0]})
      .then((resp)=>{
        if(resp === 'error'){
          alert('Something went wrong. Try Again.')
        } else {
          
          setModules(updatedArray)
          setUpdate(!update)
          setCurrentModule(0)
          setShouldRunEffect(false)
  setModuleTitle(modules[0].moduleTitle)
  setModuleType(modules[0].type)
  setEditorData(modules[0].content)
  setSelectedFile(modules[0].moduleType === 'audio'? modules[0].audioLink : modules[0].videoLink )
  if(modules[0].audioLink !== '' || modules[0].videoLink !== ''){
    setShowFileInput(false)
  } else {
    setShowFileInput(true)
  }
          alert('Video Deleted')
          
      
         
         
       
        }
        

      })
      }
      
    })


    })
    

  }

  if(modules[currentModule].type === 'audio'){
    axios.post(API+'delete-file-aws',{email: data.email, trainingId: sessionStorage.getItem('trainingId'), fileName: file})
    .then((resp)=>{
      if(resp === 'error'){
        alert('Something went wrong. Try Again.')
      } else {
        const updatedArray = modules.filter(item => item.id !== itemId);
console.log(updatedArray)
      axios.post(API + 'get-data',{id:data.id})
    .then((response) => {
      
      if(response === 'error'){
        alert('Something went wrong. Try Again.')
      } else {
        let userData = response.data;
        console.log(userData)
      let filter =  userData.customTrainings.filter(i=>{
        return i.id === sessionStorage.getItem('trainingId');
      })

      filter[0].courseData = updatedArray;

      axios.post(API+'update-training',{id:data.id, training: filter[0]})
      .then((resp)=>{
        if(resp === 'error'){
          alert('Something went wrong. Try Again.')
        } else {
          setModules(updatedArray)
          setUpdate(!update)
          setCurrentModule(0)
          setShouldRunEffect(false)
  setModuleTitle(modules[0].moduleTitle)
  setModuleType(modules[0].type)
  setEditorData(modules[0].content)
  setSelectedFile(modules[0].moduleType === 'audio'? modules[0].audioLink : modules[0].videoLink )
  if(modules[0].audioLink !== '' || modules[0].videoLink !== ''){
    setShowFileInput(false)
  } else {
    setShowFileInput(true)
  }
          alert('Module Deleted')
    
 
     
        }
        

      })
      }
      
    })
      }
      

    })
    
  }
  if(modules[currentModule].type === 'text'){
    const updatedArray = modules.filter(item => item.id !== itemId);

    axios.post(API + 'get-data',{id:data.id})
    .then((response) => {
      
      if(response === 'error'){
        alert('Something went wrong. Try Again.')
      } else {
        let userData = response.data;
        console.log(userData)
      let filter =  userData.customTrainings.filter(i=>{
        return i.id === sessionStorage.getItem('trainingId');
      })

      filter[0].courseData = updatedArray;

      axios.post(API+'update-training',{id:data.id, training: filter[0]})
      .then((resp)=>{
        if(resp === 'error'){
          alert('Something went wrong. Try Again.')
        } else {
          setModules(updatedArray)
          setUpdate(!update)
          setCurrentModule(0)
          setShouldRunEffect(false)
  setModuleTitle(modules[0].moduleTitle)
  setModuleType(modules[0].type)
  setEditorData(modules[0].content)
  setSelectedFile(modules[0].moduleType === 'audio'? modules[0].audioLink : modules[0].videoLink )
  if(modules[0].audioLink !== '' || modules[0].videoLink !== ''){
    setShowFileInput(false)
  } else {
    setShowFileInput(true)
  }
          alert('Module Deleted')
      
 
        }
        

      })
      }
      
    })
  }

} else{
  const updatedArray = modules.filter(item => item.id !== itemId);
  setModules(updatedArray)
  setUpdate(!update)
  setCurrentModule(0)
  setShouldRunEffect(false)
setModuleTitle(modules[0].moduleTitle)
setModuleType(modules[0].type)
setEditorData(modules[0].content)
setSelectedFile(modules[0].moduleType === 'audio'? modules[0].audioLink : modules[0].videoLink )
if(modules[0].audioLink !== '' || modules[0].videoLink !== ''){
setShowFileInput(false)
} else {
setShowFileInput(true)
}
}

}
} else {
  alert('You have to have at least one module in the course.')
}
}



  

    return (
        <Box>
            <Typography variant="button" sx={{fontSize:24}}><b>MODULE BUILDER</b></Typography>

        <Grid container>
            <Grid  className="p-2" xs={8} item >
             <Paper  className="p-3">
             <Typography variant="button" sx={{fontSize:20}}><b>Module {currentModule + 1}</b></Typography>
<hr></hr>
                <Grid container>
                    <Grid sx={{alignSelf:'center'}} item xs={3}>
                     <Typography variant='subtitle2'><b>Module Type:</b></Typography>
                    </Grid>

                    <Grid item xs={9}>
                     <Form.Select disabled={modules[currentModule].type === 'audio' && modules[currentModule].audioLink !== 'NA' ? true : modules[currentModule].type === 'video' && modules[currentModule].videoLink !== 'NA'? true : false} ref={selectRef} value={moduleType} 
                     onChange={(e)=>{
                      setShouldRunEffect(true); 
                      setShowFileInput(true); 
                      setModuleType(e.target.value);
               
                    fileInputRef.current.value = "";
                   
                    console.log(modules[currentModule].audioLink)
                  console.log(modules[currentModule].videoLink)
                    }} name="type" >
                       <option value="text">Text</option>
                       <option value="video">Video</option>
                       <option value="audio">Audio</option>
                     </Form.Select>
                     <Typography sx={{display:modules[currentModule].type === 'audio' && modules[currentModule].audioLink !== 'NA' ? 'block' : modules[currentModule].type === 'video' && modules[currentModule].videoLink !== 'NA'? 'block' : 'none'}} variant="caption">You cannot change the module type unless you delete the media file.</Typography>
                    </Grid>

                    <Grid className="mt-4" container>
            <Grid sx={{alignSelf:'top'}} item xs={3}>
             <Typography variant='subtitle2'><b>Module Title:</b></Typography>
            </Grid>

            <Grid item xs={9}>
            <Form.Control 
             type="text"
             name="moduleTitle" // Make sure this matches the property name in initialModule
             onChange={(e)=>{setShouldRunEffect(true); handleClearFile();  setModuleTitle(e.target.value)}}
             value={moduleTitle}
            />
            </Grid>

         
        </Grid>
                 
                </Grid>
                
          
              
                 <Grid className="mt-4" container>
                  <Grid sx={{alignSelf:'top'}} item xs={3}>
             <Typography style={{display: moduleType === 'text' ? 'none': showFileInput ? 'block':'none' }} variant='subtitle2'><b>Media File Upload:</b></Typography>
            </Grid>
             
            <Grid item xs={9}>
                <Form.Control 
                type="file"
                name="fileInput"
                ref={fileInputRef}
                accept={modules[currentModule].type === 'video'? "video/*": "audio/*"}
                style={{display: modules[currentModule].type === 'text' ? 'none': modules[currentModule].audioLink === 'NA' && modules[currentModule].videoLink === 'NA' ?'block' : 'none' }}
                onChange={(e)=> {
                  if(e.target.files[0].size > 250000000 && modules[currentModule].type === 'video' || e.target.files[0].size > 50000000 && moduleType === 'audio' ){
                    window.alert(`File size is too big. Size should be under ${modules[currentModule].type === 'video' ? '250mb':'50mb'}`)
                    e.target.value = '';
                  } else {
                  setShouldRunEffect(true); 
                  const file = e.target.files[0];
                  setSelectedFile(file)
                  }
                }}
                />

                <FileHolder
                Style={{display:modules[currentModule].type === 'text' ? 'none': isFile(modules[currentModule].audioLink)  ? 'block': modules[currentModule].audioLink !== 'NA' ? 'block':'none'}}
                Title={modules[currentModule].type === 'audio' && isFile(modules[currentModule].audioLink) ? modules[currentModule].audioLink.name : `Module ${currentModule+1}: ${modules[currentModule].moduleTitle } Audio`}
                OnClickDeleteFile={isFile(modules[currentModule].audioLink) || modules[currentModule].audioLink === null ?  ()=> { modules[currentModule].audioLink = 'NA'; console.log(modules[currentModule].audioLink); console.log(modules[currentModule].videoLink); setUpdate(!update);  if (fileInputRef.current) {
                
                  fileInputRef.current.value = '';
                  
                }}  : ()=>DeleteFile(modules[currentModule].audioLink !== 'NA' ? modules[currentModule].audioLink :  'NA', modules[currentModule].audioLink)}
                />

                <FileHolder
                Style={{display:modules[currentModule].type === 'text' ? 'none': isFile(modules[currentModule].videoLink) ? 'block': modules[currentModule].videoLink !== 'NA' ? 'block':'none'}}
                //`Module ${currentModule+1}: ${modules[currentModule].moduleTitle } Video`
                Title={modules[currentModule].type === 'video' && isFile(modules[currentModule].videoLink) ? modules[currentModule].videoLink.name: modules[currentModule].videoLink}
                OnClickDeleteFile={isFile(modules[currentModule].videoLink) ? ()=> {modules[currentModule].videoLink = 'NA';  console.log(modules[currentModule].audioLink); console.log(modules[currentModule].videoLink); setUpdate(!update);  if (fileInputRef.current) {
                  modules[currentModule].audioLink = 'NA';
                  modules[currentModule].videoLink = 'NA';
                  fileInputRef.current.value = '';
                }} : ()=>DeleteFile(modules[currentModule].videoLink !== 'NA' ? modules[currentModule].videoLink : 'NA') }
                />
            {/* <ModuleAudio
               
       
                SetAudioFile={setAudioFile}
    
                AudioFile={audioFile}
                />  */}
            </Grid>
            </Grid>

             
                 <Grid className="mt-4" container>
                  <Grid sx={{alignSelf:'top'}} item xs={3}>
             <Typography variant='subtitle2'><b>Content:</b></Typography>
            </Grid>

            <Grid item xs={9}>
            <CKEditor
        editor={ClassicEditor}
        data={editorData}
        onChange={(event, editor) =>{setShouldRunEffect(true); setEditorData(editor.getData())}}
        config={customConfiguration}
        onReady={(editor) => {
          // You can handle the editor instance here
          console.log('Editor is ready to use!', editor);
        }}
       
      />

            </Grid>
            <ProgressModal Progress={progress} Open={openProgress}/>
            </Grid>
            
                
             </Paper>
            </Grid>

            


            <Grid className="p-2" xs={4} item >
             <Paper  className="p-2">
               <Box sx={{maxHeight:'400px', overflow:'scroll'}}>
                {modules.map((i,index)=>{
                    
                    return <ModuleListItem
                    // Disabled={i.id === modules[currentModule].id ? true: false}
                    Selected={i.id === modules[currentModule].id ? '#1A76D242':'white'}
                    OnClick={() => {
                    //   if(isFile(modules[index].audioLink) === false ||  modules[index].audioLink === "NA" && modules[index].type === 'audio'){
                    //       fileInputRef.current.value = '';
                         
                    //   } else {
                    //     if(isFile(modules[index].audioLink)){
                    //       fileInputRef.current.value = modules[index].audioLink.name;
                    //     }
                    //   }

                    //   if(isFile(modules[index].videoLink) === false ||  modules[index].videoLink === "NA" && modules[index].type === 'video'){
                    //     fileInputRef.current.value = '';
                       
                    // } else {
                    //   if(isFile(modules[index].videoLink)){
                    //     fileInputRef.current.value = modules[index].videoLink.name;
                    //   }
                    // }
                      setShouldRunEffect(false)
                      console.log(modules[index])
                      setCurrentModule(index)
                      setModuleTitle(modules[index].moduleTitle)
                      setModuleType(modules[index].type)
                      setEditorData(modules[index].content)
                      // setSelectedFile(modules[index].moduleType === 'audio'? modules[index].audioLink : modules[index].videoLink )
                      // if(modules[index].audioLink !== '' || modules[index].videoLink !== ''){
                      //   setShowFileInput(false)
                      // } else {
                      //   setShowFileInput(true)
                      // }
                    
                    console.log(modules[index].audioLink)
                 
                    }}
                    OnClickDelete={()=>DeleteModule(i.id,i.type, i.type === 'audio' ? i.audioLink : i.type === 'video' ? i.videoLink : '')}
                    ModuleNumber={<div style={{display:'inline'}}><Typography variant="subtitle2"><b>Module {index + 1}: {i.moduleTitle.substring(0,60)}</b></Typography><Typography sx={{}} variant="caption">{i.type === "video" ?'Video' :i.type === 'audio' ? 'Audio' : 'Text'} Module</Typography></div>}
                  
                    /> 
                })}
                <div ref={modList} />
                </Box>
                <Stack sx={{textAlign:'center'}}>
                <Button
                sx={{fontSize:20, marginTop:'10px'}}
                variant="contained"
                
                onClick={()=>{
                  const newObj = {
                    id:uuid(),
                    moduleTitle:'',
                    type: 'text',
                    videoLink: 'NA',
                    audioLink: 'NA',
                    file:'',
                    content:''
                  }
                  const newArray = [...modules, newObj];

                  setModules(newArray)
                  
                  
                
                  setModuleTitle('');
                  handleClearFile();
                  setEditorData('')
                  setModuleType('text')
                  setCurrentModule(modules.length)
                 setModulesUpdate(!modulesUpdate)
               }}>+</Button>
                <Button
                sx={{fontSize:14, marginTop:'10px'}}
                onClick={()=>{setOpenProgress(true); updateProgress();}}
                variant="contained"
                ><b>Save Changes</b></Button>

<Button
                sx={{fontSize:14, marginTop:'10px'}}
                onClick={()=>{
                  const conf = window.confirm('Are you sure you would like to edit the quiz? Any unsaved changes to the modules will be lost.')
                if(conf){
                  props.NextStep()
                }
             
                }}
                variant="contained"
                ><b>Edit Quiz</b></Button>

                <Button
                onClick={()=>{
                  const conf = window.confirm('Are you sure you would like to go back? Any unsaved changes to the modules will be lost.')
                if(conf){
                  props.GoBack()
                }
             
                }}
                sx={{fontSize:14, marginTop:'10px'}}
                variant="contained"
                ><b>Go Back</b></Button>

               {/* <Button
               onClick={()=>{
                
                let conf = window.confirm('If you select this option the course will not be active until you build a quiz for the course. You can always comeback and build a quiz using the edit button on the course card.')
               if(conf){
                setShowProgress(true)
                UploadModules(modules, trainingId, data.id,data.email, updateProgressArray, true)
               }
                
               }}
               sx={{ marginTop:'10px'}}
               variant="contained"
               >Save and Exit</Button> */}

             
                </Stack>
             </Paper>
            </Grid>

      
        </Grid>
        </Box>
    )
}
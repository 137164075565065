///Delete Documents

import axios from "axios"
import { API } from "../../controllers/API"
import { getFirstNumberFromVimeoLink } from "../global-functions"


export const DeleteCourseAws = async (userEmail, trainingId, DeleteCallback)=>{

    let resp = await axios.post(API+'delete-folder-aws', {email:userEmail, trainingId: trainingId })

    if (resp === 'error'){
        window.alert('Something went wrong. Please Try again.')
    } else {
        DeleteCallback();
    }
}


export const DeleteVideoVimeo = async (userEmail, videoLink, DeleteCallback)=>{

  let videoId = getFirstNumberFromVimeoLink(videoLink)
console.log(videoId)
    let resp = await axios.post(API+'delete-vimeo-video', {email:userEmail, videoId: videoId })

    if (resp === 'error'){
        window.alert('Something went wrong. Please Try again.')
    } else {
        DeleteCallback();
    }
}
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import logo from '../assets/css/images/icon6.png'
import {  useNavigate } from "react-router-dom";
import '../assets/css/table-style.css'
import {useLocation} from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Container } from "react-bootstrap";
import { styled } from '@mui/material/styles';
import {Table} from "react-bootstrap";
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SendIcon from '@mui/icons-material/Send';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { formatDate, generateDocument } from "../classes/global-functions";
import useWindowSize from 'use-window-size-v2';
import { API } from "../controllers/API";
import axios from "axios";
import { CSVLink } from "react-csv";
import DownloadIcon from '@mui/icons-material/Download';
import { CircularProgress } from "@mui/material";
function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link target='_blank' color="inherit" href="https://employeetrainingtoolkit.com/">
          Employee Training Tool-Kit
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
  
const navItems = ['Back To Training Manager'];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundImage:'linear-gradient(#37b6e8, #528EE0)',
      color: theme.palette.common.white,
      fontWeight:'bold'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));



export const TrainingDetails = ()=>{
  const data = JSON.parse(sessionStorage.getItem('profile-data'))
    const navigate = useNavigate();
    const [statusArr, setStatusArr] = useState([])
    const location = useLocation()
    const { height, width } = useWindowSize();
    const [trainingData, setTrainingData] = useState(location.state.i)
    const [downloadableData, setDownloadableData] = useState()
    const [loading, setLoading] = useState(true)
    let statusArray = location.state.i.learners.filter(i=>{return i.status === 'complete'})
    const setupExportData = ()=>{
      let csvData = [
        ["COURSE", "DATE OF TRAINING","","",""],
        [trainingData.course.courseName, formatDate(trainingData.dateCreated), "","",""],
        ["","","","",""],
        ["LEARNER NAME", "EMAIL", "DATE COMPLETED", "STATUS", "QUIZ SCORE"],
       
      ];
            setDownloadableData([]);
      trainingData.learners.forEach(o=>{
        csvData.push([o.name, o.email, o.dateCompleted ? formatDate(o.dateCompleted) : 'NA', o.status, o.quizScore ? `${o.quizScore}%` : 'NA'])
       })
    setDownloadableData(csvData)
    }
    
    useEffect(()=>{
      console.log(statusArr.length)
        console.log(location.state)
        trainingData.learners.forEach(o=>{
            if(o.status === 'complete'){
              statusArr.push('c')
            }
            console.log('c')
          })

          setupExportData();
          setLoading(false)

    },[])

      //SEND TRAINING TO LEARNER
  const SendTraining = async (a,b, email, name, training)=>{

    console.log(a + " " + b)

    let resp = await axios.post(API + 'send-initial-training-single',{userId:data.id, trainingId:a, learnerId:b, name: name, email:email, training:training})

    if(resp.data === 'error'){
      window.alert('Something went wrong. Please try again.')
    } else {
      window.alert('Training has been sent!')
      console.log(resp.data.data)
      let newData = resp.data.data.activeTrainings;
      let trainingIndex = newData.findIndex(i=>{return i.id === a});

      setTrainingData(resp.data.data.activeTrainings[trainingIndex])
      



    }
  }

  const RemoveLearner = async (a,b)=>{

    console.log(a + " " + b)
let confirmDelete = window.confirm('Are you sure you want to remove this learner from this training?')

if(confirmDelete){
  
    let resp = await axios.post(API + 'delete-learner-from-training',{userId:data.id, trainingId:a, learnerId:b})

    if(resp.data === 'error'){
      window.alert('Something went wrong. Please try again.')
    } else {
      window.alert('Learner has been removed!')
      console.log(resp.data.data)
      let newData = resp.data.data.activeTrainings;
      let trainingIndex = newData.findIndex(i=>{return i.id === a});

      setTrainingData(resp.data.data.activeTrainings[trainingIndex])
      



    }

}
  }


  //COPY LINK TO TRAINING 
  const CopyLinkToTraining = async (a,b, c ,d)=>{
    console.log(a + " " + b)
  
      await navigator.clipboard.writeText(`https://app.employeetrainingtoolkit.com/#/training?a=${data.id}&b=${a}&c=${b}`)


   
    alert(`Copied the link to the ${c} training for ${d}`)
  }


  //RESEND TRAINING
  const ResendTrainingEmail = async (a,b, name, email, training)=>{
    console.log(a + " " + b)
    let resp = await axios.post(API + 'resend-training',{userId:data.id, trainingId:a, learnerId:b, name:name, email:email, training:training})

    if(resp.data === 'error'){
      window.alert('Something went wrong. Please try again.')
    } else {
      window.alert('Training has been resent!')
     
      
      



    }
  }


  //DOWNLOAD CERTIFICATE
  const DownloadCertificate = (a,b)=>{
    console.log(a + " " + b)
  }

  if(loading){
    <Box sx={{textAlign:'center', marginTop:20}}>
    <CircularProgress/>
    <Typography><b>Loading</b></Typography>
  </Box>
  } else {
    



    return(
        <Box  sx={{ backgroundColor:'#ECEEF1', height:'100vh', overflow:'auto', paddingBottom:'200px',   }}>
            <AppBar component="nav">
        <Toolbar
        sx={{backgroundImage:'linear-gradient(#37b6e8, #528EE0)'}}
        >
        
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: {  sm: 'block', padding:10 } }}
          >
           <img className="logo" src={logo} width="75px" />
          </Typography>
          <Box sx={{ display: {  sm: 'block' } }}>
            {navItems.map((item) => (
              <Button onClick={()=>navigate('/', { replace: true, state:'TM' })} key={item} sx={{ color: '#fff' }}>
                {item}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
        <Box className='main-box' sx={{marginTop:'100px !important', maxWidth:'1080px', margin:'auto',padding:{ xs: '0px', sm: '20px' }}}>
        <Box className='main-box' >
        <Container className="main-container" style={{ textAlign:'center'}}>
            <Paper sx={{padding:'10px', border:'2px solid black'}}>
      <Row style={{textAlign:'left', marginLeft:'5%'}}>
        <Col  sm={4}>
        <Form.Group >
                <Form.Label><b><Typography sx={{fontSize:'16px'}} variant="button"><b>Course Name:</b></Typography></b></Form.Label>
                <br></br>
                <Form.Label style={{fontSize:'20px'}}>{trainingData.course.courseName}</Form.Label>
            </Form.Group>
        </Col>
        <Col sm={4}></Col>
        <Col sm={4}>
        <Form.Group >
                <Form.Label><b><Typography sx={{fontSize:'16px'}} variant="button"><b>Course Type:</b></Typography></b></Form.Label>
                <br></br>
                <Form.Label style={{fontSize:'16px'}}>{trainingData.type.toUpperCase()}</Form.Label>
            </Form.Group>
        </Col>
      </Row>

      <Row  style={{textAlign:'left', marginTop:width > 570 ? '20px':'0px', marginLeft:'5%'}}>
        <Col  sm={4}>
        <Form.Group >
                <Form.Label><b><Typography sx={{fontSize:'16px'}} variant="button"><b>STATUS:</b></Typography></b></Form.Label>
                <br></br>
                <Form.Label style={{fontSize:'16px'}}>{statusArray.length === trainingData.learners.length ? 'COMPLETE': 'IN PROGRESS'}</Form.Label>
            </Form.Group>
        </Col>
        <Col sm={4}>
        <Form.Group >
                <Form.Label><b><Typography sx={{fontSize:'16px'}} variant="button"><b>DATE CREATED:</b></Typography></b></Form.Label>
                <br></br>
                <Form.Label style={{fontSize:'16px'}}>{formatDate(trainingData.dateCreated)}</Form.Label>
            </Form.Group>
        </Col>

        <Col sm={4}>
        <Form.Group >
                <Form.Label><b><Typography sx={{fontSize:'16px'}} variant="button"><b>PROGRESS:</b></Typography></b></Form.Label>
                <br></br>
                <Form.Label style={{fontSize:'16px'}}>{`${statusArray.length}/${trainingData.learners.length}`}</Form.Label>
            </Form.Group>
        </Col>
      </Row>
      </Paper>
    </Container> 
            </Box>
        
            
            <Box className='main-box-table' sx={{margin:5, padding:{ xs: '0px'}}} >
            <div style={{float:'right', marginBottom:'10px'}}>
           <Tooltip title="Download Training Data CSV File"><Button variant="contained"><CSVLink download={`${trainingData.course.courseName} Training Data - ${formatDate(trainingData.dateCreated)}.csv`} style={{color:'white'}} data={downloadableData}><DownloadIcon/></CSVLink></Button></Tooltip>
            </div>
            <TableContainer  component={Paper}>
      <Table sx={{ minWidth: 700 }} >
        <TableHead>
          <TableRow sx={{height:'60px'}}>
            <StyledTableCell align="center"><Typography variant="button" ><b>Name</b></Typography></StyledTableCell>
            <StyledTableCell align="center"><Typography variant="button" ><b>Email</b></Typography></StyledTableCell>
            <StyledTableCell align="center"><Typography variant="button" ><b>Status</b></Typography></StyledTableCell>
            <StyledTableCell align="center"><Typography variant="button" ><b>Actions</b></Typography></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {trainingData.learners.map((row) => {
                let status;
                if(row.status === 'complete'){
                status = <Typography sx={{color:'#199A71'}} variant='button'><b>COMPLETE</b></Typography>

      
                }

                if(row.status === 'not sent'){
                status = <Typography sx={{color:'orange'}} variant='button'><b>Not Sent</b></Typography>
                }

                if(row.status === 'sent'){
                status = <Typography sx={{color:'orange'}} variant='button'><b>Sent</b></Typography>
                }
                // if(row.status === 'complete'){
                //   
                // }
            
            return (
                
            <StyledTableRow key={row.name}>
              <StyledTableCell align="center" component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="center">{row.email}</StyledTableCell>
              <StyledTableCell align="center"><Typography sx={{}}>{status}</Typography></StyledTableCell>
              <StyledTableCell align="center">
              <Tooltip sx={{display:row.status === 'not sent'?'inline':'none'}} title='Send training to learners email address' ><IconButton sx={{padding:0.5, display:row.status === 'not sent'?'inline':'none'}} onClick={()=>SendTraining(trainingData.id, row.id, row.email, row.name, trainingData)}><SendIcon sx={{color:'#3FA9E6', display:row.status === 'not sent'?'inline':'none', cursor:'pointer'}}/></IconButton></Tooltip>
              <Tooltip sx={{display:row.status === 'complete'?'inline':'none'}} title='Copy link to clipboard to send the training to learner manually' ><IconButton sx={{padding:0.5}} onClick={()=>CopyLinkToTraining(trainingData.id, row.id,trainingData.course.courseName, row.name)}><ContentCopyIcon  sx={{color:'#3FA9E6',fontSize:'20px', display:row.status != 'complete'?'inline':'none', cursor:'pointer'}}/> </IconButton></Tooltip> 
              <Tooltip sx={{display:row.status === 'sent'?'inline':'none'}} title='Resend the training to the learner' ><IconButton sx={{display:row.status === 'sent'?'inline':'none',padding:0.5}} onClick={()=>ResendTrainingEmail(trainingData.id, row.id, row.name, row.email,trainingData)}><ForwardToInboxIcon titleAccess='Resend Training Email' sx={{color:'#3FA9E6',  display:row.status === 'sent'?'inline':'none', cursor:'pointer'}}/> </IconButton></Tooltip>
              <Tooltip sx={{display:row.status === 'complete'?'inline':'none'}} title='Download Certificate' ><IconButton 
               onClick={()=>{
                let obj = {
                    student_name: row.name,
                    course_name: trainingData.course.courseName,
                    date: formatDate(new Date),
                    instructor: trainingData.sentBy.presenterName ? trainingData.sentBy.presenterName : trainingData.sentBy.name,
                    presenter_title: trainingData.sentBy.title ? trainingData.sentBy.title : 'Presenter'
                    
                }
                
                generateDocument(obj, row.name)}}
              sx={{color:'#3FA9E6',display:row.status === 'complete'?'inline':'none', cursor:'pointer'}}><DownloadIcon/></IconButton></Tooltip>
              <Tooltip title='Delete learner from this training' ><IconButton sx={{padding:0.5}} onClick={()=>RemoveLearner(trainingData.id, row.id)}><DeleteIcon titleAccess='Delete learner from this training' sx={{color:'tomato', cursor:'pointer'}}/> </IconButton></Tooltip>

              </StyledTableCell>
    
            </StyledTableRow>

)
          })}
        </TableBody>
      </Table>
    </TableContainer>
         </Box>
         </Box>
         <Box sx={{height:'100px'}}>
           <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
    
    <Copyright sx={{ pt: 4 }} />
  </Container>
           </Box>
        </Box>
    )
}
}
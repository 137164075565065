import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState, useRef } from "react";
import { Form, InputGroup } from "react-bootstrap";
import uuid from "react-uuid";
import UpdateSnackbar from "../../components/training-builder-components/UpdateSnackbar";
import QuizListItem from "../../components/quiz-builder/QuizListItem";
import { DocumentChip } from "../../components/training-builder-components/DocumentChip";
import axios from "axios";
import { API } from "../../controllers/API";







export const QuizBuilder = (props)=>{
    const [trainingId, setTrainingId] = useState(sessionStorage.getItem('trainingId'))
    const [userId, setUserId] = useState(props.UserId)
    const date = new Date()
    const modList = useRef(null);
    // True / False Variables and Functions
const [tfAnswer, setTfAnswer] = useState('true')
const [tfQuestion, setTfQuestion] = useState('')
const [tfResponse, setTfResponse] = useState('')


//Mulitple Choice Variables
const [mcOption, setMcOption] = useState('')



const [modulesUpdate, setModulesUpdate] = useState(true)
const [openBar, setOpenBar] = React.useState(false);
const [update, setUpdate] = useState(true)
const [questionType, setQuestionType] = useState('multi')
const [questions, setQuestions] = useState([{id: uuid(),question:'', questionType:questionType, answer: tfAnswer, response:'', options:[]}])
const [currentQuestion, setCurrentQuestion] = useState(0)
useEffect(()=>{
    console.log('props.Training')
console.log(props.Training)
},[])
useEffect(()=>{
UpdateIndex(currentQuestion)
},[update])

// Update Index

const UpdateIndex = (a)=>{
    setCurrentQuestion(a)
}
useEffect(()=>{
    modList.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
   },[modulesUpdate])



    const AddQuiz = async (a)=>{
        console.log(a);
  
     let trainingId = sessionStorage.getItem('trainingId');

        let resp = await axios.post(API+ 'add-quiz', {id:a, trainingId: trainingId, questions:questions, dateUpdated: date});
         console.log(resp)
        if(resp === 'error'){
            window.alert('Something went wrong. Please try again.')
        } else {
           
            let trainingFilter = resp.data.training.customTrainings.filter((i)=>{
                return i.id === trainingId;
            })
            console.log(trainingFilter[0].courseData.length)
            if(trainingFilter[0].courseData.length > 0){
              let changeStatus = await axios.post(API+'change-course-status', {id:a,trainingId:trainingId, status: true})
              if(changeStatus === 'error'){
                window.alert('Something went wrong. Please try again.')
              } else {
                window.alert('Quiz Saved')
                props.Navigate();
                sessionStorage.setItem('trainingId', '')
              }
            } else {
                window.alert('Quiz Saved but course is not acive.')
                props.Navigate();
                sessionStorage.setItem('trainingId', '')
            }
            
        }
    }
    return (
        <Box>
            <Typography variant="button" sx={{fontSize:24}}><b>QUIZ BUILDER</b></Typography>
        <Grid container>
            <Grid  className="p-2" xs={8} item >
                <Typography variant="button" sx={{fontSize:20}}><b>Question {currentQuestion + 1}</b></Typography>
             <Paper  className="p-3">
             
                <Grid container>
                    <Grid sx={{alignSelf:'center'}} item xs={3}>
                     <Typography variant='subtitle2'><b>Question Type</b></Typography>
                    </Grid>

                    <Grid item xs={9}>
                     <Form.Select onChange={(e)=>{
                        setQuestionType(e.target.value)
                        questions[currentQuestion].questionType = e.target.value
                        if(e.target.value === 'trueFalse'){
                            
                        questions[currentQuestion].options = ['true','false']
                        setTfAnswer('true')

                    } else {
                        questions[currentQuestion].options = []
                    }
                     }}>
                       <option value="multi">Multiple Choice</option>
                       <option value="trueFalse">True / False</option>
                     </Form.Select>
                    </Grid>
                 
                </Grid>
                <Box sx={{display: questionType === 'trueFalse' ? 'block':'none'}}>

                <Grid className="mt-4" container>
                    <Grid sx={{alignSelf:'top'}} item xs={3}>
                     <Typography variant='subtitle2'><b>Question:</b></Typography>
                    </Grid>

                    <Grid item xs={9}>
                    <Form.Control rows="4" value={tfQuestion} onChange={(e)=>{setTfQuestion(e.target.value); questions[currentQuestion].question = e.target.value}} as='textarea'/>
                    </Grid>
                 
                </Grid>

                <Grid className="mt-4" container>
                    <Grid sx={{alignSelf:'center'}} item xs={3}>
                     <Typography variant='subtitle2'><b>Answer:</b></Typography>
                    </Grid>

                    <Grid item xs={9}>
                     <Form.Select value={tfAnswer} onChange={(e)=>{setTfAnswer(e.target.value); questions[currentQuestion].answer = e.target.value}}>
                       <option value="">---</option>
                       <option value="true">True</option>
                       <option value="false">False</option>
                     </Form.Select>
                    </Grid>
                 
                </Grid>

                <Grid className="mt-4" container>
                    <Grid sx={{alignSelf:'top'}} item xs={3}>
                     <Typography variant='subtitle2'><b>Response:</b></Typography>
                    </Grid>

                    <Grid item xs={9}>
                    <Form.Control rows="4" value={tfResponse} onChange={(e)=>{setTfResponse(e.target.value); questions[currentQuestion].response = e.target.value}} as='textarea'/>
                    </Grid>
                 
                </Grid>
                <Grid className="mt-4" container>
                    <Grid sx={{alignSelf:'top'}} item xs={8}>
                    {/* <Button sx={{display:questions.length === currentQuestion ? 'none':'block'}} onClick={ UpdateTFQuestion } variant="contained">{'Update'} Question</Button> */}

                    </Grid>

                    <Grid sx={{textAlign:'end'}} item xs={4}>
                    {/* <Button onClick={ AddTFQuestion } variant="contained">{'Save'} Question</Button> */}


                    </Grid>
                 
                </Grid>
                </Box>

                <Box sx={{display: questionType != 'trueFalse' ? 'block':'none'}}>
                <Grid className="mt-4" container>
                    <Grid sx={{alignSelf:'top'}} item xs={3}>
                     <Typography variant='subtitle2'><b>Question:</b></Typography>
                    </Grid>

                    <Grid item xs={9}>
                    <Form.Control rows="4" value={tfQuestion} onChange={(e)=>{setTfQuestion(e.target.value); questions[currentQuestion].question = e.target.value}} as='textarea'/>
                    </Grid>
                 
                </Grid>

                

                <Grid className="mt-4" container>
                    <Grid sx={{alignSelf:'top'}} item xs={3}>
                     <Typography variant='subtitle2'><b>Add Options:</b></Typography>
                    </Grid>

                    <Grid item xs={9}>
                        <InputGroup>
                        <Form.Control value={mcOption} onChange={(e)=>setMcOption(e.target.value)}/>
                        <Button onClick={()=>{questions[currentQuestion].options.push(mcOption); setMcOption('')}} variant="contained">Add Option</Button>
                       
                        </InputGroup>
                        <div>
                        {questions[currentQuestion].options.map(i=>{
                            return <DocumentChip Label={i}/>
                            
                        })}
                        </div>
                                            </Grid>
                 
                </Grid>
                <Grid className="mt-4" container>
                    <Grid sx={{alignSelf:'center'}} item xs={3}>
                     <Typography variant='subtitle2'><b>Answer:</b></Typography>
                    </Grid>

                    <Grid item xs={9}>
                     <Form.Select value={tfAnswer} onChange={(e)=>{setTfAnswer(e.target.value); questions[currentQuestion].answer = e.target.value}}>
                     <option value="">---</option>
                       {questions[currentQuestion].options.map(i=>{
                        return <option value={i}>{i}</option>
                       })}
                     </Form.Select>
                    </Grid>
                 
                </Grid>
                <Grid className="mt-4" container>
                    <Grid sx={{alignSelf:'top'}} item xs={3}>
                     <Typography variant='subtitle2'><b>Response:</b></Typography>
                    </Grid>

                    <Grid item xs={9}>
                    <Form.Control rows="4" value={tfResponse} onChange={(e)=>{setTfResponse(e.target.value); questions[currentQuestion].response = e.target.value}} as='textarea'/>
                    </Grid>
                 
                </Grid>

                

                </Box>
             </Paper>
            </Grid>

            


            <Grid className="p-2" xs={4} item >
             <Paper className="p-2 mt-4">
             <Box sx={{maxHeight:'400px', overflow:'scroll'}}>
                {questions.map((i,index)=>{
                    
                    return <QuizListItem
                    Selected={index === currentQuestion ? '#1A76D242':'white'}
                    OnClick={()=>{


                        UpdateIndex(index)
                        setTfAnswer(questions[index].answer)
                        setTfQuestion(questions[index].question)
                        setTfResponse(questions[index].response)
                        setQuestionType(questions[index].questionType)
                        setUpdate(!update)
                        console.log(index)
                        console.log(currentQuestion)
                      
                    }
                        
                        
                    }
                    OnClickDelete={()=>{
                        if(questions.length > 1){
                            
                 console.log(questions)

                      let filter = questions.filter(o=>{
                         return o.id !== i.id;
                      })
                 console.log(filter)
                 setCurrentQuestion(0)
                      setQuestions(filter)

                    }
                    }}
                    QuestionNumber={<div style={{display:'inline'}}><Typography variant="subtitle2"><b>Question {index + 1}</b></Typography><Typography sx={{}} variant="caption">{i.questionType === "trueFalse" ?'True / False' :'Multiple Choice'}</Typography></div>}
                    Question={i.question.substring(0,60)}
                    /> 
                })}
                <div ref={modList} />
                </Box>
                <Stack sx={{textAlign:'center'}}>
                <Button onClick={()=>{
                   
                   if(questionType === 'trueFalse'){
                    questions.push({id: uuid(),question:'', questionType:questionType, answer: tfAnswer, response:'', options:['true','false']})
                    setTfAnswer(true)
                   } else{
                    questions.push({id: uuid(),question:'', questionType:questionType, answer: tfAnswer, response:'', options:[]})
                    setTfAnswer('')
                   }
                   
                    setCurrentQuestion(questions.length - 1)
                    
                    setTfQuestion('')
                    setTfResponse('')
                    setModulesUpdate(!modulesUpdate)
                
                }} variant="contained" sx={{fontSize:20, marginTop:'10px'}}><b>+</b></Button>

<Button onClick={()=> AddQuiz(props.UserId, sessionStorage.getItem('trainingId'), questions)}  variant="contained" sx={{fontSize:14, marginTop:'10px'}}><b>Save Quiz to course</b></Button>
                </Stack>
             </Paper>
            </Grid>

       <UpdateSnackbar
       Open={openBar}
       />
        </Grid>
        </Box>
    )
}
import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export const DocumentCard = (props)=> {
  return (
    <Card sx={{ width:'250px', margin:'10px' }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          DOCUMENT
        </Typography>
        <Typography variant="button" component="div">
          {props.DocumentName}
        </Typography>
        <Typography sx={{ mb: 1.5, color:props.DocumentColor, fontWeight:'bold' }} color="text.secondary">
          {props.FileType}
        </Typography>
        
      </CardContent>
      <CardActions sx={{float:'right'}}>
       <Link target="_blank" download={props.DocumentTitle} title={props.DocumentTitle} href={props.LinkUrl}><Button size="small">Download</Button></Link>
      </CardActions>
    </Card>
  );
}
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import Link from "@mui/material/Link";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { formatDate } from "../classes/global-functions";
import { API } from "../controllers/API";
import DeleteIcon from '@mui/icons-material/Delete';
import { InputGroup } from "react-bootstrap";





export const ProfileBasic = ()=>{
    const data = JSON.parse(sessionStorage.getItem('profile-data'))
    const token = JSON.parse(sessionStorage.getItem('token'))
    const [userData, setUserData] = useState({name:'', dateSubscribed:'', membershipType:'', email:'', learnerCategories:[]})
    const [update,setUpdate]= useState(false);
    const [renewalDate, setRenewalDate] = useState('')
    const [presenterName, setPresenterName] = useState('');
    const [presenterTitle, setPresenterTitle] = useState('');
    const [presenterNameEditButton, setPresenterNameEditButton] = useState('EDIT');
    const [presenterTitleEditButton, setPresenterTitleEditButton] = useState('EDIT');
    const [presenterNameDisabled, setPresenterNameDisabled] = useState(true);
    const [presenterTitleDisabled, setPresenterTitleDisabled] = useState(true);
    
    const [replyTo, setReplyTo] = useState('');
    const [replyToEditButton, setReplyToEditButton] = useState('EDIT');
    const [replyToDisabled, setReplyToDisabled] = useState(true);




const deleteCategory = async (a) => {
  const response = await axios.post(`${API}delete-learner-category`, {id:data.id, catId:a})

if(response.data == 'error'){
  window.alert('Something went wrong. Please try agian.')
} else {
  window.alert('Category has been removed. This will not change any of the learners. It simply just removed the category from the preloaded list when adding learners.')
  setUpdate(!update)
}

}
    return (
        <Box>
            <Typography sx={{fontSize:22}} variant="button"><b>Profile</b></Typography>
            <Container>
      <Row>
        <Col>
             <Form.Group>
             <Form.Label><Typography variant="button" sx={{fontSize:16}}><b>NAME</b></Typography></Form.Label>
             <Form.Control value={token.data.firstName + ' ' + token.data.lastName} type="text" />
             </Form.Group></Col>
        <Col>
             <Form.Group>
             <Form.Label><Typography variant="button" sx={{fontSize:16}}><b>EMAIL</b></Typography></Form.Label>
             <Form.Control value={token.data.email} type="text" />
             </Form.Group></Col>
      </Row>
      <Row className="mt-2">
    
        <Col>  <Form.Group>
             <Form.Label><Typography variant="button" sx={{fontSize:16}}><b>Membership Type</b></Typography></Form.Label>
             <Form.Control value={token.data.membershipType} type="text" />
             <Link target="_blank" sx={{float:'right', paddingRight:1}} href="https://employeetrainingtoolkit.com/#pricing"><Typography variant="button" sx={{fontSize:'12px',color:'blue'}}>Upgrade</Typography></Link>
             </Form.Group>
             </Col>
             <Col></Col>
      </Row>
      <hr></hr>

     

     

    </Container>
 

    
<div className="m-3" style={{textAlign:'end'}}>
  <Button onClick={()=>{
    sessionStorage.clear()
    window.location.reload()
  }} variant="contained" sx={{fontWeight:'bold'}} >Logout</Button>
</div> 

         
        </Box>
    )
}
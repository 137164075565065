import axios from "axios";
import { API } from "../../controllers/API";
import uuid from "react-uuid";

//Upload Documents
export const HandleUploadDocument = (CurrentDocument, Email, CourseName, DocumentLinks, Documents, SetDocUpload, SetUpdateArray, index) => {

    

    const formData = new FormData();
    formData.append('file', CurrentDocument);
    formData.append('folder', Email)
    formData.append('courseId', CourseName)
    axios.post(API+'upload-document',formData)
      .then((response) => {
        // Video uploaded successfully
        
      console.log(response.data)
     DocumentLinks.push(
      {
        id:uuid(),
        documentName:response.data.fileUrl.split('/').pop(),
        link:response.data.fileUrl,
        type:response.data.fileUrl.split(/[#?]/)[0].split('.').pop().trim()
    }
      )
     SetDocUpload(DocumentLinks.length / Documents.length * 100)
      console.log(DocumentLinks.length / Documents.length * 100)
      SetUpdateArray()
      })
      .catch((error) => {
        // Error handling
   console.log(error)
       
        alert('Something went wrong try again')
      

      });
  };

  

  export const HandleCoverImageUpload = (CoverFile,Email,Id, CourseName, SetUpdateArray, poster) => {

    

    const formData = new FormData();
  
    formData.append('file', CoverFile);
    formData.append('folder', Email)
    formData.append('courseId', Id)
    formData.append('courseName', CourseName)
    axios.post(API+'upload-cover-image',formData)
      .then((response) => {
        // Video uploaded successfully
        
      console.log(response.data)
    poster.push(response.data.fileUrl)
     SetUpdateArray()

      })
      .catch((error) => {
        // Error handling
   
        poster.push('')
        SetUpdateArray()
     
     

      });
  };

  export const HandleAudioUpload = (AudioFile,Email,Id, CourseName, SetUpdateArray, audio) => {

    

    const formData = new FormData();
  
    formData.append('file', AudioFile);
    formData.append('folder', Email)
    formData.append('courseId', Id)
    formData.append('courseName', CourseName)
    axios.post(API+'upload-audio',formData)
      .then((response) => {
        // Video uploaded successfully
        
      console.log(response.data)
    audio.push(response.data.fileUrl)
     SetUpdateArray()

      })
      .catch((error) => {
        // Error handling
   console.log(AudioFile)
   console.log(error)
       
        alert('Something went wrong try again')
     

      });
  };


  export const UploadModules = (modules, trainingId, userId, email,ProgressArray, saveAndExit)=>{
      console.log(modules)
      console.log(trainingId)
      console.log(email)
      console.log(userId)
      console.log(sessionStorage.getItem('courseName'));
     let courseName = sessionStorage.getItem('courseName');

    modules.forEach((i,index) => {
      if( i.type === 'video' && typeof i.videoLink !== 'string'){

        const formData = new FormData();
        formData.append('video', i.videoLink);
        formData.append('folder', email)
        formData.append('title', `${courseName} Module ${index + 1} - ${i.moduleTitle}`)
          console.log(i)
          axios.post(API+'upload-video',formData)
      .then((response) => {
        // Video uploaded successfully
      
       
        const videoUri = response.data.link;
        modules[index].videoLink = videoUri;
        
        console.log(videoUri);
        ProgressArray(index, modules, saveAndExit)


        console.log(modules[index].videoLink)
    
      })
      .catch((error) => {
        // Error handling
        console.log(error)
       alert('Something went wrong. Please try again.')
      });

      } else {
        ProgressArray(index, modules, saveAndExit)
      }

      if(i.type === 'audio'&& typeof i.audioLink !== 'string'){
        const formData = new FormData();
  
    formData.append('file', i.audioLink);
    formData.append('folder', email)
    formData.append('courseId', trainingId)
    formData.append('courseName', `${courseName} Module ${index + 1} - ${i.moduleTitle}`)
    axios.post(API+'upload-audio',formData)
      .then((response) => {
        // Video uploaded successfully
        
      console.log(response.data)
      modules[index].audioLink = response.data.fileUrl
      ProgressArray(index, modules, saveAndExit)

      })
      .catch((error) => {
        // Error handling

   console.log(error)
       
        alert('Something went wrong try again')
     

      });
      } else {
        ProgressArray(index, modules, saveAndExit)
      }

      if(i.type === 'text'){
        ProgressArray(index, modules, saveAndExit)
      }
    });





   

   
  }


  // const UploadModule = ()=>{

  // }

  const UploadModule = (array, email, index, courseName, ProgressArray,saveAndExit) => {
      
    array.forEach(async i=>{

      if( i.type === 'video'){

        const formData = new FormData();
        formData.append('video', i.videoLink);
        formData.append('folder', email)
        formData.append('title', `${courseName} Module ${index + 1} - ${i.moduleTitle}`)
          console.log(i)
          let resp = await axios.post(API+'upload-video',formData)
      .then((response) => {
        // Video uploaded successfully
      
       
        const videoUri = response.data.link;
        array[index].videoLink = videoUri;
        
        console.log(videoUri);
        ProgressArray(index, array, saveAndExit)


        console.log(array[index].videoLink)
    
      })
      .catch((error) => {
        // Error handling
        console.log(error)
       alert('Something went wrong. Please try again.')
      });

      } 
    })
  }
import './App.css';
import {
  Route,
  Routes,
 
} from 'react-router-dom';
import { Login } from './screens/Login';
import { Home } from './screens/Home';
import useToken from './hooks/useToken'
import { useState, useEffect } from 'react';
import { Video } from './screens/Video';
import { TrainingSetup } from './screens/TrainingSetup/TrainingSetup';
import { BasicHome } from './screens/BasicHome';
import { LearnerDetails } from './screens/LearnerDetails';
import { TrainingDetails } from './screens/TrainingDetails';
import { TrainingBuilder } from './screens/Training-Builder/TrainingBuilder';
import { BuildTraining } from './screens/Training-Builder/BuildTraining';
import { EditTraining } from './screens/Training-Builder/EditTraining';
import axios from 'axios';
import { API } from './controllers/API';
import { TrainingLibraryDetails } from './screens/TrainingLibraryDetails';
import Training from './screens/Training/Training';
import TrainingDemo from './screens/Training/TrainingDemo';
function App() {
  // const [token, setToken] = useState(true)
  const [basicMembership, setBasicMembership] = useState(false)
  const { token, setToken } = useToken();

  useEffect(()=>{
    const wakeUp =  async () => {
      let resp = await axios.get(API);
      console.log(resp)
      console.log(token)
    }
  wakeUp();

  },[])
  if(!token) {

    return(
      <Routes> 
        <Route path="/" element={ <Login setToken={setToken} />} />
        <Route path="/training" element={ <Training />} />
        <Route path="/demo" element={<TrainingDemo/>}/>
      </Routes>
    )
  } else{
    if(token.memberType === 'diy'){
      return (
        
<Routes>
    <Route path="/" element={<BasicHome/>} />
    <Route path="/training-library" element={ <TrainingLibraryDetails />} />
    <Route render={() => <h1>Page not found</h1>} />
    </Routes>

)
    } 
    if (token.memberType === 'basic') {
      
  return (
    <Routes>
    <Route path="/" element={<Home/>} />
    <Route path="/training" element={ <Training />} />
    <Route path="/training-setup" element={ <TrainingSetup />} />
    <Route path="/demo" element={<TrainingDemo/>}/>
    <Route path="/video" element={ <Video />} />
    <Route path="/training-library" element={ <TrainingLibraryDetails />} />
    <Route path="/edit-training" element={ <EditTraining />} />
    <Route path="/build-training" element={ <BuildTraining />} />
    <Route path="/training-details" element={ <TrainingDetails />} />
    <Route path="/learner-details" element={ <LearnerDetails />} />
    <Route render={() => <h1>Page not found</h1>} />
    </Routes>
  );
} 


if (token.memberType === 'pro' || token.memberType === 'enterprise') {
      
  return (
    <Routes>
    <Route path="/" element={<Home/>} />
    <Route path="/training" element={ <Training />} />
    <Route path="/training-setup" element={ <TrainingSetup />} />
    <Route path="/demo" element={<TrainingDemo/>}/>
    <Route path="/video" element={ <Video />} />
    <Route path="/training-replay" element={ <TrainingLibraryDetails />} />
    <Route path="/training-builder" element={ <TrainingBuilder />} />
    <Route path="/training-library" element={ <TrainingLibraryDetails />} />
    <Route path="/edit-training" element={ <EditTraining />} />
    <Route path="/build-training" element={ <BuildTraining />} />
    <Route path="/training-details" element={ <TrainingDetails />} />
    <Route path="/learner-details" element={ <LearnerDetails />} />
    <Route render={() => <h1>Page not found</h1>} />
    </Routes>
  );
} 



}


}

export default App;

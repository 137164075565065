import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Slider,
  Typography,
  IconButton,
  Grid,
  Paper,
  Box,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import SaveIcon from '@mui/icons-material/Save';
import ToolTipButton from "../../../components/TooltipButton";
import axios from "axios";
import { API } from "../../../controllers/API";
import { ProgressBar } from "react-bootstrap";
import Replay10Icon from '@mui/icons-material/Replay10';
import Forward10Icon from '@mui/icons-material/Forward10';
const TinyText = styled(Typography)({
  fontSize: "0.75rem",
  opacity: 0.9,
  fontWeight: 500,
  letterSpacing: 0.2,
});

export const SingleAudio = ({ title, imageUrl, audioUrl, description, moduleTitle, CourseTitle, Learner, TrainingId, UserId, ShowSeek, AudioEnded }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef(null);
  const [playedSeconds, setPlayedSeconds] = useState(Learner.progress);
  const [progress, setProgress] = useState(Learner.percentage); // Adding progress state
  const [initialProgress, setInitialProgress] = useState(Learner.percentage)

  useEffect(() => {
    audioRef.current?.addEventListener("timeupdate", handleTimeUpdate);
    return () => {
      audioRef.current?.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, []);

  useEffect(() => {
    audioRef.current.currentTime = playedSeconds; // Seek to played seconds on load
  }, [playedSeconds]);

  const handleTogglePlay = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const audio = audioRef.current;
    setCurrentTime(audio.currentTime);
  };

  useEffect(() => {
    audioRef.current.currentTime = playedSeconds;
  }, [playedSeconds]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (progress < 99) {
        const confirmationMessage = "You have unsaved changes. Are you sure you want to leave?";
        event.preventDefault();
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [progress]);

  useEffect(() => {
    if (parseInt(progress) === 100) {

      AudioEnded() // Call the function when progress reaches 100%
    }
  }, [progress]);

 

  useEffect(() => {
    const audio = audioRef.current;

    const playPromise = audio.play();
    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          setIsPlaying(true);
        })
        .catch((error) => {
          console.log("Auto-play prevented: ", error);
        });
    }



    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);

      // Calculate percentage complete
      const duration = audio.duration;
      if (duration) {
        const calculatedProgress = (audio.currentTime / duration) * 100;
        setProgress(calculatedProgress);
      }
    };

    audio.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      audio.removeEventListener("timeupdate", handleTimeUpdate);
    };


    
  }, []);

  const handleSeekForward = () => {
    const audio = audioRef.current;
    if (audio.currentTime + 10 < audio.duration) {
      audio.currentTime += 10;
      setCurrentTime(audio.currentTime);
    }
  };

  const handleSeekBackward = () => {
    const audio = audioRef.current;
    if (audio.currentTime - 10 > 0) {
      audio.currentTime -= 10;
      setCurrentTime(audio.currentTime);
    } else {
      audio.currentTime = 0;
      setCurrentTime(0);
    }
  };


  const handleSeekChange = (e, value) => {
    const audio = audioRef.current;
    audio.currentTime = value;
    setCurrentTime(value);
  };

  const saveProgress = async () => {
    console.log(progress)
    if(progress < 99){
      
    Learner.progress = playedSeconds;
    try {
      Learner.progress = playedSeconds; // Update the playedSeconds in the Learner object
      const resp = await axios.post(API + 'update-progress', {
        userId: UserId,
        trainingId: TrainingId,
        learnerId: Learner.id,
        playedSeconds: currentTime,
        percentage: Math.round(progress),
      });

      if (resp.data === 'error') {
        alert('Something went wrong and your progress was not saved.');
      } else {
        alert('Your progress has been saved. You may exit and return to this page, and you will pick up where you left off.');
      }
    } catch (error) {
      console.error('Error saving progress:', error);
    }

  }
  };

  return (
    <Box>
          <Grid container>
        <Grid xs={11} sm={11} item>
        <Typography sx={{fontSize:'18px'}} variant="button">
         <b>  {CourseTitle}</b> 
        </Typography>
        </Grid>

        <Grid sx={{textAlign:'right'}}  xs={1} sm={1} item>
          <ToolTipButton 
          Placement="left"
          Title={<Typography sx={{fontSize:12}} variant="button"><b>SAVE PROGRESS</b></Typography>}
          onClick={saveProgress}
          Icon={<SaveIcon htmlColor="white"/>}
          style={{backgroundColor:'#1B76D2'}}
          />
        
        </Grid>
      </Grid>
       
    <Grid
    sx={{
      marginTop: 10,
      display: "flex",
      width: "100%",
      // paddingTop: 5,
      margin: 0,
    }}
    container
    spacing={2}
  >
    <Grid item xs={12} sm={8}>
      <Card sx={{ padding: 2, width: "100%", textAlign:'center' }}>

          <CardMedia component="img" image={imageUrl} sx={{height:'320px', width: '100%', }} />
          
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {title}
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
             
              <Slider
           
               sx={{marginLeft:1.5, marginRight:1.5, display:ShowSeek === true ? 'none':'block'}}
                aria-label="time-slider"
                value={currentTime}
                onChange={handleSeekChange}
                max={audioRef.current && audioRef.current.duration}
              />
            </div>
            <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: -2,
            }}
          >
            <TinyText sx={{ marginTop: ShowSeek ? "10px" : "0px" }}>
            {isNaN(currentTime) ? '0:00' : formatTime(currentTime)}
            </TinyText>
            <TinyText sx={{ marginTop: ShowSeek ? "10px" : "0px" }}>
            {isNaN(audioRef.current ? audioRef.current.duration : 0) ? '0:00' : formatTime(audioRef.current ? audioRef.current.duration : 0)}
            </TinyText>
          </Box>
            <audio ref={audioRef} src={audioUrl} />
            <IconButton onClick={handleSeekBackward}>
          <Replay10Icon htmlColor="#1B76D2"/>
        </IconButton>
            <Button variant="contained" onClick={handleTogglePlay}>
              {isPlaying ? 'Pause Training' : progress > 0 ? 'Resume Training': 'Start Training'}
            </Button>
            <IconButton onClick={handleSeekForward}>
           <Forward10Icon htmlColor="#1B76D2"/>
        </IconButton>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4} sx={{ display: "flex", width: "100%" }}>
     <Paper sx={{ padding: 2, height: 500, width: "100%", display: "flex", flexDirection: "column" }}>
  <Typography variant="button" sx={{ marginBottom: 0 }}>
    <b>{'Description'}</b>
  </Typography>
  <hr />
  <div
    dangerouslySetInnerHTML={{ __html: description }}
    style={{ height: "100%", overflow: "auto" }}
  ></div>
</Paper>
    </Grid>
    </Grid>
    <Box sx={{display:initialProgress === 0 ? 'block': 'none'}}>
     <hr />
     <Typography variant="button">Training Progress: </Typography>
     <Typography variant="button">
       <b>{Math.round(progress)}%</b>
     </Typography>
     <ProgressBar defaultValue={progress} now={progress} />

     <hr />
   </Box>
   
    <Box sx={{display:initialProgress !== 0 ? 'block': 'none'}}>
     <hr />
     <Typography variant="button">Training Progress: </Typography>
     <Typography variant="button">
       <b>{Math.round(initialProgress)}%</b>
     </Typography>
     <ProgressBar now={initialProgress} />

     <hr />
   </Box>

    </Box>
  );
};

const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};

import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

export default function QuizListItem(props) {
  return (
    <div>
          <ListItem
          secondaryAction={
            <IconButton onClick={props.OnClickDelete} edge="end" aria-label="delete">
              <DeleteIcon htmlColor='red' />
            </IconButton>
          }
          sx={{backgroundColor:props.Selected, borderRadius:2}} disablePadding>
            <ListItemButton  onClick={props.OnClick}>
              {/* <ListItemIcon>
                <Circle />
              </ListItemIcon> */}
              <ListItemText

              primary={props.QuestionNumber}
              secondary={props.Question}
              
              />
            </ListItemButton>
          </ListItem>
          <Divider />
          </div>
  );
}
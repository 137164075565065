import React from 'react';
import '../assets/css/SearchBox.css'; // Import your CSS file for styling
import { Tooltip } from 'react-bootstrap';

const SearchInput = (props) => {
  return (
    <div style={{ float: 'right' }} className="search-container">
      <div className="search-wrapper">
        <span className="search-icon">&#128269;</span>
        <input onChange={props.onChange} value={props.value} type="text" placeholder={props.placeholder} className="search-input" />
      </div>
    </div>
  );
};

export default SearchInput;



import React,{useState, useEffect} from "react";
import Button from "@mui/material/Button";
import Modal from 'react-bootstrap/Modal';
import Form from "react-bootstrap/Form";
import { courseData } from "../../data/CourseData";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Avatar, makeStyles, CircularProgress } from '@mui/material';
import { LearnerTransferList } from "../../components/LearnerTransferList";
import { Accordion } from "react-bootstrap";
import uuid from "react-uuid";
import { Link, useNavigate } from "react-router-dom";
import { VideoCard } from "../../components/VideoCard";
import axios from "axios";
import { API } from "../../controllers/API";
import SearchBox from "../../components/SearchInput";
import { Box } from "@mui/material";
import '../../assets/css/table-item-style.css'
import ViewToggle from "../../components/training-builder-components/ViewToggle";
import ComputerIcon from '@mui/icons-material/Computer';
import ToolTipButton from "../../components/TooltipButton";
import EditIcon from '@mui/icons-material/Edit';

export const VideoTrainingLibrarySetup = (props)=>{
  const data = JSON.parse(sessionStorage.getItem('profile-data'))
const [library, setLibrary] = useState(true)
const [detail, setDetail] = useState(<p>Mason</p>)
const [show, setShow] = useState(false);
const handleShow = (a) => {setShow(true); setCourse(a)};
const handleClose = () => {setShow(false); setCourse('')}
const [learnerList, setLearnerList] = useState([[{"name":"null","email":"null"}]])
const navigate = useNavigate();
const [course, setCourse] = useState('')

//TRAINING SETUP VARIABLES
const [seekBar, setSeekBar] = useState(false);
const [sendTrainingsNow, setSendTrainingsNow] = useState(false);
const [loading,setLoading] = useState(true);
const [mainData,setMainData] = useState(false)
const [searchValue, setSearchValue] = useState('')

const [view, setView] = useState('cards');
const [emailSettings, setEmailSettings] = useState({
  contactMessage:'',
  completeBy:'',
  specifyDate: false,

})

const handleViewChange = (event, newView) => {
  setView(newView);
  console.log(newView);
};

useEffect(() => {
  async function fetchData() {
    const response = await axios.post(API + 'get-data', { id: data.id });

 
    setMainData(response.data);
    console.log('mainData');
    console.log(mainData);
    setLoading(false);
  }
  fetchData();
}, []);

     if(loading){
      return <Box sx={{textAlign:'center', marginTop:20}}>
      <CircularProgress/>
      <Typography><b>Loading</b></Typography>
    </Box>
    } else {
    if(library){
        return (
          <div>
             <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',  marginBottom:3 }}>
      <SearchBox
      onChange={(e)=>setSearchValue(e.target.value)}
      value={searchValue}
      placeholder="Search"
      />
       <ViewToggle View={view} onChange={handleViewChange} />
    </Box>
    
            {
 view === 'cards' ? (
  <div>
                <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center' }}>

 {courseData.filter((i) =>
          i.courseName.toLowerCase().includes(searchValue.toLowerCase()) 
          
        ).map((i)=>{
                return (<VideoCard  
                    ClickCard={()=>handleShow(i)} Image={i.poster}/>)
               })} 

{mainData.customTrainings.filter((i) =>
          i.courseName.toLowerCase().includes(searchValue.toLowerCase()) 
          
        ).map((i)=>{
                if(i.type === 'single-video'){
                  return (<VideoCard  
                    ClickCard={()=>handleShow(i)} Image={i.poster}/>)
                }
                    
                  })} 
  </div>
  </div>
 ) : (
  <div>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell><Typography variant="body2"><b>COURSE NAME</b></Typography></TableCell>
            <TableCell><Typography variant="body2"><b>RUNTIME</b></Typography></TableCell>
            <TableCell><Typography variant="body2"><b>ACTIONS</b></Typography></TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {courseData.filter((i) =>
          i.courseName.toLowerCase().includes(searchValue.toLowerCase()) 
          
        ).map((item, index) => (
            <TableRow   key={index}>
              <TableCell>
                <Avatar sx={{ width: 125, height: 'auto' }} alt={`Image ${index + 1}`} variant="rounded"  src={item.poster} />
              </TableCell>
              <TableCell>{item.courseName}</TableCell>
              <TableCell>{item.runtime.toUpperCase()}</TableCell>
              <TableCell><Link target="_blank" to='/demo' >
              <ToolTipButton
              Placement="top"
              Icon={<ComputerIcon htmlColor="dodgerBlue"/>}
              Title="VIEW DEMO OF TRAINING"
              onClick={()=>{localStorage.setItem('sample-training', JSON.stringify(item)); localStorage.setItem('seek-option', JSON.stringify(seekBar))}}
            />
            </Link>
            <ToolTipButton
            Placement="top"
              Icon={<EditIcon htmlColor="dodgerBlue"/>}
              Title="CREATE TRAINING"
              onClick={()=>handleShow(item)}
            />
            
            </TableCell>
            </TableRow>
          ))}

        {mainData.customTrainings.filter((i) =>
            i.courseName.toLowerCase().includes(searchValue.toLowerCase()) 
            
          ).map((item, index) => {
            if(item.type === 'single-video'){
           return (
            <TableRow onClick={()=>handleShow(item)} key={index}>
              <TableCell>
                <Avatar sx={{ width: 125, height: 'auto' }} alt={`Image ${index + 1}`} variant="rounded"  src={item.poster} />
              </TableCell>
              <TableCell>{item.courseName}</TableCell>
              <TableCell>{item.runtime.toUpperCase()}</TableCell>
              <TableCell><Link target="_blank" to='/demo' >
                <ToolTipButton
              Icon={<ComputerIcon htmlColor="dodgerBlue"/>}
              Title="View Demo of Training"
              onClick={()=>{localStorage.setItem('sample-training', JSON.stringify(item)); localStorage.setItem('seek-option', JSON.stringify(seekBar))}}
            /></Link></TableCell>

            </TableRow>
          )}})}
        </TableBody>
      </Table>
    </TableContainer>
  </div>
 )
}




<Modal size="lg" style={{zIndex:100000}} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Training Setup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           
            <div style={{display:course != ''?'block':'none'}}>
       <hr></hr>
       <Typography variant="button" sx={{fontSize:16}}><b>Course Details</b></Typography>
       <hr></hr>
       <Form.Group>
        <Form.Label><Typography variant="button"><b>Course Name</b></Typography></Form.Label>
        <br></br>
        <Form.Label><Typography variant="p">{course.courseName}</Typography></Form.Label>
       </Form.Group>

       <Form.Group>
        <Form.Label><Typography variant="button"><b>Course Time</b></Typography></Form.Label>
        <br></br>
        <Form.Label><Typography variant="p">{course.runtime}</Typography></Form.Label>
       </Form.Group>

       <Form.Group>
        <Form.Label><Typography variant="button"><b>Course Description</b></Typography></Form.Label>
        <br></br>
        <Form.Label><div dangerouslySetInnerHTML={{__html:course.courseDescription}}></div></Form.Label>
       </Form.Group>
       <hr></hr>
       </div>
        <Form.Group style={{marginTop:'10px'}}>
           
           <Accordion>
           <Accordion.Item eventKey="0">
        <Accordion.Header><b>ADD LEARNERS</b></Accordion.Header>
        <Accordion.Body>
        <Form.Group className="mb-3 mt-3">
      <Form.Label><b>Select Learners:</b></Form.Label>
      <p>Click a person’s name to add them as a learner. If you would like to remove a person you can click their name in the right column.</p>
      <LearnerTransferList learners={mainData.learners}/>
      </Form.Group>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header onClick={()=>{
          if(learnerList != null){
            setLearnerList(JSON.parse("["+window.learnerList+"]")); 
            // console.log(reviewers)
            
            // setUpdate(!update)
          }
         
        }}><b>FINALIZE</b></Accordion.Header>
        <Accordion.Body>
        <hr></hr>
        <Typography variant="button" sx={{fontSize:16}}><b>Training Options</b></Typography>

        <hr></hr>
        <Form.Check 
        type="switch"
        id="custom-switch"
        value={seekBar}
        onChange={()=>setSeekBar(!seekBar)}
        label={<Typography variant="subtitle2"><b>Require the learner to watch or listen to the entire training without skipping any parts.</b></Typography>}
      />   
           <Typography sx={{fontSize:14}} variant="caption">This will disable the seek function on the video portion of the training. Instead a percentage will be used to monitor the progress of the training. The learner will be able to save their place and return if they can't finish the training in one sitting.</Typography>

            <hr></hr>

        <Form.Check 
        type="switch"
        id="custom-switch"
        value={sendTrainingsNow}
        onChange={()=>setSendTrainingsNow(!sendTrainingsNow)}
        label={<Typography variant="subtitle2"><b>Send Trainings Immdiately upon creation.</b></Typography>}
      />   
     <Typography sx={{fontSize:14}} variant="caption">If you leave this switch off you can mannually send the trainings to each person in the <b>TRAINING MANAGER</b> section by clicking the email icon beside their name.</Typography>
            <hr></hr>
            <Typography variant="button" sx={{fontSize:16}}><b>Email Options</b></Typography>

<hr></hr>
    <Form.Check 
    
type="switch"
id="custom-switch"
onChange={(e)=>{
  setEmailSettings((prevData) => ({ ...prevData, specifyDate: !emailSettings.specifyDate, completeBy:'' }))
 }}
    


label={<Typography variant="subtitle2"><b>Specify date for employee to complete training by.</b></Typography>}
/>   

<Form.Control
style={{display:emailSettings.specifyDate === false ? 'none': 'block' }}
disabled={!emailSettings.specifyDate}
value={emailSettings.completeBy}
className="mt-3"
type="date"
onChange={(e)=>{
  setEmailSettings((prevData) => ({ ...prevData, completeBy: e.target.value }))
  console.log(emailSettings.completeBy)
 }}        />
 <hr></hr>
            
            <Typography variant="subtitle2"><b>Contact Message</b></Typography>  
            <Typography sx={{fontSize:14, marginBottom:3}} variant="caption">Write a quick message about who the employee should contact if they have any questions about the training.</Typography>     
            <Form.Control as="textarea" rows={3} onChange={(e)=>{
             setEmailSettings((prevData) => ({ ...prevData, contactMessage: e.target.value }))
             console.log(emailSettings)
            }}/>
    <hr></hr>
            <Typography variant="button"><b>Learners</b></Typography><br></br>
            <Typography sx={{fontSize:14}} variant="caption">Confirm the list of people you would like to include in the training. You can change this by going to the learners section and click on their name to remove them from the training.</Typography>
            <TableContainer sx={{padding:'10px', marginTop:5}} component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
<TableHead>
          <TableRow>
   
          <TableCell>Name</TableCell>
          <TableCell>Email</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
       {learnerList[0].map(i=>{
        return(
          <TableRow>
      
          <TableCell>{i.name}</TableCell>
          <TableCell>{i.email}</TableCell>
        </TableRow>
        )
       })}
        </TableBody>
      </Table>
    </TableContainer>
            <hr></hr>
            <Form.Group >
            <Link target="_blank" to='/demo' ><Button onClick={()=>{localStorage.setItem('sample-training', JSON.stringify(course)); localStorage.setItem('seek-option', JSON.stringify(seekBar))}} variant="contained">View Demo of Training</Button></Link>
          
            <Button onClick={async()=>{
              let learnerListArr = []
     
              learnerList[0].forEach(i=>{
                learnerListArr.push({
                  id:i.id,
                  firstName: i.firstName,
                  lastName:i.lastName,
                  name:i.name,
                  email:i.email,
                  category:i.category,
                  status:sendTrainingsNow ? 'sent':'not sent',
                  progress:0,
                  percentage:0

                })
              })
                let trainingObject = {
                  id:uuid(),
                  dateCreated: new Date(),
                  sentBy:{presenterName:data.certificateSettings.name === 'NA'? data.name : data.certificateSettings.name, title:data.certificateSettings.title === 'NA'? "Presenter" : data.certificateSettings.title ,name: data.name, email:data.replyToEmail},
                  seekbar:seekBar,
                  trainingSent:sendTrainingsNow,
                  learners: learnerListArr,
                  course:course,
                  emailSettings:emailSettings,
                  type:'video',
                  status:'in progress',
                 

                }
                console.log(trainingObject)
            console.log(learnerList[0][0])
            if(learnerList[0][0] === undefined){
              alert('Please select the learners for this training.')} else {
                
              if(learnerList[0][0].name === "null" ){
                alert('Please select the learners for this training.')
              } else {
             let confirmSend = window.confirm(sendTrainingsNow ?'Are you sure you would like to setup this training? Trainings will be sent to the learners immediately.':'Are you sure you are ready to setup the training?');
             if(confirmSend){
              
            

            let resp = await  axios.post(API+ 'create-training', {userId:data.id, training:trainingObject})
                
            if(resp.data == 'trainings-created' && sendTrainingsNow){
              window.alert('Trainings have been setup and sent!')
              handleClose()
              navigate('/', { replace: true, state:'TM' })
              window.learnerList = '[]'
              setLearnerList([[{"name":"null","email":"null"}]])
            } 

            if(resp.data == 'trainings-created' && sendTrainingsNow === false){
              window.alert('Trainings have been setup. You can now go to the training manager to send them to each person at your leisure.')
              handleClose()
              navigate('/', { replace: true, state:'TM' })
              window.learnerList = '[]'
              setLearnerList([[{"name":"null","email":"null"}]])
            } 
            
            if(resp.data != 'trainings-created'){
              window.alert('Something went wrong. Please Try again.')
              window.learnerList = '[]'
              setLearnerList([[{"name":"null","email":"null"}]])
            }
             } 
            }
          }
            }} sx={{float:'right'}} variant="contained">{sendTrainingsNow ? 'Assign and Send Trainings': 'Assign Training'}</Button>
           </Form.Group>
         
        </Accordion.Body>
      </Accordion.Item>
     
           </Accordion>
           </Form.Group>
          
        </Modal.Body>
        
      </Modal>
            
            </div>
        
        )
    } else {
        return (
            detail
        )
       
      }
    
    }
    
    }
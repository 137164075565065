import * as React from 'react';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export default function ViewToggle(props) {

  return (
    <ToggleButtonGroup
    color='primary'
      value={props.View}
      exclusive
      onChange={props.onChange}
      aria-label="text alignment"
    >
      <ToggleButton value="cards" aria-label="left aligned">
        <ViewModuleIcon />
      </ToggleButton>
      <ToggleButton value="list" aria-label="centered">
        <ViewListIcon />
      </ToggleButton>
     
    </ToggleButtonGroup>
  );
}
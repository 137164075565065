import React from "react";
import { VideoPlayer } from "../components/VideoPlayer";



export const Video = ()=>{

    return ( 
        <div 
        style={{display:'flex', justifyContent:'center', alignItems:'center',height:'100vh', weight:'100vw', color:'black'}}
        >
           <VideoPlayer/>
        </div>
    )
}
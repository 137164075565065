import React, {useEffect, useState} from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { TrainingDetail } from "../subscreens/TrainingDetail";
import { DocumentCard } from "../components/DocumentCard";
import { Box } from "@mui/system";
import Drawer from '@mui/material/Drawer';
import { AppBar, Button, CssBaseline, IconButton, Toolbar, Typography } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../assets/css/images/icon6.png';
import { TrainingDetailAudio } from "../subscreens/TrainingDetailAudio";


const drawerWidth = 240;
const navItems = ['Back To Training Library'];

export const TrainingLibraryDetails = (props)=>{
    
    const location = useLocation()
    const [trainingData, setTrainingData] = useState(location.state.state)
    const [mobileOpen, setMobileOpen] = useState(false);
    const navigate = useNavigate();
    const { windowSize } = props;
    
    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
      };

    useEffect(()=>{
      console.log(location.state)
      
    },[])

    const container = windowSize !== undefined ? () => window().document.body : undefined;
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>

        <CssBaseline />
        <AppBar component="nav">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ display: { xs: 'none', sm: 'block', padding: 10 } }}>
              <img src={logo} width="75px" alt="Logo" />
            </Typography>
            <Typography color={'white'}>
              <b>TRAINING LIBRARY</b>
            </Typography>

            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              {navItems.map((item) => (
                <Button key={item} sx={{ color: '#fff', position: 'absolute', right: 15, top: 30 }} onClick={() => navigate('/', { replace: true, state:location.state.library })}>
                 <b> {item}</b>
                </Button>
              ))}
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {/* {drawer} */}
          </Drawer>
        </Box>
        <Box className='main-box' sx={{marginTop:'120px !important', maxWidth:'1080px', margin:'auto',padding:{ xs: '0px', sm: '20px' }, paddingTop:'80px'}}>
         {location.state.library === 'video' ?
         (<TrainingDetail 
            // ClickBack={()=>ClickBack()}
            CourseName={trainingData?.courseName}
            CourseDescription={`${trainingData?.courseDescription}`}
            Image={trainingData?.poster}
            Runtime={trainingData?.runtime}
            VideoUrl={trainingData?.videoLink}
            VideoDownloadLink={trainingData?.videoDlLink}
            AudioDownloadLink={trainingData?.audioLink}
            Documents={trainingData?.documents.map((i)=>{
                let color;
                if(i.fileType.toUpperCase() == 'PPTX'){
                    color = '#C24020'
                } 
    
                if(i.fileType.toUpperCase() == 'PDF'){
                    color = '#F31000'
                }
    
                if(i.fileType.toUpperCase() == 'DOCX'){
                    color = '#0F3C8D'
                }
    
                if(i.fileType.toUpperCase() == 'VTT'){
                    color = '#4648CF'
                }
                
                return (
                <DocumentCard
                DocumentName={i.documentName}
                FileType={i.fileType.toUpperCase()}
                DocumentColor={color}
                LinkUrl={i.link}
                DocumentTitle={`${trainingData?.courseName} - ${i.documentName}`}
                />)
            })}
            />): (
                <TrainingDetailAudio 
            // ClickBack={()=>ClickBack()}
            CourseName={trainingData?.courseName}
            CourseDescription={`${trainingData?.courseDescription}`}
            Image={trainingData?.poster}
            Runtime={trainingData?.runtime}
            AudioLink={trainingData?.audioLink}
            VideoDownloadLink={trainingData?.videoDlLink}
            AudioDownloadLink={trainingData?.audioLink}
        
            Documents={trainingData?.documents.map((i)=>{
                let color;
                if(i.fileType.toUpperCase() == 'PPTX'){
                    color = '#C24020'
                } 
    
                if(i.fileType.toUpperCase() == 'PDF'){
                    color = '#F31000'
                }
    
                if(i.fileType.toUpperCase() == 'DOCX'){
                    color = '#0F3C8D'
                }
    
                if(i.fileType.toUpperCase() == 'VTT'){
                    color = '#4648CF'
                }
                
                return (
                <DocumentCard
                DocumentName={i.documentName}
                FileType={i.fileType.toUpperCase()}
                DocumentColor={color}
                LinkUrl={i.link}
                DocumentTitle={`${trainingData?.courseName} - ${i.documentName}`}
                />)
            })}
            />

         )}
        
        </Box>
        </Box>
    )
}
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DownloadIcon from '@mui/icons-material/Download';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MuiAudioPlayer from "mui-audio-player-plus";
import { PosterAudioCard } from "../components/PosterAudioCard";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Link, ListItemIcon, ListItemText } from "@mui/material";





export const TrainingDetailAudio = (props)=>{
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
    return (
        <div >
      
      <Row>
        <Col xs={12} md={6}>
        <PosterAudioCard  Image={props.Image}/>
        </Col>
        <Col xs={12} md={6}>
        <Typography variant="button" sx={{fontWeight:'bold', fontSize:'20px'}}>{props.CourseName}</Typography>

          <br></br>
          <br></br>
        <MuiAudioPlayer
          id="inline-timeline"
          display="timeline"
          containerWidth={'90%'}
          inline
          src={props.AudioLink}
        />

     <br></br>
     <Typography variant="button">{props.Runtime}</Typography>
     <br></br>
     <IconButton 
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClick}
     >
              <DownloadIcon />
            </IconButton>
            <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <VolumeUpIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText><Link sx={{textDecoration:'none', color:'black'}} target="_blank" download={props.CourseName} href={props.AudioDownloadLink}><Typography variant="button">Download Audio</Typography></Link></ListItemText>
          <Typography sx={{marginLeft:'10px !important'}} variant="body2" color="text.secondary">
          <DownloadIcon />
          </Typography>
        </MenuItem> 
      </Menu>
        </Col>
      </Row>
       <hr></hr>
       <Typography sx={{fontWeight:'bold', fontSize:16}} variant="button">Description</Typography>
       <br></br>
        <Typography variant="subtitle1">
         <div dangerouslySetInnerHTML={{__html:props.CourseDescription}}/>

      </Typography>
    <hr></hr>
    <Typography sx={{fontWeight:'bold', fontSize:16}} variant="button">DOCUMENTS</Typography>
    <div style={{display:'flex', flexWrap:'wrap' }}>
       {props.Documents}
      
       </div>
       <hr></hr>
      
        </div>
    )
}
import  React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import uuid from "react-uuid";
import logo from '../../assets/css/images/icon6.png'
import '../../assets/css/style.css'
import { useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Form from 'react-bootstrap/Form';
import stockImg from '../../assets/css/images/stock_custom_card_img.png'
import axios from 'axios';
import { API } from '../../controllers/API';
import { useState } from 'react';
import { UploadCoverImage } from '../../components/training-builder-components/UploadCoverImage';
import { UploadDocuments } from '../../components/training-builder-components/UploadDocuments';
import BuildModal from '../../components/training-builder-components/BuildModal';
import { UploadAudio } from '../../components/training-builder-components/UploadAudio';
import { formatDate, getFirstNumberFromVimeoLink } from '../../classes/global-functions';
import {useLocation} from 'react-router-dom';
import { UploadVideo } from '../../components/training-builder-components/edit-builder/UploadVideo';
import { DeleteVideoVimeo } from '../../classes/TrainingBuilderFunctions/Delete';
import { EditQuizBuilder } from '../Edit-Quiz/EditQuizBuilder';
import { EditModuleBuilder } from '../Edit-Quiz/EditModuleBuilder';
import UpdateProgressModal from '../../components/training-builder-components/UpdateProgressModal';
import { HandleUploadDocument } from '../../classes/TrainingBuilderFunctions/Upload';
import DeleteFileModal from '../../components/training-builder-components/DeleteFileModal';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const drawerWidth = 240;
const navItems = ['Back To Training Builder'];
const steps = [
  {
    label: 'Select campaign settings',
    description: `For each ad campaign that you create, you can control how much
              you're willing to spend on clicks and conversions, which networks
              and geographical locations you want your ads to show on, and more.`,
  },
  {
    label: 'Create an ad group',
    description:
      'An ad group contains one or more ads which target a shared set of keywords.',
  },
  {
    label: 'Create an ad',
    description: `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`,
  },
];

const customConfiguration = {
  toolbar: {
    items: [
      'heading',
      '|',
      'bold',
      'italic',
      'link',
      'bulletedList',
      'numberedList',
      'blockQuote',
      'undo',
      'redo',
    ],
  },
};

export const EditTraining = (props)=>{
    const location = useLocation();
  const [training, setTraining] = useState(location.state)
  const data = JSON.parse(sessionStorage.getItem('profile-data'))
  const [editorData, setEditorData] = useState('');

  const { windowsize } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const [moduleBuilder, setShowModuleBuilder] = useState(false)
  const [showAudio, setShowAudio] = React.useState(false);
  const [showVideo, setShowVideo] = React.useState(false);
  const [socket, setSocket] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [type, setType] = useState(training.type)
  const [completeUpload, setCompleteUpload] = useState([])
  const [quizSwitch, setQuizSwitch] = useState(false)
  const [showQuiz, setShowQuiz] = useState(false)
  const date = new Date()

  //Text State Variables
  const [trainingTitle, setTrainingTitle] = useState('');
  const [trainingLink, setTrainingLink] = useState('NA');
  const [trainingRuntime, setTrainingRuntime] = useState('');


  const [courseActive, setCourseActive] = useState(training.active);
  //Cover Image Variables
  const[ coverFile, setCoverFile] = useState(training.poster)
  const[ coverImage, setCoverImage] = useState(training.poster === '' ? stockImg : training.poster)
  const[poster,setPoster] = useState([])

   //Document Upload Variables
   const[ currentDocument, setCurrentDocument] = useState()
   const[ documents, setDocuments] = useState(training.documents)
   const[ newDocuments, setNewDocuments] = useState([])
   const[ documentLinks, setDucumentLinks] = useState([])
   const[ docUploadPercent, setDocUploadPercent] = useState()
   const[docUploaded, setDocUploaded] = useState([])
   const [fileSize, setFileSize] = useState(0)
   //Audio Variables
   const[audio,setAudio] = useState([])
   const[ audioFile, setAudioFile] = useState(training.audioLink ? training.audioLink : '')

   //Video Variables
   const[ videoFile, setVideoFile] = useState(training.videoLink ? training.videoLink :'')


   //Updating Modal
   const [open, setOpen] = useState(false);
   const [openDeleteFile, setOpenDeleteFile] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
const [modules, setModules] = useState(training.courseData)
  const [update, setUpdate] = useState(true)

  const [updateData, setUpdateData] = useState(true)
  function isFile(variable) {
    return variable instanceof File;
  }
  useEffect(()=>{
    console.log(location)
    console.log(audioFile)
    console.log(videoFile)
    setEditorData(training.courseDescription);
  },[update])


  // useEffect(()=>{
  //   async function fetchData() {
  //     const response = await axios.post(API + 'get-data',{id:data.id})
    
  //                let filter = response.data.customTrainings.filter((i)=> {return (i.id === training.id) })
  //     console.log(filter[0])
         
  //            setTraining(filter[0])
           
  //      }
  //      fetchData()
  // },[])

  useEffect(()=>{
    async function fetchData() {
      const response = await axios.post(API + 'get-data',{id:data.id})
    
                 let filter = response.data.customTrainings.filter((i)=> {return (i.id === training.id) })
      console.log(filter[0])
         
             setTraining(filter[0])
             setDocuments(filter[0].documents)
           if(filter[0].active){
            setCourseActive(true)
            console.log(courseActive)
           }
       }
       fetchData()
  },[update])
 
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

   

  const UpdateTraining = async (trainingId, email,courseName, training)=>{
    setOpen(true)
    let trObj  = training;
    trObj.courseDescription = editorData;
    console.log(coverFile)

    documents.forEach(i=>{
      if(i?.name){
        const formData = new FormData();
        formData.append('file', currentDocument);
        formData.append('folder', data.email)
        formData.append('courseId', trObj.id)
        axios.post(API+'upload-document',formData)
          .then(async (response) => {
            // Video uploaded successfully
            
          console.log(response.data)
          let newDocumentArr = [];

          documents.forEach(i=>{
            if(i?.documentName){
              newDocumentArr.push(i);
            }
          })
          let item =  {
            id:uuid(),
            documentName:response.data.fileUrl.split('/').pop(),
            link:response.data.fileUrl,
            type:response.data.fileUrl.split(/[#?]/)[0].split('.').pop().trim()
        }
        console.log('item:')

        console.log(item)

         newDocumentArr.push(item)
          trObj.documents = newDocumentArr;
          let respTwo = await axios.post(API+'update-training', {id:data.id, training: trObj})
                
          if(respTwo === 'error'){
              alert('Something went wrong. Please try again.')
          } else {
              console.log(respTwo)

              let filter = respTwo.data.customTrainings.filter(i=>{
                  return i.id === training.id;
              })
               console.log(filter[0])
              setTraining(filter[0])
           
              console.log(training)
              // navigate(".", { replace: true, state: training});
              setOpen(false)
            
             

          }

       
          })
          .catch((error) => {
            // Error handling
       console.log(error)
           
            alert('Something went wrong try again')
          
    
          });
      }

    })
    


    if(coverFile.name){
      const fileExt = coverFile.name.split('.')[1]
      const CoverFile = new FormData();
      CoverFile.append('file', coverFile);
      CoverFile.append('folder', email);
      CoverFile.append('trainingId', trainingId);
      CoverFile.append('courseName', courseName);
      CoverFile.append('fileExt', fileExt);
      let resp = await axios.post(API+'replace-cover-file', CoverFile)
      if(resp === 'error'){
          alert('Something went wrong. Please try again.')
      } else {
                trObj.dateCreated = date;
                trObj.poster = resp.data.fileUrl;
                setAudioFile(resp.data.fileUrl)
                trObj.dateUpdated = date;
                let respTwo = await axios.post(API+'update-training', {id:data.id, training: training})
                
                if(respTwo === 'error'){
                    alert('Something went wrong. Please try again.')
                    setOpen(false)

                } else {
                    console.log(respTwo)
  
                    let filter = respTwo.data.customTrainings.filter(i=>{
                        return i.id === training.id;
                    })
                     console.log(filter[0])
                    setTraining(filter[0])
                    console.log(training)
                    navigate(".", { replace: true, state: training});
                    setOpen(false)
                   
                   

                }
      }
    }
    if(training.type === 'single-audio'){
     
        if(audioFile.name){
          const fileExt = audioFile.name.split('.')[1]
          const formData = new FormData();
      
          formData.append('file', audioFile);
          formData.append('folder', email)
          formData.append('trainingId', trainingId)
          formData.append('courseName', courseName)
          formData.append('fileExt', fileExt);
          let resp = await axios.post(API+'replace-single-audio', formData)
            if(resp === 'error'){
                alert('Something went wrong. Please try again.')
                setOpen(false)

            } else {
                console.log(resp.data.fileUrl)
                
                trObj.dateCreated = date;
                trObj.courseData[0].audioLink = resp.data.fileUrl;
                setCoverFile(resp.data.fileUrl)
                // setTraining({...training, audioLink: `${resp.data.fileUrl}` })
                // setTraining({...training, dateUpdated: date })
                console.log(trObj)
                trObj.dateUpdated = date;
                let respTwo = await axios.post(API+'update-training', {id:data.id, training: training})
                
                if(respTwo === 'error'){
                  setOpen(false)
                    alert('Something went wrong. Please try again.')
                    

                } else {
                    console.log(respTwo)
  
                    let filter = respTwo.data.customTrainings.filter(i=>{
                        return i.id === training.id;
                    })
                     console.log(filter[0])
                    setTraining(filter[0])
                    console.log(training)
                    navigate(".", { replace: true, state: training});
        
                    alert('Updated!')
                    setOpen(false)

                }
               
            }
             
        }
    }

    if(training.type == 'single-video'){
      if(videoFile.name){
        
        let videoId = getFirstNumberFromVimeoLink(trObj.videoLink);
        const formData = new FormData();
        formData.append('video', videoFile);
        formData.append('folder', data.email)
        formData.append('title', training.courseName)
        axios.post(API+'upload-video',formData)
          .then((response) => {
            // Video uploaded successfully
            
            const videoUri = response.data.link;
            console.log(videoUri);
           

            DeleteVideoVimeo(data.email, training.videoLink, async ()=>{
console.log(response.data.link)
              trObj.videoLink = response.data.link
              trObj.videoTrainingLink = response.data.link
              trObj.courseData[0].videoLink = response.data.link

              setVideoFile(response.data.link);
              console.log('done')
              trObj.dateUpdated = date;
              let respTwo = await axios.post(API+'update-training', {id:data.id, training: trObj})
                
              if(respTwo === 'error'){
                setOpen(false)
                  alert('Something went wrong. Please try again.')
                

              } else {
                  console.log(respTwo)

                  let filter = respTwo.data.customTrainings.filter(i=>{
                      return i.id === training.id;
                  })
                   console.log(filter[0])
                  setTraining(filter[0])
                  console.log(training)
                  navigate(".", { replace: true, state: training});
                  setOpen(false)
                  alert('Updated!')
               

              }

            })
          })
          .catch((error) => {
            // Error handling
            setPbActive(false)
            setOpen(false)
            console.error('Failed to upload video:', error);
         

          });
      }
    }

    console.log(!coverFile.name)
    console.log(!videoFile.name)
             console.log(!audioFile.name)
            if(!coverFile.name && !audioFile.name && !videoFile.name){
              trObj.dateUpdated = date;
              let respTwo = await axios.post(API+'update-training', {id:data.id, training: training})
                
              if(respTwo === 'error'){
                setOpen(true)
                  alert('Something went wrong. Please try again.')
           

              } else {
                  console.log(respTwo)

                  let filter = respTwo.data.customTrainings.filter(i=>{
                      return i.id === training.id;
                  })
                   console.log(filter[0])
                  setTraining(filter[0])
                  console.log(training)
                  navigate(".", { replace: true, state: training});
                  setOpen(false)
                  alert('Updated!')
              

              }
            }
  }

  const SendUpdate = async (id, training)=>{

  }

 
  useEffect(() => {
    // Establish WebSocket connection
    const newSocket = new WebSocket('ws://localhost:3002/ws');
    setSocket(newSocket);

    return () => {
      // Close WebSocket connection when component unmounts
      if (newSocket.readyState === 1) {
      newSocket.close();
      }
    };
  }, []);

  useEffect(() => {
    if (socket) {
      // Listen for WebSocket messages
      socket.onmessage = (event) => {
        const progress = JSON.parse(event.data).percentage;
        console.log(progress)
        setUploadProgress(progress);
      };
    }
  }, [socket]);


  useEffect(() => {
    if(uploadProgress === 100 || trainingLink != ''){
      let description = editorData;
      console.log(description)
     

    }
  }, [trainingLink]);

const GoBack = () =>{
  setShowModuleBuilder(false)
  setUpdate(!update)
}
  
  const NextStep = ()=>{
   
      setShowQuiz(true);
      setShowModuleBuilder(false)
      setUpdate(!update)
  }


  const handleToggleValue = (a)=>{
  console.log(a)
    if(a === 'single-audio'){
        setShowAudio(true);
        setShowVideo(false)
        
    }

    if(a === 'single-video'){
        setShowAudio(false);
        setShowVideo(true)
    }

    if(a === 'module'){
        setShowAudio(false);
        setShowVideo(false)
    }
    setType(a)
  }
  
 const[ pbActive, setPbActive] = useState(false)
 
  const DeleteDocument = (id, file) => {
    console.log(file)
    if(isFile(file)){
         let filter = documents.filter(i=>{return i.name !== file.name})
         setDocuments(filter);
         setUpdate(!update)
    } else {
      
    setOpenDeleteFile(true)
    axios.post(API+'delete-document-aws',{email: data.email, trainingId: training.id, fileName: file})
    .then((resp)=>{
      if(resp === 'error'){
        alert('Something went wrong. Try Again.')
      } else {
        
        let filter = training.documents.filter(i=> {return i.id !== id});

        training.documents = filter;
        console.log('filter')
      console.log(filter)

      axios.post(API+'update-training',{id:data.id, training: training})
      .then((resp)=>{
        if(resp === 'error'){
          alert('Something went wrong. Try Again.')
          setOpenDeleteFile(false)
        } else {
          alert('Document Deleted')
          setDocuments(filter)
          setUpdate(!update)
          setOpenDeleteFile(false)
        }
        

      })
      }
      
    })

  }
      }
      


  



  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <img src={logo} width="175px" height="auto"/>
      <Divider />
      <List>
      {/* <Link to="/"> */}
          <ListItem disablePadding>
            <ListItemButton onClick={()=>navigate('/', { replace: true, state:'TM' })}  sx={{ textAlign: 'center' }}>
              <ListItemText primary={"BACK TO DASHBOARD"} />
            </ListItemButton>
          </ListItem>
      {/* </Link> */}
      </List>
    </Box>
  );

  const container = windowsize !== undefined ? () => windowsize().document.body : undefined;

  return (
    <Box sx={{ display: 'flex', justifyContent:'center', backgroundColor:'#ECEEF1' }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{  display: { xs: 'none', sm: 'block', padding:10 } }}
          >
           <img src={logo} width="75px" />
          </Typography>
          <Typography color={'white'}><b>TRAINING BUILDER</b></Typography>

          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              <Button onClick={()=>navigate('/training-builder', { replace: true})} key={item} sx={{ color: '#fff', position:'absolute', right:15, top:30}}>
               <b>{item}</b>
              </Button> 
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
        <div style={{marginTop:10}}>
          <Box sx={{width:'1000px'}} className="mt-5">
          <Grid sx={{display: showQuiz ?'none': moduleBuilder ? 'none':'flex'}} container >
            <Grid item xs={9}>
       <Paper className='p-3'>
      
        <Grid container>
            <Grid sx={{alignSelf:'center'}} item xs={3}>
        <Form.Label><Typography variant='subtitle2'><b>Course Name</b></Typography></Form.Label>
        </Grid>
        <Grid item xs={6}>
        <Form.Control value={training.courseName} onChange={(e)=>{setTraining({...training, courseName: e.target.value }); setTrainingTitle(e.target.value)}} type='text'/>
        </Grid>
        
        </Grid>

        <Grid  className='mt-4'  container>
            <Grid item xs={3}>
        <Form.Label><Typography variant='subtitle2'><b>Course Description</b></Typography></Form.Label>
        </Grid>
        <Grid item xs={8}>
        <CKEditor
        editor={ClassicEditor}
        data={editorData}
        onChange={(event, editor) =>{setEditorData(editor.getData())}}
        config={customConfiguration}
        onReady={(editor) => {
          // You can handle the editor instance here
          console.log('Editor is ready to use!', editor);
        }}
       
      />
        </Grid>
        
        </Grid>

        <Grid className='mt-4' container>
            <Grid sx={{alignSelf:'top'}} item xs={3}>
        <Form.Label><Typography variant='subtitle2'><b>Course Runtime</b></Typography></Form.Label>
        </Grid>
        <Grid item xs={6}>
        <Form.Control value={training.runtime} placeholder='Example: 47 minutes' onChange={(e)=>{setTraining({...training, runtime: e.target.value }); setTrainingRuntime(e.target.value)}} type='text'/>
        <Typography className='mt-3' variant='caption'><b>If you are not sure just estimate how much time it will take the learner to complete the course.</b></Typography>

        </Grid>
        
        </Grid>

  
        <Grid sx={{display: training.type === 'single-video' ? 'flex':'none'}} className='mt-4'  container>
            <Grid item xs={3}>
        <Form.Label><Typography variant='subtitle2'><b>Replace Video File</b></Typography></Form.Label>
    
        </Grid>
        
        <Grid item xs={8}>
          <UploadVideo
          videoFile={videoFile}
          CourseName={training.courseName + ' Video'}
          SetVideoFile={setVideoFile}
          
          />
       </Grid>
        
        </Grid>

        <Grid sx={{display: training.type === 'single-audio' ? 'flex':'none'}} className='mt-4'  container>
            <Grid item xs={3}>
        <Form.Label><Typography variant='subtitle2'><b>Course Audio File</b></Typography></Form.Label>
        </Grid>
        
        <Grid item xs={8}>
        <UploadAudio

          AudioFile={audioFile}
          Email={data.email}
          CourseName={training.id}
          setCoverImage={setCoverImage}
          SetAudioFile={setAudioFile}
        
          /> </Grid>
        
        </Grid>

        <Grid  className='mt-4'  container>
            <Grid item xs={3}>
        <Form.Label><Typography variant='subtitle2'><b>Course Cover Image</b></Typography></Form.Label>
        </Grid>
        
        <Grid item xs={8}>
          <UploadCoverImage
          CoverFile={coverFile}
          Email={data.email}
          CourseName={training.id}
          CoverImage={coverImage}
          setCoverImage={setCoverImage}
          SetCoverImage={setCoverImage}
          SetCoverFile={setCoverFile}
          SetImageLink={setCoverFile}
          SetFileSize={setFileSize}
          FileSize={fileSize}
          />
        </Grid>

        </Grid>

        <Grid  className='mt-4'  container>
            <Grid item xs={3}>
        <Form.Label><Typography variant='subtitle2'><b>Course Documents</b></Typography></Form.Label>
        </Grid>
        
        <Grid item xs={8}>
       <UploadDocuments
       Documents={documents}
       Email={data.email}
       SetCurrentDocument={setCurrentDocument}
       CurrentDocument={currentDocument}
       DeleteDocument={DeleteDocument}
       />
        </Grid>
        
        </Grid>

  
        
        <Grid  className='mt-5'  container>
            <Grid item xs={3}>

        </Grid>
        <Grid sx={{textAlign:'right'}}  item xs={8}>
        
        </Grid>
        <Grid item xs={4}>
        </Grid>
        
        </Grid>
    

        
       </Paper>
       </Grid>
       <Grid item xs={3}>
        <Box sx={{marginLeft:'10px'}}>
        <Box sx={{textAlign:'center', border:'solid grey 2px'}}>
        <img src={coverImage}/>
        
        </Box>

        <Typography className='mt-3'><b>Status: <span style={{color:courseActive? 'green': 'tomato'}}>{courseActive ? 'ACTIVE': 'INACTIVE'}</span></b></Typography>

        <Typography className='mt-3'><b>Date Added: <span >{formatDate(training.dateCreated)}</span></b></Typography>
        
        <Typography className='mt-3'><b>Date Updated: <span >{formatDate(training.dateUpdated ? training.dateUpdated : '')}</span></b></Typography>
        <Button
        onClick={()=>UpdateTraining(training.id, data.email, training.courseName, training)}
        sx={{width:'100%', fontWeight:'bold', marginTop:'10px'}} variant='contained'>Update</Button>

      <Button
     
        onClick={()=>{
          let conf = window.confirm('If you have not clicked UPDATE, any changes you have made on this page will not be saved. Are you sure you would like to do this?')
          if(conf){
          setShowQuiz(true)
        }}}
        sx={{width:'100%', fontWeight:'bold', marginTop:'10px'}} variant='contained'>EDIT QUIZ</Button>

<Button
     
     onClick={()=>{
      let conf = window.confirm('If you have not clicked UPDATE, any changes you have made on this page will not be saved. Are you sure you would like to do this?')
      if(conf){
        setShowModuleBuilder(true)
      }
   
    }}
     sx={{display:training.type === 'module' ? 'block':'none' ,width:'100%', fontWeight:'bold', marginTop:'10px'}} variant='contained'>EDIT MODULES</Button>
        <Box className='mt-3'>
          {/* <Typography><b>Change Status</b></Typography>
          <Form.Select>
            <option>Active</option>
            <option>Not Active</option>
          </Form.Select> */}
        </Box>
        </Box>
       </Grid>
        </Grid>
        <Box sx={{display:moduleBuilder?'block':'none'}}>
  <EditModuleBuilder
  Modules={modules}
  TrainingId={training.id}
  Navigate={()=> navigate('/training-builder')}
  UserId={data.id}
  CourseData={training.courseData}
  GoBack={GoBack}
  NextStep={NextStep}
  Return={()=> navigate('/training-builder')}
  />
  </Box>


        <Box sx={{display:showQuiz?'block':'none'}}>
  <EditQuizBuilder
  Update={()=>setOpen(true)}
  UpdateClose={()=>setOpen(false)}
  Questions={training.quiz}
  TrainingId={training.id}
  Navigate={()=> {setShowQuiz(!showQuiz);
  setUpdate(!update)
  }}
  UserId={data.id}
  /> 

  
  </Box>
  
  
    </Box>
    <UpdateProgressModal
    Open={open}
    
    />

<DeleteFileModal
    Open={openDeleteFile}
    
    />
  
  
      
       </div>
    
      </Box>
  
    </Box>
  );
}

EditTraining.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  windowsize: PropTypes.func,
};
import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export default function TypeToggle(props) {
  const [alignment, setAlignment] = React.useState(props.Type);

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
    props.HandleChange(newAlignment)
  };

  return (
    <ToggleButtonGroup
      value={alignment}
      exclusive
      color="primary"
      onChange={handleAlignment}
      aria-label="text alignment"
    >
   {/* <Tooltip title="This type of training allows you to "> */}
    <ToggleButton  value="module" aria-label="left aligned">
       <b>Module</b>
      </ToggleButton>
      {/* </Tooltip> */}
      <ToggleButton value="single-audio" aria-label="centered">
        <b>Single Audio</b>
      </ToggleButton>
      <ToggleButton value="single-video" aria-label="right aligned">
      <b>Single Video</b>
      </ToggleButton>
  
    </ToggleButtonGroup>
  );
}
import React from "react";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';




export const PosterAudioCard = (props)=>{

    return (
        <Card onClick={props.ClickCard} sx={{ width:'90%', height:'auto', margin:'10px'}}>
      
     
      
        <CardMedia
          component="img"
          height="auto"
          width='100%'
          image={props.Image}
          alt="green iguana"
        />
    

   
    </Card>
    )
}
import React, { useState, useEffect } from "react";

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import axios from "axios";
import Paper from '@mui/material/Paper';
import Form from 'react-bootstrap/Form';
import { API } from "../controllers/API";

import { users } from "../data/users";
export const LearnerTransferList = (props)=>{
  const data = JSON.parse(sessionStorage.getItem('profile-data'))
    const [leftQuery, setLeftQuery] = useState('')
    const [rightQuery, setRightQuery] = useState('')
    const[left, setLeft] = useState(props.learners)
    const[right, setRight] = useState([])
    // useEffect(()=>{
    //   axios.post(API+ 'get-data',{id:data.id}).then((response)=>{
    //     return response
    
    //       }).then((response)=>{
    //         setLeft(response.data[0].learners)
    //       })
    // },[])
    const leftSearchFilter = props.learners.filter(el => {
        return !right.find(element => {
           return element.name === el.name;
        });
      })    
      const rightSearchFilter = data.learners.filter(el => {
        return !left.find(element => {
           return element.name === el.name;
        });
      })
    const [leftFilter, setLeftFilter] = useState(null);
    useEffect(()=>{
      
      setLeft(leftSearchFilter.filter(i=>{return i.name.toLowerCase().includes(leftQuery.toLowerCase())}).sort((a, b) => a.name.localeCompare(b.name)))
      },[leftQuery])
      
      
      useEffect(()=>{
      
      setRight(rightSearchFilter.filter(i=>{return i.name.toLowerCase().includes(rightQuery.toLowerCase())}).sort((a, b) => a.name.localeCompare(b.name)))
      },[rightQuery])

    useEffect(()=>{
        if(leftFilter != null){
            console.log(leftFilter)
            setLeft(oldArray => [...oldArray, leftFilter]);
            window.reviewerList = JSON.stringify(right)
        }


    },[leftFilter])
    const customListLeft = (items) => (
        <Paper sx={{ width: '300px', height: 230, overflow: 'auto', paddingTop:0}}>
         <List style={{paddingTop:0}}>
         {/* <hr style={{marginBottom:5, marginTop:5}}></hr>
          
          <li style={{marginLeft:'30px', padding:'0px'}}><b>Managers</b></li>
          <hr style={{marginTop:5}}></hr> */}
            {items.map((i)=>{

      
                return ( <ListItem
                    key={i.id}
                    role="listitem"
                    button
                    onClick={()=>handleAddToReviewerList(i.id)}
                  >
                    <ListItemIcon>
                      {/* <Checkbox
                        checked={checked.indexOf(value) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      /> */}
                    </ListItemIcon>
                    <ListItemText primary={`${i.name}`} />
                  </ListItem>)
        
            })}
             {/* <hr style={{marginBottom:5}}></hr>
          
 <li style={{marginLeft:'30px', padding:'0px'}}><b>Employees</b></li>
 <hr style={{marginTop:5}}></hr> */}
           
            <ListItem/>
         </List>
          
        </Paper>
      );
   const handleAddToReviewerList = (a)=>{

   let filterLeft =  left.filter(i=>{return (i.id !== a)})
   setLeft(filterLeft)
console.log(filterLeft)
   let filterRight = left.filter(i=>{
    return(i.id === a)
   })


   right.push(filterRight[0])
console.log(right)
  window.learnerList = JSON.stringify(right)
   }

   const handleRemoveToReviewerList = (a)=>{
    let filterRight =  right.filter(i=>{return (i.id !== a)})
    setRight(filterRight)
    console.log(filterRight)
    let filterLeft = right.filter(i=>{
        return(i.id === a)
       })
       setLeftFilter(filterLeft[0])
       window.learnerList = JSON.stringify(filterRight)
       console.log(left)

   
    }

      const customListRight = (items) => (
        <Paper sx={{ width: '300px', height: 230, overflow: 'auto' }}>
         <List>
            {right.map((i)=>{
                
               
                    
                return ( 
                    
                   <ListItem
                    
                    key={i.id}
                    role="listitem"
                    button
                    onClick={()=>handleRemoveToReviewerList(i.id)}
                  >
                    <ListItemIcon>
                      {/* <Checkbox
                        checked={checked.indexOf(value) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      /> */}
                    </ListItemIcon>
                    <ListItemText primary={`${i.name}`} />
                  </ListItem>)


                
            })
        
        }
            <ListItem title="Mason"/>
         </List>
          
        </Paper>
      );

    return(
        <div>
       <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>
        <Form.Label><b>Learner List</b></Form.Label>
        <Form.Control
        placeholder='Search..'
        style={{marginBottom:'10px'}}
        type="text"
        value={leftQuery}
         onChange={(e)=>{
          setLeftQuery(e.target.value)
         
         }}
      />{customListLeft(left.sort((a, b) => a.name.localeCompare(b.name)))}</Grid>
      {/* <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            // onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid> */}
      <Grid item>
      <Form.Label><b>Learners</b></Form.Label>
        <Form.Control
        placeholder='Search..'
        style={{marginBottom:'10px'}}
        type="text"
        value={rightQuery}
        onChange={(e)=>{
          setRightQuery(e.target.value)
          
         }}
      />{customListRight(right.sort((a, b) => a.name.localeCompare(b.name)))}</Grid>
    </Grid>
      </div>
    
    )

}
import  React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import uuid from "react-uuid";
import logo from '../../assets/css/images/icon6.png'
import '../../assets/css/style.css'
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import stockImg from '../../assets/css/images/stock_custom_card_img.png'
import { useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Form from 'react-bootstrap/Form';
import TypeToggle from '../../components/training-builder-components/TypeToggle';
import axios from 'axios';
import { API, wsAPI } from '../../controllers/API';
import { useState } from 'react';
import { UploadCoverImage } from '../../components/training-builder-components/UploadCoverImage';
import { UploadDocuments } from '../../components/training-builder-components/UploadDocuments';
import { HandleAudioUpload, HandleCoverImageUpload, HandleUploadDocument } from '../../classes/TrainingBuilderFunctions/Upload';
import { UploadAudio } from '../../components/training-builder-components/UploadAudio';
import { QuizBuilder } from './QuizBuilder';
import { ModuleBuilder } from './ModuleBuilder';
import { formatDate } from '../../classes/global-functions';
import SetupModal from '../../components/training-builder-components/SetupModal';

const customConfiguration = {
  toolbar: {
    items: [
      'heading',
      '|',
      'bold',
      'italic',
      'link',
      'bulletedList',
      'numberedList',
      'blockQuote',
      'undo',
      'redo',
    ],
  },
};

const drawerWidth = 240;
const navItems = ['Back To Training Builder'];
const steps = [
  {
    label: 'Select campaign settings',
    description: `For each ad campaign that you create, you can control how much
              you're willing to spend on clicks and conversions, which networks
              and geographical locations you want your ads to show on, and more.`,
  },
  {
    label: 'Create an ad group',
    description:
      'An ad group contains one or more ads which target a shared set of keywords.',
  },
  {
    label: 'Create an ad',
    description: `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`,
  },
];

export const BuildTraining = (props)=>{
  const data = JSON.parse(sessionStorage.getItem('profile-data'))
  const trainingId = uuid();
  const { wind } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const [moduleBuilder, setShowModuleBuilder] = useState(false)
  const [showAudio, setShowAudio] = React.useState(false);
  const [showVideo, setShowVideo] = React.useState(false);
  const [socket, setSocket] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [type, setType] = useState('')
  const [completeUpload, setCompleteUpload] = useState([])
  const [quizSwitch, setQuizSwitch] = useState(true)
  const [showQuiz, setShowQuiz] = useState(false)
  const date = new Date()
  const [fileSize, setFileSize] = useState(0)
  const [editorData, setEditorData] = useState('');

  //Text State Variables
  const [trainingTitle, setTrainingTitle] = useState('');
  const [trainingRuntime, setTrainingRuntime] = useState('');
  const [trainingLink, setTrainingLink] = useState('NA');

  const [courseActive, setCourseActive] = useState(false);
  //Cover Image Variables
  const[ coverFile, setCoverFile] = useState()
  const[ coverImage, setCoverImage] = useState(stockImg)
  const[poster,setPoster] = useState([])

   //Document Upload Variables
   const[ currentDocument, setCurrentDocument] = useState()
   const[ documents, setDocuments] = useState([])
   const[ documentLinks, setDucumentLinks] = useState([])
   const[ docUploadPercent, setDocUploadPercent] = useState()
   const[docUploaded, setDocUploaded] = useState([])

   //Audio Variables
   const[audio,setAudio] = useState([])
   const[ audioFile, setAudioFile] = useState('')


   //Updating Modal
   const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
 
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };


  let trainingObject;

   const setUpdateArray = async (a)=>{
  let videoLink = a;
    console.log('update')
  
    console.log(completeUpload.length);
    console.log(documents.length + 2);
   
    completeUpload.push('i')

    let description = editorData
    if(completeUpload.length === documents.length + 2  || completeUpload.length === documents.length + 1 && type === 'module'){
      let courseData;
      
      if(type === 'single-video'){
       courseData = [{
        
        id:'moduleId-1',
        moduleTitle:trainingTitle,
        type: 'video',
        videoLink: videoLink,
        content:'NA'
    }]
      }


      if(type === 'single-audio'){
        courseData = [{
         
         id:'moduleId-1',
         moduleTitle:trainingTitle,
         videoLink: 'NA',
         type: 'audio',
         audioLink: audio[0],
         content:'NA'
     }]

     


       }
     
      trainingObject = {
        id:trainingId,
        active: courseActive,
        dateCreated: new Date(),
        courseName: trainingTitle,
        courseDescription: description,
        videoLink: videoLink,
        videoTrainingLink:videoLink,
        videoDlLink: '',
        audioLink: audio[0],
        poster: poster[0],
        runtime: trainingRuntime,
        type:type,
        courseData: courseData,
         documents:documentLinks,
         quiz:[],
      }

      if(type === 'module'){
        trainingObject.courseData = [{
          id:uuid(),
          moduleTitle:'',
          type: 'text',
          videoLink: 'NA',
          audioLink: 'NA',
          file:'',
          content:''
        }];
       }
  
      sessionStorage.setItem('trainingId', trainingId)
      sessionStorage.setItem('courseName', trainingTitle)
      console.log(trainingId)

      console.log('done');
      console.log(trainingObject)
      handleClose()
      let response = axios.post(API+ 'upload-custom-training', {id:data.id, training: trainingObject})
      if(response.data === 'error'){
        alert('Something went wrong. Please try again')
      } else {
        if(type == 'module'){
          setShowModuleBuilder(true)
        } else {
        if(quizSwitch){
       
           setShowQuiz(true)
        } else{
          navigate('/training-builder', { replace: true})
        }
        
      }}
      

    }
   
  }

 
  useEffect(() => {
    const newSocket = new WebSocket(wsAPI);
    setSocket(newSocket);

    return () => {
      if (newSocket && newSocket.readyState === WebSocket.OPEN) {
        newSocket.close();
      }
    };
  }, []);

  useEffect(() => {
    if (socket) {
      console.log('WebSocket connection established');

      socket.onmessage = (event) => {
        const progress = JSON.parse(event.data).percentage;
        console.log('WebSocket message received:', progress);
        setUploadProgress(progress);
      };
    }
  }, [socket]);


  useEffect(() => {
    if(uploadProgress === 100 || trainingLink != ''){
      let description = editorData
      console.log(description)
     

    }
  }, [trainingLink]);

  useEffect(()=>{
    console.log(props.Training)
  },[])


  
  const NextStep = ()=>{
    if(quizSwitch){
      setShowQuiz(true);
      setShowModuleBuilder(false)
    } else {
      navigate('/')
    }
  }


  const handleToggleValue = (a)=>{
  console.log(a)
    if(a === 'single-audio'){
        setShowAudio(true);
        setShowVideo(false)
        
    }

    if(a === 'single-video'){
        setShowAudio(false);
        setShowVideo(true)
    }

    if(a === 'module'){
        setShowAudio(false);
        setShowVideo(false)
    }
    setType(a)
  }
  
 const[ videoFile, setVideoFile] = useState('')
const[ pbActive, setPbActive] = useState(false)
  const handleFileUpload = (event) => {

    setPbActive(true)

    const formData = new FormData();
    formData.append('video', videoFile);
    formData.append('folder', data.email)
    formData.append('title', trainingTitle)
    axios.post(API+'upload-video',formData)
      .then((response) => {
        // Video uploaded successfully
        
        const videoUri = response.data.link;
        console.log(videoUri);
        
        setTrainingLink(videoUri)
        console.log(trainingLink)
        setPbActive(false)
        setUpdateArray(response.data.link)
      })
      .catch((error) => {
        // Error handling
        setPbActive(false)

        console.error('Failed to upload video:', error);
      });
  };
  function isFile(variable) {
    return variable instanceof File;
  }

  const DeleteDocument = (id, file) => {
    console.log(file)
    if(isFile(file)){
         let filter = documents.filter(i=>{return i.name !== file.name})
         setDocuments(filter);
       
   
      }
    }
  



  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <img src={logo} width="175px" height="auto"/>
      <Divider />
      <List>
      {/* <Link to="/"> */}
          <ListItem disablePadding>
            <ListItemButton onClick={()=>navigate('/', { replace: true, state:'TM' })}  sx={{ textAlign: 'center' }}>
              <ListItemText primary={"BACK TO DASHBOARD"} />
            </ListItemButton>
          </ListItem>
      {/* </Link> */}
      </List>
    </Box>
  );

  const container = wind !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex', justifyContent:'center', backgroundColor:'#ECEEF1' }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{  display: { xs: 'none', sm: 'block', padding:10 } }}
          >
           <img src={logo} width="75px" />
          </Typography>
          <Typography color={'white'}><b>TRAINING BUILDER</b></Typography>

          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              <Button onClick={()=>navigate('/training-builder', { replace: true})} key={item} sx={{ color: '#fff', position:'absolute', right:15, top:30}}>
               <b>{item}</b>
              </Button> 
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
        <div style={{marginTop:10}}>
          <Box sx={{width:'1000px'}} className="mt-5">
          <Grid sx={{display: showQuiz ?'none': moduleBuilder ? 'none':'flex'}} container >
            <Grid item xs={9}>
       <Paper className='p-3'>
      
        <Grid container>
            <Grid sx={{alignSelf:'center'}} item xs={3}>
        <Form.Label><Typography variant='subtitle2'><b>Course Name</b></Typography></Form.Label>
        </Grid>
        <Grid item xs={6}>
        <Form.Control onChange={(e)=>setTrainingTitle(e.target.value)} type='text'/>
        </Grid>
        
        </Grid>

        <Grid  className='mt-4'  container>
            <Grid item xs={3}>
        <Form.Label><Typography variant='subtitle2'><b>Course Description</b></Typography></Form.Label>
        </Grid>
        <Grid item xs={8}>

        <CKEditor
        editor={ClassicEditor}
        data={editorData}
        onChange={(event, editor) =>{setEditorData(editor.getData())}}
        config={customConfiguration}
        onReady={(editor) => {
          // You can handle the editor instance here
          console.log('Editor is ready to use!', editor);
        }}
      />
  
        </Grid>
        
        </Grid>

        <Grid className='mt-4' container>
            <Grid sx={{alignSelf:'top'}} item xs={3}>
        <Form.Label><Typography variant='subtitle2'><b>Course Runtime</b></Typography></Form.Label>
        </Grid>
        <Grid item xs={6}>
        <Form.Control placeholder='Example: 47 minutes' onChange={(e)=>setTrainingRuntime(e.target.value)} type='text'/>
        <Typography className='mt-3' variant='caption'><b>If you are not sure just estimate how much time it will take the learner to complete the course.</b></Typography>

        </Grid>
        
        </Grid>
        

        <Grid  className='mt-4'  container>
            <Grid item xs={3}>
        <Form.Label><Typography variant='subtitle2'><b>Course Type</b></Typography></Form.Label>
        </Grid>
        <Grid item xs={8}>
       <TypeToggle
       HandleChange={handleToggleValue}
       />
        </Grid>
        
        </Grid>
        <Grid sx={{display: showVideo ? 'flex':'none'}} className='mt-4'  container>
            <Grid item xs={3}>
        <Form.Label><Typography variant='subtitle2'><b>Course Video File</b></Typography></Form.Label>
    
        </Grid>
        
        <Grid item xs={8}>
       <Form.Control accept='video/*' onChange={(e)=>{
         if(e.target.files[0].size >  500000000){
          window.alert('File size is too big. Size should be under 500mb.')
          e.target.value = '';
        } else {
        setVideoFile(e.target.files[0]); setFileSize(e.target.files[0].size); console.log(fileSize)
        }
        }} type='file'/>
      
       <Box className="mt-3" sx={{display:'flex'}}>
        
       </Box>
       <Typography variant='caption'><b>Video size must be less than 500mb</b></Typography>
        </Grid>
        
        </Grid>

        <Grid sx={{display: showAudio ? 'flex':'none'}} className='mt-4'  container>
            <Grid item xs={3}>
        <Form.Label><Typography variant='subtitle2'><b>Course Audio File</b></Typography></Form.Label>
        </Grid>
        
        <Grid item xs={8}>
        <UploadAudio
          AudioFile={audioFile}
          Email={data.email}
          CourseName={trainingId}
          setCoverImage={setCoverImage}
          SetAudioFile={setAudioFile}
          SetFileSize={setFileSize}
          FileSize={fileSize}
          /> 
          </Grid>
        
        </Grid>

        <Grid  className='mt-4'  container>
            <Grid item xs={3}>
        <Form.Label><Typography variant='subtitle2'><b>Course Cover Image</b></Typography></Form.Label>
        </Grid>
        
        <Grid item xs={8}>
          <UploadCoverImage
          CoverFile={coverFile}
          Email={data.email}
          CourseName={trainingId}
          CoverImage={coverImage}
          setCoverImage={setCoverImage}
          SetCoverImage={setCoverImage}
          SetCoverFile={setCoverFile}
          SetImageLink={setCoverFile}
          SetFileSize={setFileSize}
          FileSize={fileSize}
          />
        </Grid>

        </Grid>

        <Grid  className='mt-4'  container>
            <Grid item xs={3}>
        <Form.Label><Typography variant='subtitle2'><b>Course Documents</b></Typography></Form.Label>
        </Grid>
        
        <Grid item xs={8}>
       <UploadDocuments
       Documents={documents}
       Email={data.email}
       SetCurrentDocument={setCurrentDocument}
       CurrentDocument={currentDocument}
       DeleteDocument={DeleteDocument}
       />
        </Grid>
        
        </Grid>
{/* 
        <Grid  className='mt-4'  container>
            <Grid item xs={3}>
        <Form.Label><Typography variant='subtitle2'><b>Would you like to build a quiz?</b></Typography></Form.Label>
        <Typography variant='caption'><b>Note: You can add a quiz later if you would like to.</b></Typography>
        </Grid>
        
        <Grid item xs={8}>
       <Switch onChange={()=>setQuizSwitch(!quizSwitch)} checked={quizSwitch}/>
        </Grid>
        
        </Grid> */}
        
        
        <Grid  className='mt-5'  container>
            <Grid item xs={3}>

        </Grid>
        <Grid sx={{textAlign:'right'}}  item xs={8}>
        <Button onClick={()=>{
          if(trainingTitle === '' || editorData === ''){
            window.alert('Please make sure you have entered a name and description for the course.')
          } else {
            
           if(type === '' || type === null){

        

            alert('Please select the course type to continue.')
            } else{

              if(audioFile === ''  && videoFile === '' && type != 'module') {
                    alert(`Please add the ${type === 'single-audio' ? 'audio' : 'video'} file to upload with the training.`)
              } else {
                let conf; 

                if(quizSwitch === false){
                  
             conf = window.confirm('If you do not add a quiz to the course it will not be active to use in training. You can go ahead and upload and add a quiz later if you would like to. Are you sure you would like to proceed?');
           
            } else { conf = true}
             if(conf){
              
          handleOpen()

          HandleCoverImageUpload(coverFile, data.email, trainingId, trainingTitle, setUpdateArray, poster)
          
          documents.forEach((i,index)=>{
            HandleUploadDocument(i, data.email, trainingId, documentLinks, documents, setDocUploadPercent, setUpdateArray, index)
        
          

          })
        if(type === 'single-video'){
          handleFileUpload()
        } 

        if(type === 'single-audio'){
          HandleAudioUpload(audioFile, data.email, trainingId, trainingTitle, setUpdateArray, audio)
        } 

      }
        
      } 

     
       

      }

    }


          
        }} variant='contained'><b>{type=== 'module'? 'Start Building Modules': quizSwitch ? 'Start Building Quiz': 'Upload Training'}</b></Button>
        </Grid>
        <Grid item xs={4}>
        </Grid>
        
        </Grid>
    

        
       </Paper>
       </Grid>
       <Grid item xs={3}>
        <Box sx={{marginLeft:'10px'}}>
        <Box sx={{textAlign:'center', border:'solid grey 2px'}}>
        <img src={coverImage}/>
        
        </Box>

        <Typography className='mt-3'><b>Status: <span style={{color:courseActive? 'green': 'tomato'}}>{courseActive ? 'ACTIVE': 'INACTIVE'}</span></b></Typography>

        <Typography className='mt-3'><b>Date Added: <span >{formatDate(date)}</span></b></Typography>
        

        <Box className='mt-3'>
          {/* <Typography><b>Change Status</b></Typography>
          <Form.Select>
            <option>Active</option>
            <option>Not Active</option>
          </Form.Select> */}
        </Box>
        </Box>
       </Grid>
        </Grid>
        <Box sx={{display:moduleBuilder?'block':'none'}}>
  <ModuleBuilder
  TrainingId={trainingId}
  Training={trainingObject}
  Navigate={()=> navigate('/training-builder')}
  UserId={data.id}
  NextStep={NextStep}
  Return={()=> navigate('/training-builder')}
  />
  </Box>


        <Box sx={{display:showQuiz?'block':'none'}}>
  <QuizBuilder
  Training={trainingObject}
  TrainingId={trainingId}
  Navigate={()=> navigate('/training-builder')}
  UserId={data.id}
  /> 

  
  </Box>
  
  
    </Box>
    {/* <BuildModal
    OpenModal={handleOpen}
    Open={open}
    CloseModal={handleClose}
    CoverImageProgress={100}
    DocumentProgress={docUploadPercent}
    VideoProgress={uploadProgress}
    /> */}
      <SetupModal

      Open={open}
      />
       </div>
    
      </Box>
  
    </Box>
  );
}

BuildTraining.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
 wind: PropTypes.func,
};
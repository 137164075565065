import  React, {useState} from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from '../assets/css/images/icon6.png'
import PropTypes from 'prop-types';
import '../assets/css/login.css'
import { API } from '../controllers/API';
import loginImage from '../assets/css/images/login-image.png'
import { Spinner } from 'react-bootstrap';
async function loginUser(credentials) {


  console.log(credentials)
   return fetch(`${API}verify?${credentials}`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
   
  })
    .then(data => data.json())
 }

function Copyright(props) {
    return (
      <Typography variant="body2" align="center" {...props}>
        <Typography  color="white" align="center" {...props}>
        {'Copyright © '}
        <Link sx={{color:'white'}} color="inherit" href="https://andereprepsu.com/">
          Employee Training Tool-Kit
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
        </Typography>
      </Typography>
    );
  }

  const theme = createTheme();


  export const  Login = ({setToken})=> {

    const[isLoggingIn,setIsLoggingIn] = useState(false)
  const[username,setUser] = useState('')
  const[password,setPass] = useState('')
  const[activity,setActivity] = useState(false)
  const [open, setOpen] = useState(false);


    const handleSubmit = async e => {
      setActivity(true)
      setIsLoggingIn(true)
      e.preventDefault();
      const token = await loginUser(`user=${username}&pass=${password}`
       );
       console.log(token)
       
      //  if(token.action === 'registered'){
      //   loginAgain(username, password)
        
      //  } else{
        if(token.token == 'diy'){
          setToken(token)
          setActivity(false)
          setIsLoggingIn(false)
        } 
    
        if(token.token == 'test123'){
          setToken(token)
          setActivity(false)
          setIsLoggingIn(false)
          console.log(token)
        } 
        if(token.token == 'inactive'){
          alert('There is no active memebership with this account.')
          setActivity(false)
          setIsLoggingIn(false)
        }
        if(token.token == false){
          alert('There is no active memebership with this account. Please check your credentials and try again.')
          setActivity(false)
          setIsLoggingIn(false)
        }
  
      //  }
     
    }
  
    return (
        // <div className="login-page">
      <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${loginImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >  </Grid>
        <Grid sx={{backgroundColor:'#3FA9E6'}} item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div style={{paddingTop:'40px', textAlign:'center'}}>
          <img src={logo} width="200px"/>
          </div>
          <Box
            sx={{
              my: 4,
              mx: 6,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
         
        
            
        
            <Paper component="form" noValidate  onSubmit={handleSubmit} sx={{ mt: 1, padding:'20px' }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                onChange={(e)=>{setUser(e.target.value);console.log(username);}}
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                onChange={(e)=>{setPass(e.target.value);console.log(password);}}
                id="password"
                autoComplete="current-password"
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In <Spinner style={{display:activity ? 'inline': 'none', marginLeft: '5px', width:'15px', height:'15px'}} color='white'/>
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="https://employeetrainingtoolkit.com/login/?action=forgot_password" target='_blank' variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link target='_blank' href="https://employeetrainingtoolkit.com/#pricing" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              {/* <Copyright sx={{ mt: 5 }} /> */}
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>

     
      // </div>
    );
  }

  Login.propTypes = {
    setToken: PropTypes.func.isRequired
  }

  const styles = theme => ({
    textField: {
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',            
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: 500
    },
    input: {
        color: 'white'
    }
});
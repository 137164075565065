import React, { useState } from "react";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';



export const PosterPlayerCard = (props)=>{
const [hoverPlay, setHoverPlay] = useState('none')
    return (
        <Card onClick={props.ClickCard} sx={{ width:'90%', height:'auto', margin:'10px'}}>
      
      <CardActionArea 
      onClick={props.Click}
      onMouseEnter={()=>{
        setHoverPlay('block')
      }}
      onMouseLeave={()=>{
        setHoverPlay('none')
      }}
      >
       <div className="poster-card-overlay" style={{display:hoverPlay, position:'absolute', top:0, bottom:0, left:0, right:0, backgroundColor:'#19191985'}}>
       <div style={{textAlign:'center', marginTop:'20%'}}>
       <PlayCircleIcon  sx={{fontSize:'60px', color:'white'}}/>
       </div>
       </div>
        <CardMedia
          component="img"
          height="auto"
          width='100%'
          image={props.Image}
          alt="green iguana"
        />
    
      </CardActionArea>
   
    </Card>
    )
}
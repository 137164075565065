import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Icon from "@mui/material/Icon";
import Box from '@mui/material/Box';import useWindowSize from 'use-window-size-v2';
import { API } from "../../../controllers/API";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import '../../../assets/css/style.css';
import { formatDate, generateDocument } from "../../../classes/global-functions";
import { CircularProgress } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";



const AnswerCard = (props)=>{
    return (
      <div style={{display:props.ShowAnswerCard}}>
        <Paper sx={{padding:'20px'}}>
            <Box>
              <Typography variant='button' sx={{textAlign:'start', fontSize:'16px'}}><b>Quiz Question {props.Index} of {props.Length}</b></Typography>
              </Box>
              <hr></hr>
      <div style={{textAlign:'center'}}>
    
              <br></br>
        {/* <Paper sx={{   textAlign:'center', padding:'20px'}}> */}
        <Box>
        <Icon component={props.IsCorrect ? CheckCircleIcon : CancelIcon} sx={{ fontSize: 75, color:props.IsCorrect ?'green':'tomato'}}></Icon>
        <Typography sx={{fontSize:'50px'}}></Typography>
        <Typography sx={{fontSize:'large'}} variant='button'><b>{props.IsCorrect ? <span>Correct! </span>: 'Incorrect!'}</b></Typography>
     <br></br>
        <Typography sx={{fontSize:'large'}}><b>Your Answer:</b></Typography>
        <Typography sx={{fontSize:'large'}}>{props.Answer}</Typography>
        <br></br>
        <Typography sx={{fontSize:'large'}}><b>Correct Answer:</b> </Typography>
        <Typography sx={{fontSize:'large'}}>{props.CorrectAnswer}</Typography>
        <br></br>
        <Typography sx={{fontSize:'16px', display:props.Response != '' ? 'block': 'none'}} variant='subtitle2'>{props.Response}</Typography>
        <br></br>
      <Button variant="contained" onClick={props.OnClickNext}>{props.NextButtonTitle}</Button>
      {/* </Paper> */}
      </Box>
      </div>
      </Paper>
      </div>
    )
  }

  const QuestionAnswer = ({ question, options, answer, handleAnswer, showQuestion, index , length, QuizButton, Answer, CorrectAnswer, showQuestionAnswer,OnClickNext, response, NextButtonTitle, GoBack}) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [currentAnswer, setCurrentAnswer] = useState('')
    const [actualAnswer, setActualAnswer] = useState('')
    const handleOptionSelect = (e) => {
     console.log(e)
      setSelectedOption(e);
      setCurrentAnswer(e);
     
    };

    
  
  
  
    return (
      
      <div style={{display:showQuestionAnswer, textAlign:'start'}}>
       
         <Paper sx={{padding:'20px'}}>
          <div>
      <Typography variant='button' sx={{textAlign:'start', fontSize:'16px'}}><b>Quiz Question {index} of {length}</b></Typography>
      <Typography sx={{float:'right', color: Answer === question+CorrectAnswer ? 'green' :'tomato'}} variant="button"><b>{Answer === question+CorrectAnswer ? 'CORRECT!' :'INCORRECT'}</b></Typography>
      </div>
        <p><b>{question}</b></p>
        <ToggleButtonGroup

      orientation="vertical"
      // value={selectedOption}
      // exclusive
      sx={{width:'100%'}}
      // onChange={handleOptionSelect} 
      // aria-label="Platform"
    >
      {options.map((i,index)=>{
        return(
          <ToggleButton key={index} sx={{color:question+answer === question+i?'white': Answer === question+i? 'white':'grey' , backgroundColor:answer === i?'#4DC569': Answer === question+i? 'tomato':'white'}} className='toggle'   value={i}><b>{i}</b></ToggleButton>

        )
      })}
  </ToggleButtonGroup>
                    <Typography variant="button"><b>Response</b></Typography>
               <Typography sx={{fontSize:'16px', display:response != '' ? 'block': 'none'}} variant='subtitle2'>{response}</Typography>

               <div className="pt-4" style={{textAlign:'right'}}>
    
                <Button sx={{marginTop:'5px'}} variant='contained' onClick={OnClickNext}>{NextButtonTitle}</Button>

                </div>
        {QuizButton}
        </Paper>
      </div>
     
    );
  };
const Question = ({ question, options, answer, handleAnswer, showQuestion, index , length, QuizButton}) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [currentAnswer, setCurrentAnswer] = useState('')
  

    const [alignment, setAlignment] = React.useState('web');

    const handleChange = (event, newAlignment) => {
      setAlignment(newAlignment);
      setSelectedOption(newAlignment);
      setCurrentAnswer(newAlignment);
    };


    const handleOptionSelect = (e) => {

     console.log(e.target.value)
      setSelectedOption(e.target.value);
      setCurrentAnswer(e.target.value);
     
    };

  
    return (
      
      <div style={{display:showQuestion, textAlign:'start'}}>
         <Paper sx={{padding:'20px'}}>
      <Typography variant='button' sx={{textAlign:'start', fontSize:'16px'}}><b>Quiz Question {index} of {length}</b></Typography>
  
        <p><b>{question}</b></p>
        {/* <ButtonGroup vertical>
        {options.map((option, index) => (
          <div style={{marginTop:'10px'}} key={option}>
       <input
       type='checkbox' value={option} 
       id={`radio-${index}`}
       checked={selectedOption === option}
       onChange={(e) => handleOptionSelect(e.currentTarget.value)}
       />
       <label for={`radio-${index}`}>{option}</label>
        <ToggleButton
            key={index}
            style={{width:'100%'}}
            id={`radio-${index}`}
            type="radio"
            variant="secondary"
            name="radio"
            value={option}
            checked={selectedOption === option}
            onChange={(e) => handleOptionSelect(e.currentTarget.value)}
          >
            {option}
          </ToggleButton>

        </div>

        ))}
      </ButtonGroup> */}
<ToggleButtonGroup
     color="primary"
     value={alignment}
      exclusive={true}
      orientation="vertical"
      sx={{width:'100%'}}
      onChange={handleChange}
      aria-label="Platform"
    >
      {options.map((i,index)=>{
        return(
          <ToggleButton key={index} value={question+i}><b>{i}</b></ToggleButton>

        )
      })}
      
    </ToggleButtonGroup>

               <div className="pt-4" style={{textAlign:'right'}}>
                <Button sx={{marginTop:'5px'}} variant='contained' onClick={()=>handleAnswer(currentAnswer)}>Check Answer</Button>
                </div>
        {QuizButton}
        </Paper>
      </div>
     
    );
  };

export const Quiz = (props)=>{
  const[training, setTraining] = useState(props.Training)
  const[learner, setLearner] = useState(props.Learner)
    const [quiz, showQuiz] = useState(false)
    const [startQuiz, setStartQuiz] = useState(false)
    const [showVideo, setShowVideo] = useState(true)
    const [showCertificateBtn, setShowCertificateBtn] = useState(false)
    const [remainingTime, setRemainingTime] = React.useState(null);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [ loading, setLoading] = useState(true)
    const [score, setScore] = useState(0);
    const [questions,setQuestions] = useState(props.Quiz === "NA"?[{
      question: 'null',
      options: ['null1', 'null2', 'null3', 'null4'],
      answer: 'null1',
    }]:props.Quiz)
    const [showAnswerCard, setShowAnswerCard] = useState(false)
    const [showQuestionCard, setShowQuestionCard] = useState(true)
  const [showResultCard, setShowResultCard] = useState(false)
  const [quizComplete, setQuizComplete] = useState(false)
  const [quizResults, setQuizResults] = useState(false)
  const [guess, setGuess] = useState('');
  let [searchParams, setSearchParams] = useSearchParams();
  const [theAnswer, setTheAnswer] = useState('');
  const [nextButtonTitle, setNextButtonTitle] = useState('Next Question')
  const [resultLine, setResultLine] = useState('')
  let userId = searchParams.get('a');
    const { height, width } = useWindowSize();

    useEffect(()=>{
      console.log(learner.status)
     if(learner.status === 'complete'){
     setResultLine(`Score: ${learner.quizScore}%`)
      setShowCertificateBtn(true)
      setShowAnswerCard(false);
          setShowQuestionCard(false);
          setShowResultCard(true);
          setLoading(false)
     } else{
      console.log('show')
      setShowQuestionCard(true);
      setLoading(false)
     }
    },[])

    //QUIZ LOGIC

  const handleAnswer = (guess) => {
    console.log(guess)
    setShowAnswerCard(true)
    setShowQuestionCard(false);
    setGuess(guess)
    setTheAnswer(questions[currentQuestion].answer)


    if(guess === questions[currentQuestion].question+questions[currentQuestion].answer){
     
      setScore(Number(score) + 1)
      console.log(score)
    }

    


    if (currentQuestion < questions.length - 1) {
      
    
    } else {
     
      setNextButtonTitle('Show Result')
      setQuizComplete(false)
      
      
    }
  };
if(loading){
  <Box sx={{textAlign:'center', marginTop:0}}>
    <CircularProgress/>
    <Typography><b>Loading</b></Typography>
  </Box>
} else {
  
//END QUIZ LOGIC
    return (
        <Box sx={{display:'block', }}>

    

    <Box >
    <Grid container>
    <Grid className="mb-3" item xs={12}>
        <Typography sx={{fontSize:'18px'}} variant="button">
         <b>  {props.CourseTitle}</b> 
        </Typography>
        <Button sx={{display: props.Type === 'scorm'? 'block':'none'}} onClick={props.GoBack}><ArrowBackIos/> Back to Course</Button>
        </Grid>

        <Grid  item xs={ 12}>
        {/* <Paper sx={{width:'100%', minHeight:'385px',padding:2}}> */}
  
      <Question
        question={questions[currentQuestion].question}
        options={questions[currentQuestion].options}
        answer={questions[currentQuestion].answer}
        handleAnswer={handleAnswer}
        showQuestion={showQuestionCard ?'block':'none'}
        length={questions.length}
        index={currentQuestion + 1}
      />

<QuestionAnswer
        question={questions[currentQuestion].question}
        options={questions[currentQuestion].options}
        answer={questions[currentQuestion].answer}
        Answer={guess}
        response={questions[currentQuestion].response}
        CorrectAnswer={theAnswer}
        handleAnswer={handleAnswer}
        NextButtonTitle={nextButtonTitle}
        showQuestionAnswer={showAnswerCard?'block':'none'}
        length={questions.length}
        index={currentQuestion + 1}
        OnClickNext={async()=>{
          console.log(score)
        
               if(nextButtonTitle === 'Next Question'){
             
                 setCurrentQuestion(currentQuestion); 
                 setShowAnswerCard(false);
                 setShowQuestionCard(true);
                  setCurrentQuestion(currentQuestion + 1)
               } else {
                
                 let percentage = Math.round(score / questions.length * 100)
                 console.log(percentage)
                 if(percentage > 69){
                 setResultLine(`You scored ${percentage}%`);
             
           
                  
       
                   let resp = await axios.post(API + 'complete-training', {userId:userId, trainingId:training.id, learnerId:learner.id, learnerDetails: learner, trainingName: training.course.courseName, quizScore: percentage})
                  if(resp.data === 'error'){
                   
                  } else {
                   setShowCertificateBtn(true)
                   alert('Congratulations you have completed the training! Your results have been sent to the person who setup the training. Click below to download your certificate.')
                  }
                 } else {
           
                 }
                 setShowAnswerCard(false);
                 setShowQuestionCard(false);
                 setShowResultCard(true)  
              
                
               }
       
              
             }}
      />
    
    <div style={{display:showAnswerCard === false &&showResultCard? 'flex':'none', justifyContent:'center'}}>
      <Paper sx={{display:'block', maxWidth:'500px',  padding:'40px'}}>
        <div>
        <Typography variant='button'><b>{showCertificateBtn? 'Congratulations!':'You did not pass. Try Again'}</b></Typography>
      <Typography variant='subtitle2' sx={{fontSize:'16px', marginTop:1}}>{resultLine}</Typography>
      </div>
      <Box>
        
        <Typography sx={{display: showCertificateBtn ? 'block':'none'}} variant='subtitle2'><b>You have completed and passed the training! Click the button below to download your certificate. The person who setup the training has been notified that the training has been completed. You can reach out to that person if you have any questions. You may now close this window.</b></Typography>
      <Stack sx={{textAlign:'center', marginTop:2}}>
      <Button
      onClick={()=>{
        let obj = {
            student_name: learner.name,
            course_name: props.CourseTitle,
            date: formatDate(new Date),
            instructor: props.Issuer,
            presenter_title: props.IssuerTitle
        }
        
        generateDocument(obj, learner.name)}}
      sx={{display: showCertificateBtn ? 'block':'none'}} variant='contained'>Download Certificate</Button>
      <Button sx={{display: showCertificateBtn ? 'none':'block'}}
       onClick={()=>{
        setShowAnswerCard(false);
          setShowQuestionCard(true);
          setShowResultCard(false);
          showQuiz(true)
          setCurrentQuestion(0)
          setScore(0)
          setNextButtonTitle('Next Question')
       }}
       variant='contained'>Retake Quiz</Button>
      <Button onClick={()=>{
         setShowAnswerCard(false);
         setShowQuestionCard(false);
         setShowResultCard(true);
         showQuiz(false)
         props.ShowQuiz()
         setCurrentQuestion(0)
        setShowVideo(true)
        setScore(0)
        setNextButtonTitle('Next Question')
      }} sx={{display: showCertificateBtn ? 'none':'block', marginTop:2}} variant='contained'>Back to Training</Button>
      </Stack>
      </Box>
      </Paper>
    </div>

        {/* </Paper> */}
        </Grid>
</Grid>
    </Box>


   
        </Box>
    )
}

}
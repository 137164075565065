import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';


export default function CustomTrainingCard(props) {
  return (
    <Card sx={{ minWidth: 265,width:265, marginTop:'16px !important' }}>
      <CardMedia
        component="img"
        alt="course"
        sx={{height:'150px'}}
        image={props.CourseImage}
      />
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {props.CourseName}
        </Typography>
      
        <Typography variant="body2" color="text.secondary">
          <b>{'Course Status:'} <span style={{color:props.TrainingStatus ? 'green': 'tomato'}}>{props.TrainingStatus ? 'ACTIVE': 'INACTIVE'}</span></b>
        </Typography>

        <Typography sx={{marginTop:1}} variant="body2" color="text.secondary">
        <b>{'Course Type:'} <span >{props.Type}</span></b>        </Typography>
      </CardContent>
      <CardActions>
        <Button onClick={props.ClickEdit} size="small">Edit</Button>
        <Link style={{display: props.TrainingStatus ? 'block':'none'}}  target="_blank" to='/demo' ><Button onClick={()=>{localStorage.setItem('sample-training', JSON.stringify(props.Course)); localStorage.setItem('seek-option', JSON.stringify('true'))}} >Demo</Button></Link>
        <Button onClick={props.OnClickDelete} sx={{float:'inline-end'}}><DeleteIcon htmlColor='tomato'/></Button>
      </CardActions>
    </Card>
  );
}
import React from "react";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const Text = (props)=>{
    return (
        <Box sx={{display:'block'}}>

    

    <Box >
    <Grid container>
    <Grid className="mb-3" item xs={12}>
     
        </Grid>

        <Grid component={Paper} item xs={ 12}>
        <Paper sx={{width:'100%',overflow:'auto', height:'510px',padding:2}}>
    <Typography variant="button"><b>{props.ModuleTitle}</b></Typography>
        <hr/>
        <div dangerouslySetInnerHTML={{__html: props.ModuleDescription}}></div>
    </Paper>
        </Grid>
</Grid>
    </Box>


   
        </Box>
    )
}
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import {saveAs} from "file-saver";
import doc from '../assets/certificateFinal.docx'
export function formatDate(inputDate) {
    var date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
        // Months use 0 index.
        return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
    }
}

export function formatBcDate(inputDate) {
    var date = new Date(inputDate);
    let formattedDate = date.setDate(date.getDate()+1);
    let finalDate = new Date(formattedDate);
    if (!isNaN(finalDate.getTime())) {
        // Months use 0 index.
        return finalDate.getMonth() + 1 + '/' + finalDate.getDate() + '/' + finalDate.getFullYear();
    }
}

export const titleCase = (string)=>{
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
  }

export  function get_url_extension( url ) {
    return url.split(/[#?]/)[0].split('.').pop().trim();
}


export function getFirstNumberFromVimeoLink(url) {
    const matches = url.match(/\d+/g);
    
    if (matches && matches.length > 0) {
      return parseInt(matches[0], 10);
    } else {
      return null; // Return null if no number found in the link
    }
  }

  function loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
}


  export const generateDocument = (obj, name) => {
    loadFile(
        doc,
        function (error, content) {
            if (error) {
                throw error;
            }
            const zip = new PizZip(content);
            const doc = new Docxtemplater(zip, {
                paragraphLoop: true,
                linebreaks: true,
            });

            // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
            doc.render(obj);
            const blob = doc.getZip().generate({
                type: "blob",
                mimeType:
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            }); //Output the document using Data-URI
            saveAs(blob, `${name}'s Certificate`);
        }
    );
};
import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Slider,
  Typography,
  IconButton,
  Grid,
  Paper,
  Box,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { Html } from "@mui/icons-material";

export const Audio = ({ title, imageUrl, audioUrl, description, moduleTitle }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef(null);

  useEffect(() => {
    audioRef.current?.addEventListener("timeupdate", handleTimeUpdate);
    return () => {
      audioRef.current?.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, []);

  const handleTogglePlay = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const audio = audioRef.current;
    setCurrentTime(audio.currentTime);
  };

  const handleSeekChange = (e, value) => {
    const audio = audioRef.current;
    audio.currentTime = value;
    setCurrentTime(value);
  };

  return (
    <Grid
    sx={{
      marginTop: 10,
      display: "flex",
      width: "100%",
      // paddingTop: 5,
      margin: 0,
    }}
    container
    spacing={2}
  >
    <Grid item xs={12} sm={8}>
      <Card sx={{ padding: 2, width: "100%", textAlign:'center' }}>

          <CardMedia component="img" image={imageUrl} sx={{height:'320px', width: '100%', }} />
          
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {title}
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2" color="textSecondary">
              {isNaN(currentTime) ? '0:00' : formatTime(currentTime)}
              </Typography>
              <Slider
               sx={{marginLeft:1.5, marginRight:1.5}}
                aria-label="time-slider"
                value={currentTime}
                onChange={handleSeekChange}
                max={audioRef.current && audioRef.current.duration}
              />
              <Typography variant="body2" color="textSecondary">
              {isNaN(audioRef.current ? audioRef.current.duration : 0) ? '0:00' : formatTime(audioRef.current ? audioRef.current.duration : 0)}
              </Typography>
            </div>
            <audio ref={audioRef} src={audioUrl} />
            <IconButton onClick={handleTogglePlay}>
              {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4} sx={{ display: "flex", width: "100%" }}>
     <Paper sx={{ padding: 2, height: 500, width: "100%", display: "flex", flexDirection: "column" }}>
  <Typography variant="button" sx={{ marginBottom: 0 }}>
    <b>{moduleTitle}</b>
  </Typography>
  <hr />
  <div
    dangerouslySetInnerHTML={{ __html: description }}
    style={{ height: "100%", overflow: "auto" }}
  ></div>
</Paper>
    </Grid>
    </Grid>
  );
};

const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};

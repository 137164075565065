import React,{useState, useRef, useEffect} from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Table from "react-bootstrap/Table";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API } from "../controllers/API";
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import InfoIcon from '@mui/icons-material/Info';
import { formatDate } from "../classes/global-functions";
import { Box, CircularProgress } from "@mui/material";
import { Grid, Container } from '@mui/material';
import { Chart } from "react-google-charts";
import { Metrics } from "../components/Metrics";



export const Dashboard = (props) => {
    const data = JSON.parse(sessionStorage.getItem('profile-data'))
  const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [activeEventKey, setActiveEventKey] = useState("");
    const accordElem = useRef(null);
    const handleClose = () => {setShow(false); setCourse('')}
    const handleShow = () => navigate('/training-setup', { replace: true });;
    const [learnerList, setLearnerList] = useState([[{"name":"null","email":"null"}]])
   const [course, setCourse] = useState('')
   const [mainData, setMainData] = useState([])
   const [archivedTrainings, setArchivedTrainings] = useState([])
   const [showArchivedTrainings, setShowArchivedTrainings] = useState(false);
   const [activeTrainings, setActiveTrainings] = useState([])
   const [currentTrainings, setCurrentTrainings] = useState([])
   const [loading, setLoading] = React.useState(true);
   const [update, setUpdate] = useState(true);
   const [searchValue, setSearchValue] = useState('')
   const [totalTrainings, setTotalTrainings] = useState(0)
   const [totalActiveTrainings, setTotalActiveTrainings] = useState(0)
   const [totalLearners, setTotalLearners] = useState(0)
   const [totalCompletedTrainings, setTotalCompeletedTrainings] = useState(0)
   const [enrolledLearners, setEnrolledLearners] = useState(0)
   const [unenrolledLearners, setUnenrolledLearners] = useState(0)
const [trainingTimelineData, setTrainingTimelineData] = useState([])
// Intial Loading
useEffect(()=>{
  async function fetchData() {
 const response = await axios.post(API + 'get-data',{id:data.id})
 
 
        console.log(response.data)
        setMainData(response.data)
        setActiveTrainings(response.data.activeTrainings)
        setArchivedTrainings(response.data.archivedTrainings)
        setCurrentTrainings(response.data.activeTrainings)
        let enrolled = [];
        let unenrolled = []
        let combinedTrainings = [...response.data.activeTrainings, ...response.data.archivedTrainings]
        setTotalTrainings(combinedTrainings.length)
        let activeFilter = response.data.activeTrainings.filter(i=>{
          let status = []
          
          i.learners.forEach(o=>{
            if(o.status === 'complete'){
              status.push('c')
            }
          })

          return status.length < i.learners.length
        })
        setTotalActiveTrainings(activeFilter.length)
        setTotalLearners(response.data.learners.length)
        let newLearners = response.data.learners.map((learner) => {
          let learnerTrainings = combinedTrainings.filter((training) =>
            training.learners.some((tLearner) => tLearner.id === learner.id)
          );
        
          return {
            id: learner.id,
            name: learner.name,
            trainings: learnerTrainings,
          };
        });
        let currentTrainings = [...response.data.archivedTrainings, ...response.data.activeTrainings]

        let completedFilter = currentTrainings.filter(i=>{
          let status = []
          
          i.learners.forEach(o=>{
            if(o.status === 'complete'){
              status.push('c')
            }
          })

          return status.length === i.learners.length
        })
        setTotalCompeletedTrainings(completedFilter.length)
       console.log('new learners')
        console.log(newLearners);
        newLearners.map(i=>{
          if(i.trainings.length > 0){
            enrolled.push(i)

          } else {
            unenrolled.push(i)
          }
        })

        setEnrolledLearners(enrolled.length);
        setUnenrolledLearners(unenrolled.length)

    
        const trainingCounts = {};
    let totTrainings = [...response.data.activeTrainings, ...response.data.archivedTrainings]
    console.log('totTrainings')
    console.log(totTrainings)
    // Loop through the training data and count trainings for each month
    totTrainings.forEach(training => {
      const dateCreated = new Date(training.dateCreated);
      const monthYear = `${dateCreated.getMonth() + 1}-${dateCreated.getFullYear()}`;

      if (trainingCounts[monthYear]) {
        trainingCounts[monthYear]++;
      } else {
        trainingCounts[monthYear] = 1;
      }
    });
     // Calculate the starting month for the last 6 months
     const currentDate = new Date();
     let currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed
     let currentYear = currentDate.getFullYear();
     let startingMonthYear = `${currentMonth}-${currentYear}`;
 
     // Generate the last 6 months
     const months = [];
     for (let i = 0; i < 8; i++) {
       months.unshift(startingMonthYear); // Add to the beginning of the array
       // Calculate the previous month
       if (currentMonth === 1) {
         currentMonth = 12;
         currentYear--;
       } else {
         currentMonth--;
       }
       startingMonthYear = `${currentMonth}-${currentYear}`;
     }

    // Prepare the data in Google Chart format with all 12 months
    const chartData = [['Month', 'Trainings']];
   

    months.forEach(monthYear => {
      const count = trainingCounts[monthYear] || 0;
      chartData.push([monthYear, count]);
    });
      setTrainingTimelineData(chartData);

     

      if (totalTrainings !== null && totalActiveTrainings !== null && totalActiveTrainings !== null && totalCompletedTrainings !== null && trainingTimelineData.length > 0){
        setLoading(false)
      }
  }
  fetchData()
},[])
   const chartData = [
    ["Task", "Hours per Day"],
    ["Enrolled Learners", enrolledLearners],
    ["Unenrolled Learners", unenrolledLearners]
  ];

   const barData = [
    ["Year", "Sales", "Expenses", "Profit"],
    ["2014", 1000, 400, 200],
    ["2015", 1170, 460, 250],
    ["2016", 660, 1120, 300],
    ["2017", 1030, 540, 350],
  ];

  const trainingData = [
    { date: '2023-01-01', trainings: 5 },
    { date: '2023-02-01', trainings: 8 },
    // Add more data for each month...
  ];

  function formatChartData(data) {
    const chartData = [['Date', 'Trainings']];
    data.forEach(training => {

      chartData.push([training.date, training.trainings]);
   
    });
    return chartData;
  }

  const tData = formatChartData(trainingTimelineData);

   const options = {
    title: "Learner Ratios",
  };



//Update Data 
useEffect(()=>{
  async function fetchData() {
 const response = await axios.post(API + 'get-data',{id:data.id})
 
 
        console.log(response.data)
        setMainData(response.data)
        setActiveTrainings(response.data.activeTrainings)
        setArchivedTrainings(response.data.archivedTrainings)
        setCurrentTrainings(response.data.activeTrainings)
        let currentTrainings = [...response.data.archivedTrainings, ...response.data.activeTrainings]
         setTotalTrainings(currentTrainings.length)
         let activeFilter = response.data.activeTrainings.filter(i=>{
          let status = []
          
          i.learners.forEach(o=>{
            if(o.status === 'complete'){
              status.push('c')
            }
          })

          return status.length < i.learners.length
        })

        let completedFilter = currentTrainings.filter(i=>{
          let status = []
          
          i.learners.forEach(o=>{
            if(o.status === 'complete'){
              status.push('c')
            }
          })

          return status.length === i.learners.length
        })
        setTotalCompeletedTrainings(completedFilter.length)
        setTotalActiveTrainings(activeFilter.length)
        setTotalLearners(response.data.learners.length)
       
      if (totalTrainings !== null && totalActiveTrainings !== null && totalLearners !== null && totalCompletedTrainings !== null){
        setLoading(false)
      }
        if(showArchivedTrainings){
          setCurrentTrainings(response.data.archivedTrainings)
        
        }
  }
  fetchData()
  
},[update])


const DeleteTraining = async (a)=>{
  let confirmDelete = window.confirm('Are you sure you would like to DELETE this training. This action will delete the entire training and there will be now way to recover it.') 
  console.log(showArchivedTrainings)
  if(confirmDelete) {
    let resp = await axios.post(API+'delete-training', {userId:data.id, trainingId: a,type: showArchivedTrainings ? 'archived' : 'active'})
    console.log(resp)
    if(resp.data === 'error'){
      window.alert('The deletion of the training failed. Please try again.')
    } else {
      
      setUpdate(!update)
   
    }
  }
}


const ArchiveTraining = async (training)=>{
  let confirmArchive = window.confirm('Are you sure you would like to Archive this training. This will move this training to the archived training section and will not be able to be returned to the acitve training section.') 

  if(confirmArchive) {
    let resp = await axios.post(API+'archive-training', {userId:data.id, training: training})
    console.log(resp)
    if(resp.data === 'error'){
      window.alert('Something went wrong. Please try again.')
    } else {
      setUpdate(!update)
    }
  }
}

if(loading){
  return <Box sx={{textAlign:'center', marginTop:20}}>
    <CircularProgress/>
    <Typography><b>Loading</b></Typography>
  </Box>
} else {
  
    return <Box>

<Container >
<Metrics
TotalTrainings={totalTrainings}
ActiveTrainings={totalActiveTrainings}
TotalLearners={totalLearners}
TotalCompletedTrainings={totalCompletedTrainings}
/>
      <Grid container spacing={3}>
        {/* First item */}
        <Grid item xs={12} sm={6}>
          <Paper >
          <Chart
      chartType="PieChart"
      data={chartData}
      options={options}
      width={"100%"}
      height={"400px"}
    />
          </Paper>
        </Grid>
        
        {/* Second item */}
        <Grid item xs={12} sm={6}>
          <Paper >
          <Chart
        width={'100%'}
        height={'400px'}
        chartType="ColumnChart" // Use ColumnChart for vertical bars
        loader={<div>Loading Chart</div>}
        data={trainingTimelineData}
        options={{
          title: 'Trainings Conducted (Last 8 months)',
          hAxis: {
            title: 'Month',
          },
          vAxis: {
            title: 'Trainings',
            minValue: 0,
          },
          legend: { position: 'none' }, // Hide legend
        }}
      />
          </Paper>
        </Grid>
      </Grid>
    </Container>

<Box className="mt-4 mb-2" sx={{textAlign:'center'}}>
    <Typography sx={{fontSize:20}} variant="button"><b>Current Trainings</b></Typography>
</Box>
        <TableContainer sx={{padding:'10px', marginTop:2}} component={Paper}>
        
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
                  <TableRow>
           
                  <TableCell><Typography variant="button"><b>Training Name</b></Typography></TableCell>
                  <TableCell><Typography variant="button"><b>Date Created</b></Typography></TableCell>
                  <TableCell><Typography variant="button"><b>Training Type</b></Typography></TableCell>
                  <TableCell><Typography variant="button"><b>Status</b></Typography></TableCell>
                  <TableCell><Typography variant="button"><b>Progress</b></Typography></TableCell>
                  <TableCell align="center"><Typography variant="button"><b>Actions</b></Typography></TableCell>
                  </TableRow>
                </TableHead>
              <TableBody>
        
                {
                  currentTrainings.filter((i) =>
                  i.course.courseName.toLowerCase().includes(searchValue.toLowerCase()) ||
                  formatDate(i.dateCreated).includes(searchValue.toLowerCase()) ||
                  i.type.toLowerCase().includes(searchValue.toLowerCase()) 
                ).map(i=>{
                    let status = []
                    console.log(i.learners)
                    i.learners.forEach(o=>{
                      if(o.status === 'complete'){
                        status.push('c')
                      }
                    })
                    if(status.length < i.learners.length) {
                        
                    return (
        
                      <TableRow>
            
                      <TableCell>{i.course.courseName}</TableCell>
                      <TableCell>{<Typography variant="button">{formatDate(i.dateCreated)}</Typography>}</TableCell>
                      <TableCell>{i.type === 'audio' && i.type != 'bosscall' ? <Typography variant="button">Audio</Typography> :i.type === 'module' && i.type != 'bosscall' ?  <Typography variant="button">Module Learning</Typography>:  i.type === 'bosscalll'?  <Typography variant="button">Video</Typography>:  <Typography variant="button">Video</Typography>}</TableCell>
                      <TableCell>{status.length < i.learners.length ? <Typography sx={{color: 'orange', fontWeight:'bold'}} variant="button">In Progress</Typography> : <Typography sx={{color:'#199A71', fontWeight:'bold'}} variant="button">Complete √</Typography>}</TableCell>   
                      <TableCell>{status.length}/{i.learners.length}</TableCell>
                              <TableCell align="center">
                            <Tooltip title='Training Details' ><IconButton  onClick={()=>{
                                    navigate('/training-details', { replace: true, state:{i} })
                                  }} sx={{padding:0.5}}> <InfoIcon  htmlColor='#3FA9E6' sx={{cursor:'pointer',width:'30px'}} /></IconButton></Tooltip> 
                          
                          <Tooltip title='Delete Training' ><IconButton sx={{padding:0.5}} onClick={()=>DeleteTraining(i.id)}> <DeleteIcon  htmlColor='tomato' sx={{cursor:'pointer',width:'30px'}} /></IconButton></Tooltip> 
                          <Tooltip title='Archive Training' ><IconButton sx={{padding:0.5, display:showArchivedTrainings?'none':'inline'}} onClick={()=>ArchiveTraining(i)}> <ArchiveIcon htmlColor='#3FA9E6'  sx={{cursor:'pointer',width:'30px'}} /></IconButton></Tooltip> 
          
                              </TableCell>
                            </TableRow>
        
                    
                    )

                }
                  })  
                  
                }
               
                
               </TableBody>
              </Table>
            </TableContainer>
    </Box>
}

}
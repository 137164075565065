import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import logo from '../../assets/css/images/icon6.png'
import '../../assets/css/style.css'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { VideoTrainingLibrarySetup } from './VideoTrainingLibrarySetup';
import { AudioTrainingLibrarySetup } from './AudioTrainingLibrarySetup';
import { useNavigate } from "react-router-dom";
import { BossCallsTrainingLibrarySetup } from './BossCallsTrainingLibrarySetup';
import { MicroTrainingLibrarySetup } from './MicroLearningLibrarySetup';

const drawerWidth = 240;
const navItems = ['Back To Dashboard'];


export const TrainingSetup = (props)=>{
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <img src={logo} width="175px" height="auto"/>
      <Divider />
      <List>
      {/* <Link to="/"> */}
          <ListItem disablePadding>
            <ListItemButton onClick={()=>navigate('/', { replace: true, state:'TM' })}  sx={{ textAlign: 'center' }}>
              <ListItemText primary={"BACK TO DASHBOARD"} />
            </ListItemButton>
          </ListItem>
      {/* </Link> */}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'block', backgroundColor:'#ECEEF1' }}>
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block', padding:10 } }}
          >
           <img src={logo} width="75px" />
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              <Button onClick={()=>navigate('/', { replace: true})} key={item} sx={{ color: '#fff' }}>
                {item}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      
        <Toolbar />
        <Box sx={{display:'flex',backgroundColor:'#ECEEF1', justifyContent:'center' , marginTop:'50px',alignItems:'stretch'}}>
        <Box sx={{maxWidth:'1080px', width:'100%'}}>
        <Typography sx={{fontSize:18, fontWeight:'bold'}} variant='button'>SELECT TRAINING: </Typography>
      
        <Tabs
   
      defaultActiveKey="video"
      id="fill-tab-example"
      className="mb-3"
      fill
    >
      <Tab eventKey="video" title={<Typography variant='button'><b>SINGLE VIDEO TRAININGS</b></Typography>}>
      <VideoTrainingLibrarySetup/>
      </Tab>
      <Tab eventKey="audio" title={<Typography variant='button'><b>SINGLE AUDIO TRAININGS</b></Typography>}>
      <AudioTrainingLibrarySetup/>
      </Tab>

  

      <Tab eventKey="mc" title={<Typography variant='button'><b>MODULE-LEARNING COURSES</b></Typography>}>
      <MicroTrainingLibrarySetup/>
      </Tab>
    
    </Tabs>
    </Box>
       </Box>
      </Box>
     
    
  );
}

TrainingSetup.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';




export const UploadAudio = (props) =>{
const [loading, setLoading] = useState(false)

  


if(props.AudioFile != ''){
    return(
        <Box sx={{display:'flex'}}>
      <Typography>
        {props.AudioFile.name ? props.AudioFile.name : props.AudioFile.substring(props.AudioFile.lastIndexOf('/')+1).replace(/%20/g, " ")}
        </Typography><Button onClick={()=>props.SetAudioFile('')} variant="contained" sx={{padding:.5, marginLeft:5}}>Change</Button>
      </Box>
    )
   
} else {
    
    return (
        <Box>
         <InputGroup>
        <Form.Control 
        accept="audio/*"

        onChange={(e)=>{
          if(e.target.files[0].size >  200000000){
            window.alert('File size is too big. Size should be under 200mb.')
            e.target.value = '';
          } else {
            
            console.log()
            props.SetAudioFile(e.target.files[0]);
            // props.SetFileSize(e.target.files[0].size); 
           
            const objectUrl = URL.createObjectURL(e.target.files[0])
            
          }
            }} type='file'/>
       </InputGroup>   
        <Typography variant='caption'><b>Must be under 200mbs </b></Typography>
        </Box>
        
    )


}
}
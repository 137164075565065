import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

export default function ModuleListItem(props) {
  return (
    <div>
          <ListItem
          secondaryAction={
            <div style={{display:'inline'}}>
              
            <IconButton sx={{marginLeft:1}} onClick={props.OnClickDelete} edge="end" aria-label="delete">
              <DeleteIcon htmlColor='red' />
            </IconButton>
            
           </div>
          }
          sx={{backgroundColor:props.Selected, borderRadius:2}} disablePadding>
            <ListItemButton disabled={props.Disabled}  onClick={props.OnClick}>
              {/* <ListItemIcon>
                <Circle />
              </ListItemIcon> */}
              <ListItemText

              primary={<Typography><b>{props.ModuleNumber}</b></Typography> }
             
               secondary={props.ModuleTitle}
              />
            </ListItemButton>
          </ListItem>
          <Divider />
          </div>
  );
}
import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

export default function NotificationListItem(props) {
  return (

    <ListItem dense sx={{width:'300px'}}
    secondaryAction={
      <IconButton onClick={props.ClickDelete} edge="end" aria-label="delete">
        <DeleteIcon />
      </IconButton>
    }
  >
   
    <ListItemText
      primary={props.Name}
      secondary={props.Message}
    />
  </ListItem>
      
  
      
  );
}
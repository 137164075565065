import  React, {useEffect, useState} from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import logo from '../../assets/css/images/icon6.png'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
// import { ModuleTraining } from '../ModuleTraining';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import Badge from '@mui/material/Badge';
import copy_logo from '../../assets/css/images/copy-logo.png'
import QuizIcon from '@mui/icons-material/Quiz';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { TrainingDocumentCard } from '../../components/TrainingDocumentCard';
import axios from 'axios';
import { useSearchParams } from "react-router-dom";
import { API } from '../../controllers/API';
import { formatDate } from '../../classes/global-functions';
import { CircularProgress } from '@mui/material';
import { Text } from './Module-Types/Text';
import { Video } from './Module-Types/Video';
import { Audio } from './Module-Types/Audio';
import { Quiz } from './Module-Types/Quiz';
import { blue } from '@mui/material/colors';
import { SingleVideo } from './Module-Types/SingleVideo';
import { SingleAudio } from './Module-Types/SingleAudio';
import { Scorm } from './Module-Types/Scorm';

const CustomListItemIcon = styled(ListItemIcon)({
  color: 'white',
  backgroundColor: blue[500],
  borderRadius: '50%',
  width: '30px !important',
  height: '30px !important',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const DrawerList = ({ course, handleModuleChange, currentModule, learnerStatus }) => {
  return (
    <div>
      {course.courseData.map((module, index) => {
        if(course.type === 'module')
        return (
        <ListItemButton
          key={index}
          onClick={() => handleModuleChange(index)}
          sx={{
            borderBottom: '#EBEBEB 1px solid',
            backgroundColor: currentModule === index ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
            color: currentModule === index ? 'black' : 'inherit',
          }}
        >
         <CheckCircleIcon htmlColor='green' sx={{display: learnerStatus.completedModules[index].completed ? 'inline': 'none', marginRight:1, padding:0}}/>

          <Badge
            badgeContent={index + 1}
            color="primary"
            sx={{
              display: learnerStatus.completedModules[index].completed ? 'none': 'inline',
              marginRight: 3,
              marginLeft: 1,
              bgcolor: currentModule === index ? '#3FA9E6' : 'transparent',
              color: currentModule === index ? 'white' : 'inherit',
            }}
          />

          <ListItemText primary={module.moduleTitle} />
        </ListItemButton>
        )})}
     
    </div>
      
  )}

let drawerWidth = 100;
function Copyright(props) {
  return (

<div style={{textAlign:'center'}}>
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
          
    <img src={copy_logo} width="100px" height="auto"/>
  
      {'Copyright © '}
      <Link target='_blank' color="inherit" href="https://employeetrainingtoolkit.com/">
        Employee Training Tool-Kit
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
    </div>
  );
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export default function Training(props) {
const [course, setCourse] = useState({})
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [currentModule, setCurrentModule] = useState(0)
  const [showQuiz, setShowQuiz] = useState(false)
  const [hideDocuments, setHideDocuments] = useState(false)
  const [learner, setLearner] = useState({name:''})
  const [training, setTraining] = useState()
  const [loading,setLoading] = useState(true)
  const [update, setUpdate] = useState(true)
  const [learnerCompletedModules, setLearnerCompletedModules] = useState(learner.percentage)
  let [searchParams, setSearchParams] = useSearchParams();

  const handleModuleChange = (moduleIndex) => {
    setCurrentModule(moduleIndex);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    drawerWidth = 300;
  };
  const [completedModules, setCompeletedModules] = useState(0)
  const handleDrawerClose = () => {
    setOpen(false);
    drawerWidth = 10;
  };

  const handleShowQuiz = () => {
    // Your implementation for ShowQuiz
   setShowQuiz(true)
  };

//   useEffect(()=>{
//     let userId = searchParams.get('a');
//     let trainingId = searchParams.get('b');
//     let learnerId = searchParams.get('c')
// const fetchData = async () => {
//     const resp = await axios.post(API + 'find-training', {userId:userId, trainingId: trainingId});

//     if(resp === 'error'){
//       window.alert('Something went wrong. Please try again.')
//     } else {
//        console.log(resp.data)
//        setCourse(resp.data.course)
//       //  setQuestions(resp.data[0].course.quiz)
//        let learnerFilter = resp.data[0].learners.filter(i => {return i.id === learnerId})
//        if(learnerFilter.length === 0){
//         window.alert('This training is no longer available for this learner. If you think this is a mistake please reach out to the person who sent the training so it can be reassigned.')
//         window.close()
//        }
       
//        if(learnerFilter[0].status === 'complete'){
//         // setComplete(true)
//        }

//        setLearner(learnerFilter[0])
//        setTraining(resp.data)
       
//        setLoading(false)
//        if(resp.data.type === 'module'){
        
//        let modNumber = [];
//      learnerFilter[0].completedModules.forEach((i,index) => {
//       if(i.completed === false ){
//          modNumber.push(index)
//       }
//      });

  
// }

   
     
//     }
 
  
//   }
//   fetchData()
//   },[update])


  let userId = searchParams.get('a');
    let trainingId = searchParams.get('b');
    let learnerId = searchParams.get('c')

  useEffect(()=>{
    
const fetchData = async () => {
    const resp = await axios.post(API + 'find-training', {userId:userId, trainingId: trainingId});

    if(resp === 'error'){
      window.alert('Something went wrong. Please try again.')
    } else {
       console.log(resp.data)
       setCourse(resp.data.course)
      //  setQuestions(resp.data[0].course.quiz)
       let learnerFilter = resp.data.learners.filter(i => {return i.id === learnerId})
       console.log(learnerFilter)
       if(learnerFilter.length === 0){
        window.alert('This training is no longer available for this learner. If you think this is a mistake please reach out to the person who sent the training so it can be reassigned.')
       }
       if(learnerFilter[0].status === 'complete'){
      //  setShowQuiz(true)
        console.log('completed')
       }

       if(learnerFilter[0].completedModules){
        let compModsFilter = learnerFilter[0].completedModules.filter(i=>{
        return(i.completed === true)
        })

        setCompeletedModules(compModsFilter.length)
       }

       setLearner(learnerFilter[0])
       setTraining(resp.data)
       
       setLoading(false)
  
     
    }
 
  
  }
  fetchData()

  },[update])
  const totalModules = course.courseData?.length;

  useEffect(() => {
    const findFirstIncompleteModule = () => {
      if (learner && learner.completedModules) {
        const completedModuleCount = learner.completedModules.filter(
          (module) => module.completed
        ).length;
  
        if (completedModuleCount === totalModules) {
          setShowQuiz(true);
        } else {
          const incompleteModuleIndex = learner.completedModules.findIndex(
            (module) => !module.completed
          );
          if (incompleteModuleIndex !== -1) {
            setCurrentModule(incompleteModuleIndex);
          }
        }
      }
    };
  
    findFirstIncompleteModule();
  }, [learner, totalModules]);


  const handleNextModule = () => {
    updateModuleComplete(learner.completedModules[currentModule].id);
  
    if (currentModule < totalModules - 1) {
      const updatedCompletedModules = [...learner.completedModules];
      updatedCompletedModules[currentModule].completed = true;
      setLearner({ ...learner, completedModules: updatedCompletedModules });
      setCurrentModule(currentModule + 1);
    } else {
      const completedModuleCount = learner.completedModules.filter(
        (module) => module.completed
      ).length;
  
      if (completedModuleCount === totalModules - 1) {
        const updatedCompletedModules = [...learner.completedModules];
        updatedCompletedModules[currentModule].completed = true;
        setLearner({ ...learner, completedModules: updatedCompletedModules });
        setShowQuiz(true);
      } else {
        if(course.courseData[0].type === 'scorm'){
          setShowQuiz(true); 
        } else {
        alert('Please complete all the preceding modules before proceeding to the quiz.');
        }
      }
    }
  };
  

  const handlePreviousModule = () => {
    if (currentModule > 0) {
      setCurrentModule(currentModule - 1);
    }
  };


  const updateModuleComplete = async (a)=>{
    let userId = searchParams.get('a');
    let trainingId = searchParams.get('b');
    let learnerId = searchParams.get('c')
    let resp = await axios.post(API + 'update-module-progress', {userId:userId, trainingId:trainingId, learnerId:learnerId, currentModuleId: a})
    learner.completedModules[currentModule].completed = true;
    if(resp.data === 'error'){
      alert('Something went wrong and your progress was not saved.')
    } else {
      console.log(resp.data.message)
    setUpdate(!update)
    }
  }

  //Profile Menu Logic
const [anchorElProfile, setAnchorElProfile] = React.useState(null);
const openProfile = Boolean(anchorElProfile);
const openProfileMenu = (event) => {
  console.log(event)
  setAnchorElProfile(event.currentTarget);
};
const handleCloseProfile = () => {
  setAnchorElProfile(null);
};

//END PROFILE MENU LOGIC

  if(loading){
    <Box sx={{textAlign:'center', marginTop:20}}>
    <CircularProgress/>
    <Typography><b>Loading</b></Typography>
  </Box>
  } else {
    
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
        <img height='auto'style={{padding:5}} width={70} src={logo}/>
          <div style={{position:'absolute', top:15, right:25}}>
          <IconButton
        aria-controls={openProfile ? 'basic-menu-profile' : undefined}
        aria-haspopup="true"
        aria-expanded={openProfile ? 'true' : undefined}
        onClick={openProfileMenu}
       sx={{color:'#3FA9E6',  backgroundColor:'white', marginRight:'10px'}}><PersonOutlineIcon /></IconButton>
      <div style={{display:course.type === 'single-audio' || course.type === 'single-video'|| course.type === 'bosscall' || learner.status === 'complete'? 'none':'inline'}}>
       <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: 'none' })}}
          >
            <MenuIcon />
          </IconButton>
          </div>
          </div>
       <Menu
        id="basic-menu-profile"
        anchorEl={anchorElProfile}
        open={openProfile}
        onClose={handleCloseProfile}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <ListItem sx={{width:'200px', height:'45px'}} dense>
        <ListItemText
        
      primary={<Typography sx={{fontSize:'12px', fontWeight:'bold', color:'gray'}} variant='button'>Name</Typography>}
      secondary={<Typography variant='caption'><br/><b>{learner.name}</b></Typography>}
    />
        </ListItem>
<hr></hr>
<ListItem sx={{width:'200px', height:'45px'}} dense>
        <ListItemText
        
      primary={<Typography sx={{fontSize:'12px', fontWeight:'bold', color:'gray'}} variant='button'>Date of Training</Typography>}
      secondary={<Typography variant='caption'><br/><b>{formatDate(training.dateCreated)}</b></Typography>}
    />
        </ListItem>
<hr></hr>
<ListItem sx={{width:'200px', height:'45px'}} dense>
        <ListItemText
        
      primary={<Typography sx={{fontSize:'12px', fontWeight:'bold', color:'gray'}} variant='button'>Progress</Typography>}
      secondary={<Typography variant='caption'><br/><b>{learner.completedModules ? `${completedModules}/${learner.completedModules.length}` : `${learner.percentage}%`}</b></Typography>}
    />
        </ListItem>

      </Menu>
      
        </Toolbar>
      </AppBar>

      <Main open={open}>
       
      <>
      <Box sx={{ marginTop: 10, paddingLeft: 5, paddingRight:5, display:showQuiz === false ? 'block':'none' }}>
        {/* Your course content */}
        <Box>
          
        
  
        </Box>

        
        {course.courseData.map((item, index) => {
          if(course.type === 'single-video'){
            return (
              <SingleVideo
              ShowSeek={training.seekbar}
              trainingId={trainingId}
              userId={userId}
              CourseTitle={course.courseName}
              VideoLink={item.videoLink}
              ModuleTitle={'Description'}
              ModuleDescription={course.courseDescription}
              Learner={learner}
              ShowQuiz={handleShowQuiz}
              />
            )
          }

          if(course.type === 'single-audio'){
            return (
              <SingleAudio
              ShowSeek={training.seekbar}
              TrainingId={trainingId}
              UserId={userId}
              CourseTitle={course.courseName}
              audioUrl={item.audioLink}
              description={course.courseDescription}
              Learner={learner}
              imageUrl={course.poster}
              moduleTitle={item.moduleTitle}
              AudioEnded={handleShowQuiz}
              />
            )
          }
          if (index === currentModule && item.type  === 'text' && course.type === 'module') {
            return(
            <Text
            CourseTitle={course.courseName}
            ModuleTitle={item.moduleTitle}
            ModuleDescription={item.content}
            />
            )
          }
          if (index === currentModule && item.type === 'video' && course.type === 'module') {
            return (
                <Video
                CourseTitle={course.courseName}
                VideoLink={item.videoLink}
                ModuleTitle={item.moduleTitle}
                ModuleDescription={item.content}
                Learner={learner}
                />
            );
          }
          if (index === currentModule && item.type === 'scorm' && course.type === 'module') {
            return (
                <Scorm
                CourseTitle={course.courseName}
                VideoLink={item.videoLink}
                ModuleTitle={course.courseName}
                ModuleDescription={course.courseDescription}
                Learner={learner}
                />
            );
          }
          if (index === currentModule && item.type === 'audio' && course.type === 'module') {
            return (
            <Audio
            CourseTitle={course.courseName}
            audioUrl={item.audioLink}
            title={item.moduleTitle}
            description={item.content}
            Learner={learner}
            imageUrl={course.poster}
            moduleTitle={item.moduleTitle}
            />
            );
          }
          return null;
        })}
      </Box>
      <Box sx={{marginTop: 8, padding: 5, display:showQuiz === true? 'block':'none'}}>
        <Quiz
CourseTitle={course.courseName}
Issuer={'Demo'}
IssuerTitle={'Presenter'}
ModuleTitle={course.quiz === 'NA'?`Quiz ${course.quiz.length} Questions`:''}
ModuleDescription={course.courseData[currentModule].content}
Quiz={course.quiz}
Training={training}
Learner={learner}
ShowQuiz={()=>{setShowQuiz(true)}}
GoBack={()=>{setShowQuiz(false) }}
Type={course.courseData[currentModule].type}
/>
      </Box>
      <div style={{display:showQuiz === true || course.type === 'single-video' || course.type === 'single-audio' ? 'none':'block'}}>
      <hr></hr>
      </div>
      <Box
  sx={{
    marginTop: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 0,
    display:showQuiz === true || course.type === 'single-video' || course.type === 'single-audio' ? 'none':'flex',
    justifyContent: "space-between",
  }}
>
  {/* Navigation buttons */}
  <Button
    onClick={handlePreviousModule}
    disabled={currentModule === 0}
    variant="contained"
    sx={{ marginRight: 2 }}
  >
    Previous
  </Button>
  <Button onClick={handleNextModule} variant="contained">
    {currentModule === totalModules - 1 ? "Take Quiz" : "Mark Complete"}
  </Button>
</Box>
<div style={{display:showQuiz === true || course.type === 'single-video' || course.type === 'single-audio' ? 'none':'block'}}>
<hr></hr>
</div>
      <Box 
          sx={{display: showQuiz === true || hideDocuments || course.documents.length < 1 ? 'none':'block', paddingLeft: 5, paddingRight: 5}}
          >
  <Typography >
    <b>DOCUMENTS</b>
  </Typography>
  {course.documents.map((i,index)=>{
               if(i.documentName.toLowerCase().includes('instructor') === false){
                
            return ( <TrainingDocumentCard
              key={i.id}
              DocumentName={i.documentName.replace(/%20/g, ' ')}
              FileType={i.fileType}
              LinkUrl={i.link}
              />)

            }
           })}
           
</Box>
<div style={{display:showQuiz === true ? 'none':'block'}}>
<hr></hr>
</div>
    </>
 
      </Main>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <DrawerList learnerStatus={learner} currentModule={currentModule} course={course} handleModuleChange={handleModuleChange} />
          
        </List>
        <ListItem divider  sx={{backgroundColor:showQuiz?'#1589FE36':''}} disablePadding>
                <ListItemButton disableGutters onClick={(()=>{
                 console.log( completedModules + " " + course.courseData.length)
                 if(completedModules >= course.courseData.length){
              
                  setShowQuiz(true); 
                  course.courseData[currentModule].completed = true; 
      
                } else {
                  window.alert('Please complete all the modules before you take the quiz.')
                }
                })} >
                <ListItemIcon>
                <QuizIcon color='primary' sx={{display:learner.status == 'complete' ? 'none': 'inline', marginLeft:2}}/>
                <CheckCircleIcon htmlColor='green' sx={{display: learner.status == 'complete' ? 'inline': 'none', marginLeft:2}}/>

                </ListItemIcon>
                <ListItemText primary={"QUIZ"} />
                </ListItemButton>
            </ListItem>
      </Drawer>
    </Box>
  );
}

}



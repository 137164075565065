import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Avatar, makeStyles, CircularProgress } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import logo from '../../assets/css/images/icon6.png';
import AddIcon from '@mui/icons-material/Add';
import stockImg from '../../assets/css/images/stock_custom_card_img.png';
import { Link, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Typography';
import CustomTrainingCard from '../../components/training-builder-components/CustomTrainingCard';
import ViewToggle from '../../components/training-builder-components/ViewToggle';
import axios from 'axios';
import { API } from '../../controllers/API';
import { DeleteCourseAws, DeleteVideoVimeo } from '../../classes/TrainingBuilderFunctions/Delete';
import DeleteTrainingModal from '../../components/training-builder-components/DeleteTrainingModal';
import { formatDate } from '../../classes/global-functions';
import SearchBox from "../../components/SearchInput";
import DeleteIcon from '@mui/icons-material/Delete';

const drawerWidth = 240;
const navItems = ['Back To Dashboard'];

export const TrainingBuilder = (props) => {
  const data = JSON.parse(sessionStorage.getItem('profile-data'));
  const { windowSize } = props;
  const [currentCourseAmmount, setCurrentCourseAmmount] = useState(0)
  const [courseMax, setCourseMax] = useState(0)
  const [mobileOpen, setMobileOpen] = useState(false);
  const [update, setUpdate] = useState(true);
  const [searchValue, setSearchValue] = useState('')
  const [mainData, setMainData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const [view, setView] = useState('cards');

  const handleViewChange = (event, newView) => {
    setView(newView);
    console.log(newView);
  };

  useEffect(() => {
    async function fetchData() {
      const response = await axios.post(API + 'get-data', { id: data.id });

      console.log(response.data);
      setMainData(response.data);
      if(response.data.membershipType === 'Employee Training Tool-kit - Pro'){
        setCourseMax(10)
        console.log(courseMax)
      }

      if(response.data.membershipType === 'Employee Training Tool-kit - Basic'){
        setCourseMax(0)
      }

      if(response.data.membershipType === 'Employee Training Tool-kit - Enterprise'){
        setCourseMax(99999)
      }
      setCurrentCourseAmmount(response.data.customTrainings.length)

      setLoading(false);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const response = await axios.post(API + 'get-data', { id: data.id });

      console.log(response.data);
      setMainData(response.data);

      setLoading(false);
    }
    fetchData();
  }, [update]);

  const DeleteModuleTraining = (a, trainingId) => {
    let conf = window.confirm('If you delete a course this cannot be undone. Are you sure you would like to do this?');
    
    if (conf) {
      setOpenDeleteModal(true);
      DeleteCourseAws(data.email, trainingId, async () => {
        let resp = await axios.post(API + 'delete-custom-training', { id: data.id, trainingId: trainingId });

        if (resp === 'error') {
          alert('Something went wrong. Please try again');
        } else {
          a.forEach((i) => {
            if (i.type === 'video') {
              let arr = [];
              DeleteVideoVimeo(data.email, i.videoLink, () => {
                arr.push('i');
                if (arr.length === a.length) {
                  setUpdate(!update);
                  setOpenDeleteModal(false);
                }
              });
            } else {
              setUpdate(!update);
              setCurrentCourseAmmount(currentCourseAmmount - 1)
              setOpenDeleteModal(false);
            }
          });
        }
      });
    }
  };

  const DeleteTraining = async (a, videoLink) => {
    let conf = window.confirm('If you delete a course this cannot be undone. Are you sure you would like to do this?');
    if (conf) {
      setOpenDeleteModal(true);
      let trainingId = a;
      DeleteCourseAws(data.email, trainingId, async () => {
        let resp = await axios.post(API + 'delete-custom-training', { id: data.id, trainingId: trainingId });

        if (resp === 'error') {
          alert('Something went wrong. Please try again');
        } else {
          if (a.type === 'single-video') {
            if (videoLink !== 'NA') {
              DeleteVideoVimeo(data.email, videoLink, () => {
                setUpdate(!update);
                setOpenDeleteModal(false);
              });
            } else {
              setUpdate(!update);
              setOpenDeleteModal(false);
            }
          } else {
            setUpdate(!update);
            setCurrentCourseAmmount(currentCourseAmmount - 1)
            setOpenDeleteModal(false);
          }
        }
      });
    }
  };

  const container = windowSize !== undefined ? () => window().document.body : undefined;

  if (loading) {
    return <Box sx={{textAlign:'center', marginTop:20}}>
    <CircularProgress/>
    <Typography><b>Loading</b></Typography>
  </Box>
  } else {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <CssBaseline />
        <AppBar component="nav">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ display: { xs: 'none', sm: 'block', padding: 10 } }}>
              <img src={logo} width="75px" alt="Logo" />
            </Typography>
            <Typography color={'white'}>
              <b>TRAINING BUILDER</b>
            </Typography>

            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              {navItems.map((item) => (
                <Button key={item} sx={{ color: '#fff', position: 'absolute', right: 15, top: 30 }} onClick={() => navigate('/', { replace: true })}>
                 <b> {item}</b>
                </Button>
              ))}
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {/* {drawer} */}
          </Drawer>
        </Box>
        <Box component="main" sx={{ flex: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
          <Toolbar />
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Grid container>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                
              </Grid>
              <Grid sx={{ textAlign: 'end', marginTop:5  }} item xs={12}>
               
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',  marginBottom:3 }}>
      <SearchBox
      onChange={(e)=>setSearchValue(e.target.value)}
      value={searchValue}
      placeholder="Search"
      />
       <ViewToggle View={view} onChange={handleViewChange} />
    </Box>
               
                
              </Grid>
            </Grid>
            <Stack
              sx={{ display: view === 'cards' ? 'flex' : 'none', width: '100%', mt: 2, justifyContent:'center' }}
              spacing={{ xs: 1, sm: 2 }}
              direction="row"
              useFlexGap
              flexWrap="wrap"
            >
              {mainData.customTrainings.filter((i) =>
          i.courseName.toLowerCase().includes(searchValue.toLowerCase()) 
          
        ).map((i) => {
          if(i.doNotShow){} else {
            
          return(
                <CustomTrainingCard
                  key={i.id}
                  ClickEdit={() => {
                    sessionStorage.setItem('trainingId', i.id);
                    navigate('/edit-training', { replace: true, state: i });
                  }}
                  CourseImage={i.poster === '' ? stockImg : i.poster}
                  CourseName={i.courseName}
                  Type={i.type.toUpperCase()}
                  CourseDescription={i.courseDescription}
                  TrainingStatus={i.active}
                  Course={i}
                  OnClickDelete={() => (i.type === 'module' ? DeleteModuleTraining(i.courseData, i.id) : DeleteTraining(i.id, i.videoLink))}
                />
                )

          }
        })}
              <Box sx={{ display: view === 'cards' ? 'flex' : 'none', width: 265, height: 300, marginLeft: 2, marginTop: '16px !important', border: '2px grey dashed', backgroundColor: '#FFFFFF95', alignItems: 'center', justifyContent: 'center' }}>
                <Tooltip title="Build New Course">
                  <Button onClick={() => {
                    if((currentCourseAmmount + 1) > courseMax){
                      window.alert('Adding another course will exceed the ammount of courses you are allowed to have with this memebership level.')
                    } else {
                    
                    navigate('/build-training')
                    }
                  }                  
                    } variant="contained" sx={{ height: 60, borderRadius: '30px', width: 40 }}>
                    <AddIcon htmlColor="white" />
                  </Button>
                </Tooltip>
              </Box>
            </Stack>

            <Stack
  sx={{ display: view === 'list' ? 'block' : 'none', width:'100%', marginTop:0}}
  spacing={{ xs: 1, sm: 2 }}
  direction="row"
  useFlexGap
  flexWrap="wrap"
>
  <Button variant='contained' sx={{margin:1}} onClick={() => {
                    if((currentCourseAmmount + 1) > courseMax){
                      window.alert('Adding another course will exceed the ammount of courses you are allowed to have with this memebership level.')
                    } else {
                    
                    navigate('/build-training')
                    }
                  }                  
                    }><b>+ Build New Course</b></Button>
<TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell><Typography variant="body2"><b>COURSE NAME</b></Typography></TableCell>
            <TableCell><Typography variant="body2"><b>STATUS</b></Typography></TableCell>
            <TableCell><Typography variant="body2"><b>COURSE TYPE</b></Typography></TableCell>
            <TableCell><Typography variant="body2"><b>DATE CREATED</b></Typography></TableCell>
            <TableCell><Typography variant="body2"><b>LAST UPDATE</b></Typography></TableCell>
            <TableCell sx={{textAlign:'center'}}><Typography variant="body2"><b>ACTIONS</b></Typography></TableCell>
           </TableRow>
        </TableHead>
        <TableBody>
        {mainData.customTrainings.filter((i ) =>
          i.courseName.toLowerCase().includes(searchValue.toLowerCase()) 
          
        ).map((item, index) => {

          if(item.doNotShow){} else {
        
                        return (

                          <TableRow key={index}>
                          <TableCell>
                            <Avatar sx={{ width: 125, height: 'auto' }} alt={`Image ${index + 1}`} variant="rounded"  src={item.poster} />
                          </TableCell>
                          <TableCell>{item.courseName}</TableCell>
                          <TableCell><Typography variant="body2" sx={{ color: item.active ? 'green' : 'tomato', marginTop: 1 }}><b>{item.active ? 'ACTIVE' : 'INACTIVE'}</b></Typography></TableCell>
                          <TableCell><Typography sx={{ marginTop: 1 }} variant="body2" color="text.secondary">
                            <b><span >{item.type.toUpperCase()}</span></b>
                          </Typography></TableCell>
                          <TableCell>
                          <Typography sx={{ marginTop: 1 }} variant="body2" color="text.secondary">
                            <b><span>{formatDate(item.dateCreated)}</span></b>
                          </Typography>
                          </TableCell>
                          <TableCell>
                          <Typography sx={{ marginTop: 1 }} variant="body2" color="text.secondary">
                            <b><span >{formatDate(item.dateUpdated)}</span></b>
                          </Typography>
                          </TableCell>
                          <TableCell>
                          <div style={{display:'flex', justifyContent:'center'}}>
                          <Button onClick={() => {
                            sessionStorage.setItem('trainingId', item.id);
                            navigate('/edit-training', { replace: true, state: item });
                         }} sx={{ height: 36, fontSize: 14 }} size="small">Edit</Button>
                          <Link style={{ display: item.active ? 'block' : 'none', height: 38 }} target="_blank" to='/demo' ><Button onClick={() => { localStorage.setItem('sample-training', JSON.stringify(item)); localStorage.setItem('seek-option', JSON.stringify('true')) }}>Demo</Button></Link>
                          <Button onClick={() => (item.type === 'module' ? DeleteModuleTraining(item.courseData, item.id) : DeleteTraining(item.id, item.videoLink))} sx={{ float: 'inline-end', height: 38 }}><DeleteIcon htmlColor='tomato' /></Button>
                          </div>
                          </TableCell>
                        </TableRow>
                   
                        )
          }
                        
                   
                     })}


        </TableBody>
      </Table>
    </TableContainer>

</Stack>
<DeleteTrainingModal Open={openDeleteModal}/>

          </Box>
        </Box>
      </Box>
    );
  }
};

TrainingBuilder.propTypes = {
  window: PropTypes.func,
};

export default TrainingBuilder;

import React, { useEffect, useState } from "react";
import Menu from '@mui/material/Menu';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ConstructionIcon from '@mui/icons-material/Construction';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PeopleIcon from '@mui/icons-material/People';
import { DashboardNavButtons } from "../components/DashboardNavButtons";
import logo from '../assets/css/images/icon6.png'
import { VideoTrainingLibrary } from "./VideoTrainingLibrary";
import { TrainingManager } from "./TrainingManager";
import { AudioTrainingLibrary } from "./AudioTrainingLibrary";
import { Learners } from "./Learners";
import Button from "@mui/material/Button";
import NotificationListItem from "../components/NotificationListItem";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useLocation} from 'react-router-dom';
import axios from "axios";
import { API } from "../controllers/API";
import { formatDate } from "../classes/global-functions";
import { useNavigate } from "react-router-dom";
import { Bosscalls } from "./BossCalls/Bosscalls";
import { Profile } from "./Profile";
import copy_logo from '../assets/css/images/copy-logo.png'
import { CertficateGenerator } from "./CertificateGenerator";
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { Tutorials } from "./Tutorials";
import { Dashboard } from "./Dashboard";
import { CircularProgress } from "@mui/material";
function Copyright(props) {
  return (


    <Typography variant="body2" color="text.secondary" align="center" {...props}>
          <div style={{textAlign:'center'}}>
    <img src={copy_logo} width="100px" height="auto"/>
    </div>
      {'Copyright © '}
      <Link target='_blank' color="inherit" href="https://employeetrainingtoolkit.com/">
        Employee Training Tool-Kit
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme();

export const Home = ()=> {
  const navigate = useNavigate();
  const data = JSON.parse(sessionStorage.getItem('profile-data'))
  const token = JSON.parse(sessionStorage.getItem('token'))
  const location = useLocation()
  const [mainData, setMainData] = useState([])
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(true);
  const [pageTitle, setPageTitle] = useState('Dashboard');
  const [view, setView] = useState(<Dashboard/>)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notificationList, setNotificationList] = useState([])
  const [update, setUpdate] = useState(true)
  const openNotifications = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const deleteNotification = async (a)=>{

 const resp = await axios.post(API +'delete-notification', {userId:data.id, notificationId:a})

 if(resp.data === 'error'){
  window.alert('Something went wrong, please try again.')
 } else {
  console.log(resp.data)
  setUpdate(!update)

 }
  }

  const deleteAllNotifications = async (a)=>{
    const resp = await axios.post(API +'delete-all-notification', {userId:data.id})

    if(resp.data === 'error'){
     window.alert('Something went wrong, please try again.')
    } else {
      setUpdate(!update)
    }
   }
//GET MAIN DATA
   useEffect(()=>{
    async function fetchData() {
   const response = await axios.post(API + 'get-data',{id:data.id})
   
   
          console.log(response.data)
          setMainData(response.data)
          setNotificationList(response.data.notifications)
          setLoading(false)
    }
    fetchData()
  },[])


  useEffect(()=>{
    async function fetchData() {
   const response = await axios.post(API + 'get-data',{id:data.id})
   
   
          console.log(response.data)
          setMainData(response.data)
          setNotificationList(response.data.notifications)
          setLoading(false)
    }
    fetchData()
  },[update])

   useEffect(()=>{
    console.log(location.state)
    if(location.state === 'learners'){
      setView(<Learners/>)
      setPageTitle('LEARNERS')
      location.state = ''
    }

    if(location.state === 'TM'){
      setView(<TrainingManager/>)
      setPageTitle('TRAINING MANAGER')
      location.state = ''
    }

    if(location.state === 'BC'){
      setView(<Bosscalls/>)
      setPageTitle('Boss Calls')
      location.state = ''
    }

    if(location.state === 'video'){
      setView(<VideoTrainingLibrary/>)
      setPageTitle('VIDEO TRAINING LIBRARY')
      location.state = ''
    }

    if(location.state === 'audio'){
      setView(<AudioTrainingLibrary/>)
      setPageTitle('AUDIO TRAINING LIBRARY')
      location.state = ''
    }

   },[])
 
   if(loading === false){
    
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex'}}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed,
              backgroundImage:'linear-gradient(#37b6e8, #528EE0)'
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="button"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1, fontSize:16  }}
            >
             <b> {pageTitle}</b>
            </Typography>
            <a style={{color:'white'}} href="https://employeetrainingtoolkit.com/demos" target="_blank">
            <IconButton
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            color="inherit">
                <HelpOutlineIcon />
            </IconButton>
            </a>
            <IconButton
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            color="inherit">
              <Badge badgeContent={notificationList.length} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            <IconButton
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={()=>{setView(<Profile/>); setPageTitle('PROFILE')}}
            color="inherit">
                <AccountBoxIcon />
            </IconButton>
            <Menu
       
        anchorEl={anchorEl}
        open={openNotifications}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{maxHeight:'500px', overflowY:'scroll'}}
      >
        <Button sx={{float:'right', display: (notificationList.length < 0) ? 'none':'block',fontSize:12, margin:0, padding:0}} onClick={()=>deleteAllNotifications()} >Clear</Button>
        {(notificationList.length > 0)? notificationList.map((i)=>{
          
           return (
           <div>
           <NotificationListItem
           Name={<div><Typography variant="overline">{i.name}</Typography><Typography sx={{float:'right'}} variant="overline" >{formatDate(i.dateCompleted)}</Typography></div>}
           Message={`Completed Training: ${i.courseName}.`}
           ClickDelete={()=>deleteNotification(i.id)}
           />
           <hr style={{margin:-1}}></hr>
           </div>
           )
        }): <Typography sx={{width:'300px', textAlign:'center'}}>No current notifications.</Typography>}
       
      </Menu>
          </Toolbar>
        </AppBar>
        <Drawer
        PaperProps={{
          sx:{
            backgroundImage:'linear-gradient(#37b6e8, #528EE0)'
          }
        }}
        variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              border:'none',
              px: [1],
            }}
          >
             <div style={{textAlign:'center', padding:'10px', right:0}}>
          <img src={logo} style={{marginRight:open?'30px':0, marginTop:open?2:4}} width={open? 150:50}/>
          </div>
            <IconButton sx={{position: 'absolute', top:'10px'}} onClick={toggleDrawer}>
              <ChevronLeftIcon sx={{color:"white" }}/>
            </IconButton>
          
           
          </Toolbar>
        
          <Divider />
          <List sx={{border:'none', paddingTop:0}} component="nav">
         
            {/* <Divider sx={{ marginBottom:'5px' }} /> */}
           
            <DashboardNavButtons Click={()=>{setView(<Dashboard/>); setPageTitle('Dashboard')}} StyleOpen={open} Title="Dashboard" Icon={<DashboardIcon sx={{color:'white'}}/>} />
            <DashboardNavButtons Click={()=>{setView(<Learners/>); setPageTitle('Learners')}} StyleOpen={open} Title="Learners" Icon={<PeopleIcon sx={{color:'white'}}/>} />
            <DashboardNavButtons Click={()=>{setView(<TrainingManager/>); setPageTitle('Training Manager')}} StyleOpen={open} Title="Training Manager" Icon={<AutoFixHighIcon sx={{color:'white'}}/>} />
            <Box sx={{display: token.memberType !== 'basic' ? 'block':'none'}}>
            <DashboardNavButtons 
            Click={()=>{navigate('/training-builder')}} 
            StyleOpen={open} Title={`Training Builder`} Icon={<ConstructionIcon sx={{color:'white'}}/>} TooltipTitle="Build and upload your own trainings" />
            <DashboardNavButtons Click={()=>{setView(<VideoTrainingLibrary/>); setPageTitle('Video Training Library')}} StyleOpen={open} Title="Video Training Library" Icon={<LibraryBooksIcon sx={{color:'white'}}/>} />
            <DashboardNavButtons Click={()=>{setView(<AudioTrainingLibrary/>); setPageTitle('Audio Training Library')}} StyleOpen={open} Title="Audio Training Library" Icon={<AudioFileIcon sx={{color:'white'}}/>} />
            
            </Box>
      
            <Box sx={{display: token.diy ? 'block':'none'}}>
            <DashboardNavButtons Click={()=>{setView(<CertficateGenerator/>); setPageTitle('Certificate Generator')}} StyleOpen={open} Title="Certificate Generator" Icon={<WorkspacePremiumIcon sx={{color:'white'}}/>} />

            </Box>
            <Box sx={{display: token.memberType === 'pro' || token.memberType === 'enterprise' ? 'block':'none'}}>
            
            </Box>
            {/* <DashboardNavButtons Click={()=>{setView(<Bosscalls/>); setPageTitle('Boss Calls')}}  StyleOpen={open} Title="Boss Calls Live Training"  Icon={<img width={'25px'} height={'auto'} src={bc_logo} />} /> */}

            
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: '#F0F0F1',
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
           {view}
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
} else {
  <Box sx={{textAlign:'center', marginTop:20}}>
    <CircularProgress/>
    <Typography><b>Loading</b></Typography>
  </Box>
}

}

const styles = {
  paper: {
    background: "blue"
  }
}
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import Link from "@mui/material/Link";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { formatDate } from "../classes/global-functions";
import { API } from "../controllers/API";
import DeleteIcon from '@mui/icons-material/Delete';
import { InputGroup } from "react-bootstrap";
import CancelAccountModal from "../components/CancelAccountModal";





export const Profile = ()=>{
    const data = JSON.parse(sessionStorage.getItem('profile-data'))
    const token = JSON.parse(sessionStorage.getItem('token'))
    const [userData, setUserData] = useState({name:'', dateSubscribed:'', membershipType:'', email:'', learnerCategories:[]})
    const [update,setUpdate]= useState(false);
    const [renewalDate, setRenewalDate] = useState('')
    const [presenterName, setPresenterName] = useState('');
    const [presenterTitle, setPresenterTitle] = useState('');
    const [presenterNameEditButton, setPresenterNameEditButton] = useState('EDIT');
    const [presenterTitleEditButton, setPresenterTitleEditButton] = useState('EDIT');
    const [presenterNameDisabled, setPresenterNameDisabled] = useState(true);
    const [presenterTitleDisabled, setPresenterTitleDisabled] = useState(true);
    
    const [replyTo, setReplyTo] = useState('');
    const [replyToEditButton, setReplyToEditButton] = useState('EDIT');
    const [replyToDisabled, setReplyToDisabled] = useState(true);

    const [showCancelModal, setShowCancelModal] = useState(false); // State to control modal visibility

const fetchData = async ()=>{
  const response = await axios.post(`${API}get-data`, {id:data.id})

 setUserData({
    name:response.data.name,
    dateSubscribed:response.data.dateCreated,
    membershipType: response.data.membershipType,
    email:response.data.email,
    learnerCategories: response.data.learnerCategories,
    certificateSettings: response.data.certificateSettings,
    replyToEmail: response.data.replyToEmail
 })
 let date =new Date(response.data.dateCreated)
  let renewDate = date.setFullYear(date.getFullYear() + 1);
setRenewalDate(formatDate(renewDate))

  console.log(response.data)
}

useEffect(()=>{

   fetchData() 
},[update])


const deleteCategory = async (a) => {
  const response = await axios.post(`${API}delete-learner-category`, {id:data.id, catId:a})

if(response.data == 'error'){
  window.alert('Something went wrong. Please try agian.')
} else {
  window.alert('Category has been removed. This will not change any of the learners. It simply just removed the category from the preloaded list when adding learners.')
  setUpdate(!update)
}

}
    return (
        <Box>
            <Typography sx={{fontSize:22}} variant="button"><b>Profile</b></Typography>
            <Container>
      <Row>
        <Col><Form.Group>
             <Form.Label><Typography variant="button" sx={{fontSize:16}}><b>NAME</b></Typography></Form.Label>
             <Form.Control value={userData.name} type="text" />
             </Form.Group></Col>
        <Col>  <Form.Group>
             <Form.Label><Typography variant="button" sx={{fontSize:16}}><b>Date Subscribed</b></Typography></Form.Label>
             <Form.Control value={formatDate(userData.dateSubscribed)} type="text" />
             <Typography sx={{color:'grey', fontSize:'12px'}} variant="button">Renews {renewalDate}</Typography>
             </Form.Group>
             </Col>
      </Row>
      <Row className="mt-2">
      <Col >
      <Form.Group>
             <Form.Label><Typography variant="button" sx={{fontSize:16}}><b>EMAIL</b></Typography></Form.Label>
             <Form.Control value={userData.email} type="text" />
             </Form.Group></Col>
        <Col>  <Form.Group>
             <Form.Label><Typography variant="button" sx={{fontSize:16}}><b>Membership Type</b></Typography></Form.Label>
             <Form.Control value={userData.membershipType} type="text" />
             <Link target="_blank" sx={{float:'right', paddingRight:1}} href="https://employeetrainingtoolkit.com/#pricing"><Typography variant="button" sx={{fontSize:'12px',color:'blue'}}>Upgrade</Typography></Link>
             <Link onClick={() => setShowCancelModal(true)} sx={{ float: 'right', paddingRight: 1 }} href="#">
                <Typography variant="button" sx={{ fontSize: '12px', color: 'blue' }}>Cancel Account</Typography>
              </Link>
             </Form.Group>
             </Col>
      </Row>
      <hr></hr>
      <Typography sx={{fontSize:20}} variant="button"><b>Certificate Settings</b></Typography>

      <Row className="mt-2">
      <Col >
      <Form.Group>
        
             <Form.Label><Typography variant="button" sx={{fontSize:16}}><b>Presenter Name</b></Typography></Form.Label>
             <InputGroup className="mb-3">
             <Form.Control value={presenterName} onChange={(e)=> setPresenterName(e.target.value)} disabled={presenterNameDisabled} placeholder={userData.certificateSettings?.name === 'NA'? `Defaults to '${userData.name}'`: userData.certificateSettings?.name} type="text" />
        <Button onClick={()=> {
        if(presenterNameDisabled === true){
          setPresenterNameDisabled(!presenterNameDisabled)
          setPresenterNameEditButton('SAVE')
        } else {
            axios.post(API+'set-presenter-name', {id:data.id, name:presenterName})
            .then((resp)=>{
              if(resp.data === 'error'){
                window.alert('Something went wrong. Please Try again')
              } else {
                window.alert('Updated!')
                setUpdate(!update)
                setPresenterNameDisabled(true)
                setPresenterName('')
              }
            })
        }
          
        
        }} variant="contained" id="button-addon2">
          {presenterNameEditButton}
        </Button>
      </InputGroup>
             </Form.Group></Col>
        <Col>  <Form.Group>
             <Form.Label><Typography variant="button" sx={{fontSize:16}}><b>Presenter Title</b></Typography></Form.Label>
             <InputGroup className="mb-3">
             <Form.Control value={presenterTitle} onChange={(e)=> setPresenterTitle(e.target.value)} disabled={presenterTitleDisabled} placeholder={userData.certificateSettings?.title === 'NA'? `Defaults to 'Presenter'`: userData.certificateSettings?.title} type="text" />
             <Button onClick={()=> {
        if(presenterTitleDisabled === true){
          setPresenterTitleDisabled(!presenterTitleDisabled)
          setPresenterTitleEditButton('SAVE')
        } else {
            axios.post(API+'set-presenter-title', {id:data.id, title:presenterTitle})
            .then((resp)=>{
              if(resp.data === 'error'){
                window.alert('Something went wrong. Please Try again')
              } else {
                window.alert('Updated!')
                setUpdate(!update)
                setPresenterTitleDisabled(true)
                setPresenterTitle('')
              }
            })
        }
          
        
        }} variant="contained" id="button-addon2">
          {presenterTitleEditButton}
        </Button>
      </InputGroup>
             </Form.Group>
             </Col>
      </Row>

      
<hr></hr>
      <Typography sx={{fontSize:20}} variant="button"><b>Email Settings</b></Typography>

<Row className="mt-2">

  <Col> 
   <Form.Group>
       <Form.Label><Typography variant="button" sx={{fontSize:16}}><b>Reply-To Email</b></Typography></Form.Label>
       <Typography className="mb-3" variant="body2">If the learner has a question and you do not want the learner to reply to the email you setup with this account, you can change the reply-to email address here.</Typography>
       <InputGroup className="mb-3">
       <Form.Control value={replyTo} onChange={(e)=> setReplyTo(e.target.value)} disabled={replyToDisabled} placeholder={userData.replyToEmail} type="text" />
       <Button onClick={()=> {
  if(replyToDisabled === true){
    setReplyToDisabled(!replyToDisabled)
    setReplyToEditButton('SAVE')
  } else {
      axios.post(API+'set-reply-to-email', {id:data.id, email:replyTo})
      .then((resp)=>{
        if(resp.data === 'error'){
          window.alert('Something went wrong. Please Try again')
        } else {
          setReplyToEditButton('EDIT')
          window.alert('Updated!')
          setUpdate(!update)
          setReplyToDisabled(true)
          setReplyTo('')
        }
      })
  }
    
  
  }} variant="contained" id="button-addon2">
    {replyToEditButton}
  </Button>
</InputGroup>
       </Form.Group>
       </Col>
</Row>
    </Container>
    <hr></hr>

    
<Box sx={{margin:3, display: token.memberType === 'diy' ? 'none': 'block'}}> 
<Typography sx={{fontSize:18}} variant="button"><b>Edit Learner Groups</b></Typography>
<Typography  variant="subtitle2">NOTE: Delete a group will not remove that from each particular learner. Just from the list of learner groups when adding new learners.</Typography>
<Paper sx={{ padding:1, maxHeight:'300px', overflowY:'scroll'}}>
{
   userData.learnerCategories.map((i)=>{

    return (
        <Box key={i.id}>
             <ListItem sx={{fontSize:'16px'}}  divider>
      
        <ListItemText primary={<span style={{fontSize:'14px', color:'black'}}><b> <Typography  variant="button">{i.cat}</Typography></b></span>}/>
        <ListItemIcon >
        <IconButton onClick={()=>{deleteCategory(i.id)}} ><DeleteIcon htmlColor="tomato"/></IconButton>
        </ListItemIcon>
      </ListItem>
      
        </Box>
    )
   })
}
</Paper>

</Box> 
<div className="m-3" style={{textAlign:'end'}}>
  <Button onClick={()=>{
    sessionStorage.clear()
    window.location.reload()
  }} variant="contained" sx={{fontWeight:'bold'}} >Logout</Button>
</div> 
<CancelAccountModal
        show={showCancelModal}
        handleClose={() => setShowCancelModal(false)}
        userData={userData}
        setUpdate={setUpdate}
      />

         
        </Box>
    )
}
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import stockImg from '../../assets/css/images/stock_custom_card_img.png'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';



export const UploadCoverImage = (props) =>{
const [loading, setLoading] = useState(false)

  


if(props.CoverImage != stockImg){
    let name = props.CoverImage.name ? props.CoverImage.name : props.CoverImage.substring(props.CoverImage.lastIndexOf('/')+1).replace(/%20/g, " ")
    return(
        <Box sx={{display:'flex'}}>
      <Typography>{name }</Typography><Button onClick={()=>props.SetCoverImage(stockImg)} variant="contained" sx={{padding:.5, marginLeft:5}}>Change</Button>
      </Box>
    )
   
} else {
    
    return (
        <Box>
         <InputGroup>
        <Form.Control 
        accept="image/*"
        onChange={(e)=>{
            if(e.target.files[0].size >  3145728){
                window.alert('File size is too big. Size should be under 3mb.')
                e.target.value = '';
              } else {
            console.log()
            props.SetCoverFile(e.target.files[0]);
            const objectUrl = URL.createObjectURL(e.target.files[0])
            props.SetCoverImage(objectUrl)
            props.SetFileSize(e.target.files[0].size); 
            console.log(props.FileSize)
              }
            }} type='file'/>
       </InputGroup>   
        <Typography variant='caption'><b>Recommended Size: 500px x 350px</b></Typography>
        </Box>
        
    )


}
}
import React, { useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import axios from "axios";
import { API } from "../controllers/API";

const CancelAccountModal = ({ show, handleClose, userData, setUpdate }) => {
  const [reason, setReason] = useState('');
  const [comments, setComments] = useState('');
const [sending, setSending] = useState(false)
  const handleSubmit = async () => {
    setSending(true)
    const response = await axios.post(`${API}cancel-account`, {
      id: userData.id,
      email: userData.email,
      name: userData.name,
      reason,
      comments,
    });

    if (response.data === 'success') {
        setSending(false)

      window.alert('Your account cancellation request has been submitted.');
      setUpdate(prev => !prev);
      setComments('')
      setReason('')
      handleClose();
      
    } else {
        setSending(false)
       setComments('')
      window.alert('Something went wrong. Please try again.');
    }
  };

  return (
    <Modal style={{marginTop:45}} show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Cancel Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Reason for Cancellation</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              placeholder="Please provide the reason for cancelling your account."
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Additional Comments</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              placeholder="Any additional comments?"
            />
          </Form.Group>
          <Form.Text className="text-muted">
            By cancelling the account, all training data will be deleted permanently after 90 days.
          </Form.Text>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="danger" onClick={handleSubmit}>
          Submit <Spinner style={{display: sending ? 'inline-block':'none'}} size="sm"/>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelAccountModal;

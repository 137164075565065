import React, {useState, useEffect} from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { borderRadius, styled } from '@mui/system';

const BoxContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  boxShadow: '0px 3px 3px -2px #38B5E825, 0px 3px 4px 0px #38B5E825, 0px 1px 8px 0px #38B5E825',
  borderRadius:10
}));

export const Metrics = (props) => {

        const [numbers, setNumbers] = useState([
          { label: 'Total Trainings', value: props.TotalTrainings, count: 0 },
          { label: 'Active Trainings', value: props.ActiveTrainings, count: 0 },
          { label: 'Trainings Completed', value: props.TotalCompletedTrainings, count: 0 },
          { label: 'Total Learners', value: props.TotalLearners, count: 0 },
        ]);
      
        useEffect(() => {
          const interval = setInterval(() => {
            const updatedNumbers = numbers.map((num) => {
              if (num.count < num.value) {
                return { ...num, count: num.count + 1 };
              }
              return num;
            });
            setNumbers(updatedNumbers);
          }, 15); // Adjust the interval duration as needed
      
          return () => clearInterval(interval);
        }, [numbers]);

  return (
    <Grid container spacing={1} sx={{ marginTop: 2, marginBottom:2 }}>
       {numbers.map((num, index) => (
        <Grid key={index} item xs={12} sm={6} md={3}>
         
          <BoxContainer elevation={3}>
            <Typography  variant="button"><b>{num.label}</b></Typography>
            <Typography variant="h4">{num.count}</Typography>
          </BoxContainer>
        </Grid>
      ))}
    </Grid>
  );
};


import {Box, Button} from '@mui/material';
import Form from 'react-bootstrap/Form';
import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Typography from '@mui/material/Typography';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



export const AddSingleLearnerForm = (props) =>{
  const [showCatInput, setShowCatInput] = useState(false)
    return (
        <Box style={{textAlign:'start', display: props.Display}}>
        <Form.Label><Typography sx={{fontSize:'18px'}} variant='button'>Add Learner</Typography></Form.Label>
        <Row className="mb-3">
        <Form.Group as={Col} className="mb-1" controlId="formBasicEmail">
          <Form.Label><Typography variant='button'><b>First Name</b></Typography></Form.Label>
          <Form.Control value={props.PersonsFirstNameValue} onChange={props.PersonsFirstName} type="text" placeholder="First Name" />
          
        </Form.Group>

        <Form.Group as={Col} className="mb-1" controlId="formBasicEmail">
          <Form.Label><Typography variant='button'><b>Last Name</b></Typography></Form.Label>
          <Form.Control value={props.PersonsLastNameValue} onChange={props.PersonsLastName} type="text" placeholder="Last Name" />
          
        </Form.Group>
       </Row>
       <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label><b>Email</b></Form.Label>
          <Form.Control value={props.PersonsEmailValue} onChange={props.PersonsEmail}  type="text" placeholder="Email" />
          <Form.Text className="text-muted">
            This email will be used to send trainings to.
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label><b>How would you like to categorize this indiviual?</b></Form.Label>

        <InputGroup className="mb-3">
        <Form.Select onChange={props.ChangeCatSelect} value={props.CatSelectValue}>
            <option>---</option>
            {props.Categories}
            </Form.Select>
        <Button onClick={props.OnclickShowCatInput} variant="contained" id="button-addon1">
         <b>Add New Group</b>
        </Button>
       
      </InputGroup>
      <InputGroup style={{display: props.ShowCatInput }} className="mb-3">
        <Form.Control
          placeholder="Type category"
          value={props.CategoryChangeValue}
          onChange={props.OnChangeCategoryInput}
        />
        <Button onClick={props.AddCategoryClick} variant="contained"  id="button-addon2">
          Add
        </Button>
        <Button sx={{backgroundColor:'tomato'}} onClick={props.CloseCategoryClick} variant="contained"  id="button-addon2">
          Close
        </Button>
      </InputGroup>

        </Form.Group>
        
        <Button onClick={props.OnSubmit} variant="contained" type="submit">
        <b>Add Learner</b>
        </Button>
      </Box>
    )
}
import { Box } from "@mui/system";
import React, { useState, useRef } from "react";
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { API } from "../../controllers/API";
import csv from '../../assets/ETT.csv'

export const AddMultipleLearners = (props)=>{
   const fileInput = useRef(null)
    const[ result, setResult] = useState([])
    const[ displayAddBtn, setDisplayAddBtn] = useState(false)
    const data = JSON.parse(sessionStorage.getItem('profile-data'))
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }

    const addEmployees = async ()=>{
      let resp = await  axios.post(API+'add-multiple-employees',{id:data.id, array:result})
      console.log(resp.data)
    }
   



    return (
    <Box sx={{display:props.Display}}>
        {/* <Form.Label>Upload a CSV file using <a download={true} name="Employees-sample.csv" href={require("../documents/Employees-sample.csv")}><b>this</b></a> template.</Form.Label> */}
      <Form.Group controlId="formFile" className="mb-3">
     
      <Typography sx={{marginBottom:3}} variant="button">Click <a style={{color:'blue', textDecoration:'underline'}} download={'Learner-Upload-Sample.csv'} href={csv}><b>HERE</b></a> to download the learner upload sample csv.</Typography>
      <br></br>
     
        <Typography sx={{marginBottom:3}} variant="button"><b>DO NOT CHANGE THE HEADINGS ON THE DOCUMENT OR THE FILES WILL NOT UPLOAD PROPERLY.</b></Typography>
        <br></br>

        <br></br>
        
        <Form.Control
        ref={fileInput}
         accept=".csv" 
         type="file"
         onChange={props.OnFileChange}
         />
      </Form.Group>

      <Table striped bordered hover>
      <thead>
        <tr>
        
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email</th>
          <th>Group</th>
        </tr>
      </thead>
      <tbody>
        {props.TableBody.map(i=>{
      return (
          <tr key={i.id}>
      
          <td>{i.firstName}</td>
          <td>{i.lastName}</td>
  
          <td>{i.email}</td>
          <td>{i.group}</td>
          </tr>
      )
          })}
    

       
     
      </tbody>
    </Table>
    <Typography sx={{display:props.AddDisplayBtn}} variant="button"><b>REVIEW THE TABLE ABOVE TO MAKE SURE EVERYTHING LOOKS Like it should.</b></Typography>
    <Button variant="contained" onClick={props.AddEmployees} sx={{display:props.AddDisplayBtn, float:'right' ,padding:'10px', margin:'10px'}}><b>Add Learners</b></Button>
    <Button onClick={()=>{props.ClickClear(); fileInput.current.value = '' }} variant="contained" sx={{display:props.AddDisplayBtn, float:'right' ,padding:'10px', margin:'10px', backgroundColor:"red"}}><b>Clear Form</b></Button>
    </Box>
    )

}
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import uuid from "react-uuid";
import axios from "axios";
import { API } from "../../controllers/API";
import ModuleListItem from "../../components/module-components/ModuleListItem";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '../../assets/css/training-builder.css'
import ProgressModal from "../../components/training-builder-components/ProgressModal";



const customConfiguration = {
  toolbar: {
    items: [
      'heading',
      '|',
      'bold',
      'italic',
      'link',
      'bulletedList',
      'numberedList',
      'blockQuote',
      'undo',
      'redo',
    ],
  },
};



export const ModuleBuilder = (props)=>{
  const date = new Date()
    const [trainingId, setTrainingId] = useState(sessionStorage.getItem('trainingId'))
    const data = JSON.parse(sessionStorage.getItem('profile-data'))
    const courseName =  sessionStorage.getItem('courseName')
    const [shouldRunEffect, setShouldRunEffect] = useState(true);
    const modList = useRef(null);
    // Module Variables
    const [modulesUpdate, setModulesUpdate] = useState(true)

const [editorData, setEditorData] = useState('');
const [progressArray, setProgressArray] = useState([]);
const [showProgress, setShowProgress] = useState(false);
const [trainingData, setTrainingData] = useState()
const [moduleNumber, setModuleNumber] = useState(1)
const [moduleType, setModuleType] = useState('text')
const [progress,setProgress] = useState(0)
const [openProgress,setOpenProgress] = useState(false)
function capitalizeFirstLetter(str) {
    // Check if the input is not an empty string
    if (str.length === 0) return str;
  
    // Capitalize the first letter and concatenate the rest of the string
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const addBtn = useRef(null);
  const selectRef = useRef(null);
  const fileInputRef = useRef(null);
  const [moduleTitle, setModuleTitle] = useState('')
const [update, setUpdate] = useState(true)
const [checkComplete, setCheckComplete] = useState(true)
const [showFileInput, setShowFileInput] = useState(true)
const [selectedFile, setSelectedFile] = useState(null);
  const [modules, setModules] = useState([{
    id:uuid(),
    moduleTitle:'',
    type: 'text',
    videoLink: 'NA',
    audioLink: 'NA',
    file:'',
    content:''
  }]);

  useEffect(()=>{
    modList.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
   },[modulesUpdate])
  useEffect(()=>{

    async function fetchData() {
      const response = await axios.post(API + 'get-data',{id:data.id})
      console.log(trainingId)
      
             console.log(response.data)

             setTrainingData(response.data)
           
             console.log(response.data)
       }
       fetchData()
  },[update])

  const checkCompletelion = (a)=>{
    setUpdate(!update)
    setProgress(progressArray.length/modules.length * 100)
    console.log(trainingData)
    setTimeout(()=>{
      if(a === modules.length){
        setOpenProgress(false);
        alert('Uploaded!')
        

        axios.post(API + 'get-data',{id:data.id})
    .then((response) => {
      if(response === 'error'){
        alert('Something went wrong. Try Again.')
      } else {
        let userData = response.data;
        console.log(userData)
      let filter =  userData.customTrainings.filter(i=>{
        return i.id === sessionStorage.getItem('trainingId');
      })

      filter[0].courseData = modules;
      filter[0].dateUpdated = date;
      axios.post(API+'update-training',{id:data.id, training: filter[0]})
      .then((resp)=>{
        if(resp === 'error'){
          alert('Something went wrong. Try Again.')
        } else {
          props.NextStep()
        }
        

      })
      }
      
    })

        

        
      }
    },500)
  
  }

  useEffect(()=>{
   if(shouldRunEffect){
    
    setModules(prevMods =>
      prevMods.map(item =>
      item.id === modules[currentModule].id ? { ...item, moduleTitle } : item
      )
    )


   }
    console.log(modules)
  },[moduleTitle])


  useEffect(()=>{
    if(shouldRunEffect){
    setModules(prevMods =>
      prevMods.map(item =>
      item.id === modules[currentModule].id ? { ...item, content: editorData } : item
      )
    )
      }
    console.log(modules)
  },[editorData])


  useEffect(()=>{

    if(shouldRunEffect){
   setSelectedFile(null)
   handleClearFile()
   if(moduleType === 'audio'){
    setModules(prevMods =>
      prevMods.map(item =>
      item.id === modules[currentModule].id ? { ...item,  videoLink: 'NA' } : item
      )
    )
   }

   if(moduleType === 'video'){
    setModules(prevMods =>
      prevMods.map(item =>
      item.id === modules[currentModule].id ? { ...item,  audioLink: 'NA' } : item
      )
    )
   }
    setModules(prevMods =>
      prevMods.map(item =>
      item.id === modules[currentModule].id ? { ...item, type: moduleType } : item
      )
    )
      }
    console.log(modules)
  },[moduleType])


  useEffect(()=>{
    if(shouldRunEffect){
if(moduleType === 'audio'){
  setModules(prevMods =>
    prevMods.map(item =>
    item.id === modules[currentModule].id ? { ...item, audioLink: selectedFile, videoLink: 'NA' } : item
    )
  )
}


if(moduleType === 'video'){
  setModules(prevMods =>
    prevMods.map(item =>
    item.id === modules[currentModule].id ? { ...item, videoLink: selectedFile, audioLink: 'NA' } : item
    )
  )
}
    }
    
    console.log(modules)
  },[selectedFile])

  const [editingModule, setEditingModule] = useState(false)
 const [currentModule, setCurrentModule] = useState(0)


 const handleClearFile = () => {
  // Clear the file input value by setting it to an empty string
  if (fileInputRef.current) {
    fileInputRef.current.value = '';
  }
};

function isFile(variable) {
  return variable instanceof File;
}

const handleRemoveItem = (itemId) => {
  // Filter the dataArray to create a new array without the item with the specified ID
  if(modules.length > 1){
    
  const updatedArray = modules.filter(item => item.id !== itemId);

  // Set the new array as the updated state
  setModules(updatedArray);
  setCurrentModule(0)
  setShouldRunEffect(false)
  setCurrentModule(0)
  setModuleTitle(modules[0].moduleTitle)
  setModuleType(modules[0].type)
  setEditorData(modules[0].content)
  setSelectedFile(modules[0].moduleType === 'audio'? modules[0].audioLink : modules[0].videoLink )
  if(modules[0].audioLink !== 'NA' || modules[0].videoLink !== 'NA'){
    setShowFileInput(false)
  } else {
    setShowFileInput(true)
  }
} else {
  alert('You have to have at least one module in the course.')
}
};

const updateProgress = ()=>{
let progressArr = []
  modules.forEach((i,index)=>{
    console.log(i.audioLink?.name)
    if(i.type === 'audio' && isFile(i.audioLink)){
     
      const formData = new FormData();
  
      formData.append('file', i.audioLink);
      formData.append('folder', data.email)
      formData.append('courseId', sessionStorage.getItem('trainingId'))
      formData.append('courseName', `${courseName} Module ${index + 1} - ${i.moduleTitle}`)
      axios.post(API+'upload-audio',formData)
        .then((response) => {
          // Video uploaded successfully
          
        console.log(response.data)
        modules[index].audioLink = response.data.fileUrl
        modules[index].videoLink = "NA"
        progressArray.push('i')
      
        console.log('Prog array: ' +progressArray)
        
        checkCompletelion(progressArray.length)
        })
        .catch((error) => {
          // Error handling
  
     console.log(error)
         
          alert('Something went wrong try again')
       
  
        });
    } 
    

console.log(i.videoLink?.name)
    if( i.type === 'video' && isFile(i.videoLink)){

      const formData = new FormData();
      formData.append('video', i.videoLink);
      formData.append('folder', data.email)
      formData.append('title', `${courseName} Module ${index + 1} - ${i.moduleTitle}`)
        console.log(i)
        axios.post(API+'upload-video',formData)
    .then((response) => {
      // Video uploaded successfully
    
     
      const videoUri = response.data.link;
      modules[index].videoLink = videoUri;
      modules[index].audioLink = "NA"
      console.log(videoUri);
     


      console.log(modules[index].videoLink)
      progressArray.push('i')
    
      checkCompletelion(progressArray.length)
    })
    .catch((error) => {
      // Error handling
      console.log(error)
     alert('Something went wrong. Please try again.')
    });

    } 
    if( i.type === 'text'){
     
      progressArray.push('i')
    
 
      checkCompletelion(progressArray.length)
    }

    if( i.type === 'video' && i.videoLink === 'NA'){
     
      progressArray.push('i')
    
 
      checkCompletelion(progressArray.length)
    }


    if( i.type === 'audio' && i.audioLink === 'NA'){
     
      progressArray.push('i')
    
 
      checkCompletelion(progressArray.length)
    }

    

  })

  
}



  

    return (
        <Box>
            <Typography variant="button" sx={{fontSize:24}}><b>MODULE BUILDER</b></Typography>

        <Grid container>
            <Grid  className="p-2" xs={8} item >
             <Paper  className="p-3">
             <Typography variant="button" sx={{fontSize:20}}><b>Module {currentModule + 1}</b></Typography>
<hr></hr>
                <Grid container>
                    <Grid sx={{alignSelf:'center'}} item xs={3}>
                     <Typography variant='subtitle2'><b>Module Type:</b></Typography>
                    </Grid>

                    <Grid item xs={9}>
                     <Form.Select ref={selectRef} value={moduleType} onChange={(e)=>{setShouldRunEffect(true); setShowFileInput(true); setModuleType(e.target.value)}} name="type" >
                       <option value="text">Text</option>
                       <option value="video">Video</option>
                       <option value="audio">Audio</option>
                     </Form.Select>
                    </Grid>

                    <Grid className="mt-4" container>
            <Grid sx={{alignSelf:'top'}} item xs={3}>
             <Typography variant='subtitle2'><b>Module Title:</b></Typography>
            </Grid>

            <Grid item xs={9}>
            <Form.Control 
             type="text"
             name="moduleTitle" // Make sure this matches the property name in initialModule
             onChange={(e)=>{setShouldRunEffect(true); handleClearFile();  setModuleTitle(e.target.value)}}
             value={moduleTitle}
            />
            </Grid>

         
        </Grid>
                 
                </Grid>
                
          
              
                 <Grid className="mt-4" container>
                  <Grid sx={{alignSelf:'top'}} item xs={3}>
                  <Typography sx={{display: moduleType === 'text' ? 'none': showFileInput ? 'block':'none' }} variant='subtitle2'><b>Media File Upload:</b></Typography>
            </Grid>
             
            <Grid item xs={9}>
                <Form.Control 
                type="file"
                name="fileInput"
                ref={fileInputRef}
                accept={moduleType === 'video'? "video/*": "audio/*"}
                style={{display: moduleType === 'text' ? 'none': showFileInput ? 'block':'none' }}
                onChange={(e)=> {
                  console.log(e.target.files[0].size)
                  console.log(moduleType)
                  if(e.target.files[0].size > 250000000 && moduleType === 'video' || e.target.files[0].size > 50000000 && moduleType === 'audio' ){
                    window.alert(`File size is too big. Size should be under ${moduleType === 'video' ? '250mb':'50mb'}`)
                    e.target.value = '';
                  } else {
                  setShouldRunEffect(true); 
                  const file = e.target.files[0];
                  setSelectedFile(file)
                  }
                }}
                />

                <Form.Label style={{display:showFileInput ? 'none':'block'}}>{modules[currentModule]?.audioLink !== 'NA' ?  modules[currentModule]?.audioLink?.name : modules[currentModule]?.videoLink !== 'NA'? modules[currentModule]?.videoLink?.name : '' }</Form.Label>
            {/* <ModuleAudio
               
       
                SetAudioFile={setAudioFile}
    
                AudioFile={audioFile}
                />  */}
            </Grid>
            </Grid>

             
                 <Grid className="mt-4" container>
                  <Grid sx={{alignSelf:'top'}} item xs={3}>
             <Typography variant='subtitle2'><b>Content:</b></Typography>
            </Grid>

            <Grid item xs={9}>
            <CKEditor
        editor={ClassicEditor}
        data={editorData}
        onChange={(event, editor) =>{setShouldRunEffect(true); setEditorData(editor.getData())}}
        config={customConfiguration}
        onReady={(editor) => {
          // You can handle the editor instance here
          console.log('Editor is ready to use!', editor);
        }}
       
      />

            </Grid>
            <ProgressModal Progress={progress} Open={openProgress}/>
            </Grid>
            
                
             </Paper>
            </Grid>

            


            <Grid className="p-2" xs={4} item >
             <Paper className="p-2">
             <Box sx={{maxHeight:'400px', overflow:'scroll'}}>
                {modules.map((i,index)=>{
                    
                    return <ModuleListItem
                    // Disabled={i.id === modules[currentModule].id ? true: false}
                    Selected={i.id === modules[currentModule].id ? '#1A76D242':'white'}
                    OnClick={() => {
                      setShouldRunEffect(false)
                      setCurrentModule(index)
                      setModuleTitle(modules[index].moduleTitle)
                      setModuleType(modules[index].type)
                      setEditorData(modules[index].content)
                      setSelectedFile(modules[index].moduleType === 'audio'? modules[index].audioLink : modules[index].videoLink )
                      if(modules[index].audioLink !== 'NA' || modules[index].videoLink !== 'NA'){
                        setShowFileInput(false)
                      } else {
                        setShowFileInput(true)
                      }
                    
                    
                 
                    }}
                    OnClickDelete={()=>handleRemoveItem(i.id)}
                    ModuleNumber={<div style={{display:'inline'}}><Typography variant="subtitle2"><b>Module {index + 1}: {i.moduleTitle.substring(0,60)}</b></Typography><Typography sx={{}} variant="caption">{i.type === "video" ?'Video' :i.type === 'audio' ? 'Audio' : 'Text'} Module</Typography></div>}
                  
                    /> 
                })}
                <div ref={modList} />
                </Box>
                <Stack sx={{textAlign:'center'}}>
                <Button
                sx={{fontSize:20, marginTop:'10px'}}
                variant="contained"
                
                onClick={()=>{
                  const newObj = {
                    id:uuid(),
                    moduleTitle:'',
                    type: 'text',
                    videoLink: 'NA',
                    audioLink: 'NA',
                    file:'',
                    content:''
                  }
                  const newArray = [...modules, newObj];

                  setModules(newArray)
                  
                  
                
                  setModuleTitle('');
                  handleClearFile();
                  setEditorData('')
                  setModuleType('text')
                  setCurrentModule(modules.length)
                  setModulesUpdate(!modulesUpdate)
               }}>+</Button>
                <Button
                sx={{fontSize:14, marginTop:'10px'}}
                variant="contained"
                onClick={()=>{
               
                // console.log(modules)
                setOpenProgress(true)
                updateProgress()
                
               }}>Save and Build Quiz</Button>
               {/* <Button
               onClick={()=>{
                
                let conf = window.confirm('If you select this option the course will not be active until you build a quiz for the course. You can always comeback and build a quiz using the edit button on the course card.')
               if(conf){
                setShowProgress(true)
                UploadModules(modules, trainingId, data.id,data.email, updateProgressArray, true)
               }
                
               }}
               sx={{ marginTop:'10px'}}
               variant="contained"
               >Save and Exit</Button> */}

             
                </Stack>
          
             </Paper>
            </Grid>

      
        </Grid>
        </Box>
    )
}
import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';




export const VideoCard = (props)=>{

    return (
        <Card onClick={props.ClickCard} sx={{ width:'320px', height:'175px', margin:'10px'}}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="175"
          width='350'
          image={props.Image}
          alt="green iguana"
        />
    
      </CardActionArea>
   
    </Card>
    )
}
import React, { useState } from "react";
import Menu from '@mui/material/Menu';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import { DashboardNavButtons } from "../components/DashboardNavButtons";
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import logo from '../assets/css/images/icon6.png'
import { VideoTrainingLibrary } from "./VideoTrainingLibrary";
import { AudioTrainingLibrary } from "./AudioTrainingLibrary";
import { users } from "../data/users";
import { Button } from "@mui/material";
import NotificationListItem from "../components/NotificationListItem";
import { Profile } from "./Profile";
import { CertficateGenerator } from "./CertificateGenerator";
import { ProfileBasic } from "./ProfileBasic";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link target='_blank' color="inherit" href="https://employeetrainingtoolkit.com/">
        Employee Training Tool-Kit
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme();

export const BasicHome = ()=> {
  const [open, setOpen] = React.useState(true);
  const [pageTitle, setPageTitle] = useState('Dashboard');
  const [view, setView] = useState(<VideoTrainingLibrary/>)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notificationList, setNotificationList] = useState('')
  const openNotifications = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };


  const deleteNotification = (a)=>{
   let filter = notificationList.filter(i=>{return a !== i.id})

   setNotificationList(filter)
  }


  const deleteAllNotifications = (a)=>{
    setNotificationList([])
 
   
   }
 
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex'}}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed,
              backgroundImage:'linear-gradient(#37b6e8, #528EE0)'
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="button"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1, fontSize:18  }}
            >
              {pageTitle}
            </Typography>
            
            <IconButton
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={()=>{setView(<ProfileBasic/>); setPageTitle('PROFILE')}}
            color="inherit">
                <AccountBoxIcon />
            </IconButton>
            <Menu
       
        anchorEl={anchorEl}
        open={openNotifications}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{maxHeight:'500px', overflowY:'scroll'}}
      >
        <Button sx={{float:'right', display: (notificationList.length < 0) ? 'none':'block',fontSize:12, margin:0, padding:0}} onClick={()=>deleteAllNotifications()} >Clear</Button>
        {(notificationList.length > 0)? notificationList.map((i)=>{
          
           return (
           <div>
           <NotificationListItem
           Name={<div><Typography variant="overline">{i.name}</Typography><Typography sx={{float:'right'}} variant="overline" >{i.dateCompleted}</Typography></div>}
           Message={`Completed ${i.courseName}.`}
           ClickDelete={()=>deleteNotification(i.id)}
           />
           <hr style={{margin:-1}}></hr>
           </div>
           )
        }): <Typography sx={{width:'300px', textAlign:'center'}}>No current notifications.</Typography>}
       
      </Menu>
          </Toolbar>
        </AppBar>
        <Drawer
        PaperProps={{
          sx:{
            backgroundImage:'linear-gradient(#37b6e8, #528EE0)'
          }
        }}
        variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              border:'none',
              px: [1],
            }}
          >
             <div style={{textAlign:'center', padding:'10px', right:0}}>
          <img src={logo} style={{marginRight:open?'30px':0, marginTop:open?2:4}} width={open? 150:50}/>
          </div>
            <IconButton sx={{position: 'absolute', top:'10px'}} onClick={toggleDrawer}>
              <ChevronLeftIcon sx={{color:"white" }}/>
            </IconButton>
          
           
          </Toolbar>
        
          <Divider />
          <List sx={{border:'none', paddingTop:0}} component="nav">
         
            {/* <Divider sx={{ marginBottom:'5px' }} /> */}
           
            <DashboardNavButtons Click={()=>{setView(<VideoTrainingLibrary/>); setPageTitle('Video Training Library')}} StyleOpen={open} Title="Video Training Library" Icon={<LibraryBooksIcon sx={{color:'white'}}/>} />
            <DashboardNavButtons Click={()=>{setView(<AudioTrainingLibrary/>); setPageTitle('Audio Training Library')}} StyleOpen={open} Title="Audio Training Library" Icon={<AudioFileIcon sx={{color:'white'}}/>} />
            <DashboardNavButtons Click={()=>{setView(<CertficateGenerator/>); setPageTitle('Certificate Generator')}} StyleOpen={open} Title="Certificate Generator" Icon={<WorkspacePremiumIcon sx={{color:'white'}}/>} />

            
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: '#F0F0F1',
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
           {view}
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

const styles = {
  paper: {
    background: "blue"
  }
}
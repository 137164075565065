import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Grid,
  Paper,
  Typography,
  Box,
  Slider,
} from "@mui/material";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import ReactPlayer from "react-player";
import ProgressBar from "react-bootstrap/ProgressBar";
import Iframe from "react-iframe";
import '../../../assets/css/scorm.css'
import { IframePlayer } from "../../../components/Iframe-Player";
const TinyText = styled(Typography)({
  fontSize: "0.75rem",
  opacity: 0.9,
  fontWeight: 500,
  letterSpacing: 0.2,
});

export const Scorm = (props) => {
  const playerRef = useRef(null);
  const [played, setPlayed] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [remainingTime, setRemainingTime] = React.useState(null);
  const [showSeek, setShowSeek] = useState(true);
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [duration, setDuration] = useState(0);
  const [videoError, setVideoError] = useState(null);

  const handleVideoError = (error) => {
    setVideoError(error);
  };

  function formatTime(seconds) {
    const date = new Date(0);
    date.setSeconds(seconds);
    return date.toISOString().substr(11, 8);
  }

  const handleSeekTo = (event) => {
    const seconds = parseFloat(event.target.value);
    setPlayed(seconds);
    playerRef.current.seekTo(seconds);
  };

  const handleProgress = (progress) => {
    setPlayed(progress.played);
    setPlayedSeconds(progress.playedSeconds);
    const duration = playerRef.current.getDuration();
    const remaining = duration - playedSeconds;
    setRemainingTime(remaining);
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const play = () => {
    setPlaying(!playing);
  };

  const openVolumeMenu = (event) => {
    // handle volume menu open
  };

  const handleClose = () => {
    // handle volume menu close
  };

  const handleVolumeChange = (e) => {
    setVolume(parseFloat(e.target.value));
    playerRef.current.volume = e.target.value;
  };

  return (
     
    <Grid
      container
      spacing={2}
      sx={{ marginTop: 5, display: "flex", width: "100%",  margin: 0 }}
    >
      <Grid item xs={12} sm={8}>
        <Card className="player-card" sx={{ padding: 2, width: "100%", height: "500px", maxHeight:"500px" }}>
       
       
          <div style={{ position: "relative", height:'100%'}}>
        
          <Iframe 
        url={props.VideoLink}
        width='100%'
        height='100%'
       
        id=""
        className=""
      
        scrolling='auto'
        allowtransparency='true'
        allowFullScreen='1'
        
        />
  
        
    
          </div>
          <Box
            sx={{
       
              marginTop:2
            }}
          >
            <Slider
           
              min={0}
              max={1}
              step={0.01}
              value={played}
              onChange={handleSeekTo}
              style={{ paddingLeft: "0", paddingRight: "0" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: -2,
            }}
          >
            <TinyText sx={{ marginTop: showSeek ? "10px" : "0px" }}>
              {formatTime(playedSeconds)}
            </TinyText>
            <TinyText sx={{ marginTop: showSeek ? "10px" : "0px" }}>
              -{formatTime(remainingTime)}
            </TinyText>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4} sx={{ display: "flex", width: "100%" }}>
        
        <Paper className="custom-paper" sx={{ padding: 2, height: "500px", maxHeight:'500px', width: "100%", display: "flex", flexDirection: "column" }}>
  <Typography variant="button" sx={{ marginBottom: 0 }}>
    <b>{props.ModuleTitle}</b>
  </Typography>
  <hr />
  <div
    dangerouslySetInnerHTML={{ __html: props.ModuleDescription }}
    style={{ height: '100%', overflow: "auto" }}
  ></div>
</Paper>
      </Grid>
    </Grid>
  );
};

import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Form from "react-bootstrap/Form";
import { courseData } from "../data/CourseData";
import { audioCourseData } from "../data/CourseData";
import { formatDate, generateDocument } from "../classes/global-functions";
import { Row, Col } from 'react-bootstrap';



export const CertficateGenerator = ()=>{
    const [ learnersName, setLearnersName] = useState('')
    const [ course, setCourse] = useState('')
    const [ issuer, setIssuer] = useState('')
    const [ issuerTitle, setIssuerTitle] = useState('')
    return (
        <Box className="p-3">
            <Paper className="p-3">
            
               <Typography sx={{fontSize:18}} variant="button"><b>Students Name</b></Typography>
               <hr></hr>
               <Typography  className="mb-2">Enter the learners name.</Typography>
               <Form.Control onChange={(e)=>setLearnersName(e.target.value)} value={learnersName}/>

               <hr></hr>
               <Typography sx={{fontSize:18}} variant="button"><b>Course</b></Typography>
               <hr></hr>
               <Typography  className="mb-2">Select the course you would like to make certifiicates for.</Typography>
               <Form.Select onChange={(e)=>setCourse(e.target.value)}>
                <option>---</option>
                <optgroup label="Video Courses">
                   {courseData.map((i)=>{
                        return <option value={i.courseName}>{i.courseName}</option>
                    })}
                </optgroup>
                <optgroup label="Audio Courses">
                {audioCourseData.map((i)=>{
                        return <option value={i.courseName}>{i.courseName}</option>
                    })}
                </optgroup>
               </Form.Select>
               <hr></hr>
               <Typography sx={{fontSize:18}} variant="button"><b>Issuer Name and Title</b></Typography>
               <hr></hr>
               <Typography  className="mb-2">Enter the name and title of the person issuing the certifiicate.</Typography>
               <Row>
                <Col md={6}>
                <Form.Group controlId="input1">
                    <Typography variant="button"><b>Presenter Name:</b></Typography>
                    <Form.Control onChange={(e)=>setIssuer(e.target.value)} value={issuer}/>
                </Form.Group>
                </Col>
                <Col md={6}>
                <Form.Group controlId="input2">
                <Typography variant="button"><b>Presenter Title:</b></Typography>
                    <Form.Control onChange={(e)=>setIssuerTitle(e.target.value)} value={issuerTitle}/>
                </Form.Group>
                </Col>
            </Row>
               <Box sx={{textAlign:'end'}}>
               <Button onClick={()=>{
                let obj = {
                    student_name: learnersName,
                    course_name: course,
                    date: formatDate(new Date),
                    instructor: issuer,
                    presenter_title: issuerTitle
                }
                
                generateDocument(obj, learnersName)}} variant="contained" className="mt-3"><b>Generate Certificate</b></Button>
               </Box>
            </Paper>
        </Box>
    )
}
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Grid,
  Paper,
  Typography,
  Box,
  Slider,
  IconButton,
} from "@mui/material";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import ReactPlayer from "react-player";
import { ProgressBar } from "react-bootstrap";
import axios from "axios";
import { API } from "../../../controllers/API";
import ToolTipButton from "../../../components/TooltipButton";
import SaveIcon from '@mui/icons-material/Save';
import Replay10Icon from '@mui/icons-material/Replay10';
import Forward10Icon from '@mui/icons-material/Forward10';

const TinyText = styled(Typography)({
  fontSize: "0.75rem",
  opacity: 0.9,
  fontWeight: 500,
  letterSpacing: 0.2,
});

export const SingleVideoDemo = (props) => {
  const playerRef = useRef(null);
  const [played, setPlayed] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(props.Learner.progress);
  const [remainingTime, setRemainingTime] = React.useState(null);
  const [showSeek, setShowSeek] = useState(JSON.parse(localStorage.getItem('seek-option')));
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [duration, setDuration] = useState(0);
  const [videoError, setVideoError] = useState(null);
  const [progress, setProgress] = useState(props.Learner.percentage); // Adding progress state
const [initialProgress, setInitialProgress] = useState(props.Learner.percentage)
  const handleVideoError = (error) => {
    setVideoError(error);
  };

  const onEnded = async ()=>{
    // props.Learner.progress = playedSeconds;
    // try {
    //   props.Learner.progress = playedSeconds; // Update the playedSeconds in the Learner object
    //   const resp = await axios.post(API + 'update-progress', {
    //     userId: props.userId,
    //     trainingId: props.trainingId,
    //     learnerId: props.Learner.id,
    //     playedSeconds: playedSeconds,
    //     percentage: Math.round(progress),
    //   });

    //   if (resp.data === 'error') {
    //     alert('Something went wrong and your progress was not saved.');
    //   } else {
    //   }
    // } catch (error) {
    //   console.error('Error saving progress:', error);
    // }
  }

  useEffect(() => {
    if (parseInt(progress) === 100) {
      onEnded()
      props.ShowQuiz() // Call the function when progress reaches 100%
    }
  }, [progress]);



  function formatTime(seconds) {
    const date = new Date(0);
    date.setSeconds(seconds);
    return date.toISOString().substr(11, 8);
  }

  const handleSeekTo = (event) => {
    const seconds = parseFloat(event.target.value);
    setPlayed(seconds);
    playerRef.current.seekTo(seconds);
  };
  
 useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (progress < 99) {
        const confirmationMessage = "You have unsaved changes. Are you sure you want to leave?";
        event.preventDefault();
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [progress]);

  useEffect(() => {
    const fetchProgress = async () => {
      try {
        const loadedProgress = props.Learner.percentage;
        setProgress(loadedProgress);
        playerRef.current.seekTo(loadedProgress);
      } catch (error) {
        console.error('Error fetching progress:', error);
      }
    };

    fetchProgress();
  }, []);

  const saveProgress = async () => {
    alert('This operation is inactive in a demo.')
    };
  

  const handleProgress = (progress) => {
    const calculatedProgress = (progress.played * 100).toFixed(2);
    setProgress(calculatedProgress);
    setPlayed(progress.played);
    setPlayedSeconds(progress.playedSeconds);
    const duration = playerRef.current.getDuration();
    const remaining = duration - progress.playedSeconds;
    setRemainingTime(remaining);
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const play = () => {
    setPlaying(true);
    console.log('play');
    const savedProgress = props.Learner.progress;
    setPlayed(savedProgress);
    playerRef.current.seekTo(savedProgress);
    console.log(savedProgress);
  };


  const openVolumeMenu = (event) => {
    // handle volume menu open
  };

  const handleClose = () => {
    // handle volume menu close
  };

  const handleVolumeChange = (e) => {
    setVolume(parseFloat(e.target.value));
    playerRef.current.volume = e.target.value;
  };

  const handleSeekBackward = () => {
    const seekTime = Math.max(0, playerRef.current.getCurrentTime() - 10);
    playerRef.current.seekTo(seekTime);
  };

  // Function to handle seeking 15 seconds forward
  const handleSeekForward = () => {
    const seekTime = Math.min(playerRef.current.getDuration(), playerRef.current.getCurrentTime() + 10);
    playerRef.current.seekTo(seekTime);
  };

  return (
    <Box>
      <Grid container>
        <Grid xs={11} sm={11} item>
        <Typography sx={{fontSize:'18px'}} variant="button">
         <b>  {props.CourseTitle}</b> 
        </Typography>
        </Grid>

        <Grid sx={{textAlign:'right'}}  xs={1} sm={1} item>
          <ToolTipButton 
          Placement="left"
          Title={<Typography sx={{fontSize:12}} variant="button"><b>SAVE PROGRESS</b></Typography>}
          onClick={saveProgress}
          Icon={<SaveIcon htmlColor="white"/>}
          style={{backgroundColor:'#1B76D2'}}
          />
        
        </Grid>
      </Grid>
       
    <Grid
      container
      spacing={2}
      sx={{ marginTop: 5, display: "flex", width: "100%",  margin: 0 }}
    >
      <Grid item xs={12} sm={8}>
        <Card sx={{ padding: 2, width: "100%", height: "100%" }}>
          <div style={{ position: "relative", paddingTop: "56.25%" }}>
            <ReactPlayer
              ref={playerRef}
              playing={playing}
              onError={handleVideoError}
              controls={true}
              onProgress={handleProgress}
              onPause={() => {setPlaying(false);}}
              onPlay={() => play()}
              volume={volume}
              url={props.VideoLink}
              width="100%"
              height="100%"
              style={{ position: "absolute", top: 0, left: 0 }}
              onDuration={handleDuration}
            />
          </div>
          <Box
            sx={{
              marginTop: 2
            }}
          >
            <Slider
            sx={{display:props.ShowSeek === true ? 'none':'block'}}
              min={0}
              max={1}
              step={0.01}
              value={played}
              onChange={handleSeekTo}
              style={{ paddingLeft: "0", paddingRight: "0" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: -2,
            }}
          >
            <TinyText sx={{ marginTop: showSeek ? "10px" : "0px" }}>
              {formatTime(playedSeconds)}
            </TinyText>
            <TinyText sx={{ marginTop: showSeek ? "10px" : "0px" }}>
              -{formatTime(remainingTime)}
            </TinyText>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <IconButton onClick={handleSeekBackward}>
            <Replay10Icon htmlColor="#1B76D2"/>
            </IconButton>
            <Button variant="contained" onClick={() => setPlaying(!playing)}>
              {playing ? 'Pause Training' : initialProgress > 0 ? 'Resume Training': 'Start Training'}
            </Button>
            <IconButton variant="outlined" onClick={handleSeekForward}>
            <Forward10Icon htmlColor="#1B76D2"/>
            </IconButton>
          </Box>
      
        </Card>
      </Grid>
      <Grid item xs={12} sm={4} sx={{ display: "flex", width: "100%" }}>
        <Paper
          sx={{
            padding: 2,
            height: "500px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="button" sx={{ marginBottom: 0 }}>
            <b>{props.ModuleTitle}</b>
          </Typography>
          <hr />
          <div
            dangerouslySetInnerHTML={{ __html: props.ModuleDescription }}
            style={{ height: "100%", overflow: "auto" }}
          ></div>
        </Paper>
      </Grid>
     
    </Grid>
     <Box sx={{display:initialProgress === 0 ? 'block': 'none'}}>
     <hr />
     <Typography variant="button">Training Progress: </Typography>
     <Typography variant="button">
       <b>{Math.round(progress)}%</b>
     </Typography>
     <ProgressBar defaultValue={progress} now={progress} />

     <hr />
   </Box>

   <Box sx={{display:initialProgress !== 0 ? 'block': 'none'}}>
     <hr />
     <Typography variant="button">Training Progress: </Typography>
     <Typography variant="button">
       <b>{Math.round(initialProgress)}%</b>
     </Typography>
     <ProgressBar now={initialProgress} />

     <hr />
   </Box>

   </Box>
  );
};

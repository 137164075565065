import React from "react";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from "@mui/material/Tooltip";


export const DashboardNavButtons = (props)=>{

    return (
      <Tooltip title={props.TooltipTitle} placement="right">
        <ListItemButton onClick={props.Click}  sx={{fontSize:'16px'}}  divider>
        <ListItemIcon sx={{minWidth: props.StyleOpen ?'40px': '60px'}}>
          {props.Icon}
        </ListItemIcon>
        <ListItemText primary={<span style={{fontSize:'14px', color:'white'}}><b>{props.Title}</b></span>} secondary={props.SecondText} />
      </ListItemButton>
      </Tooltip>
    )
}
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DocumentChip } from "./DocumentChip";



export const UploadDocuments = (props) =>{

 const [update, setUpdate] = useState(false)
 
 useEffect(()=>{
console.log('added file')
 },[update])
 function isFile(variable) {
  return variable instanceof File;
}

    
    return (
        <Box>
         <InputGroup>
        <Form.Control id="file-upload" onChange={(e)=>{
           if(e.target.files[0].size >  50000000){
            window.alert('File size is too big. Size should be under 50mb.')
            e.target.value = '';
          } else {
          props.SetCurrentDocument(e.target.files[0]);
         } }} type='file'/>
       <Button onClick={()=>{
        if(document.getElementById('file-upload').value != ''){
        props.Documents.push(props.CurrentDocument)
        console.log(props.Documents)
        setUpdate(!update)
        // handleUploadDocument()
        document.getElementById('file-upload').value = '';
        } else {
          window.alert('Please add a file to the input.')
        }
       }} variant="contained"><b>Add</b></Button>
        </InputGroup>   
        <Typography variant='caption'><b>Note: You can upload more than one.</b></Typography>
       <br></br>
       <Box sx={{display:'inline'}}>
        {props.Documents.map((i,index)=>{
          return <DocumentChip OnDelete={()=> props.DeleteDocument(i.id, isFile(i) ? i : i.documentName)} Key={index} Label={i.name ? i.name.substring(i.name.lastIndexOf('/')+1).replace(/%20/g, " "): i.documentName.substring(i.documentName.lastIndexOf('/')+1).replace(/%20/g, " ")}/>
        })}
       </Box>

        </Box>
        
    )

}
import React,{useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Modal from 'react-bootstrap/Modal';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import singleUserIcon from '../assets/css/images/single_user.png'
import '../assets/css/learners.css'
import { AddSingleLearnerForm } from "../components/learners/AddSingleLearnerForm";
import { AddMultipleLearners } from "../components/learners/AddMultipleLearners";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {Table} from "react-bootstrap";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Papa from 'papaparse'
import { users } from "../data/users";
import { EditLearnerForm } from "../components/learners/EditLearnerForm";
import axios from "axios";
import { API } from "../controllers/API";
import uuid from "react-uuid";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import SearchBox from "../components/SearchInput";
import { CircularProgress } from "@mui/material";



export const Learners = ()=>{
  const data = JSON.parse(sessionStorage.getItem('profile-data'))
  const navigate = useNavigate();
  const [update, setUpdate] = useState(true)
    const [show, setShow] = useState(false);
    const [showMenu, setShowMenu] = useState(true);
    const [displaySingle, setDisplaySingle] = useState(false);
    const [displayMultiple, setDisplayMultiple] = useState(false);
    const[mainData, setMainData] = useState({learners:[]})
    const [categories, setCategories] = useState(data.learnerCategories)
    const [newCategory, setNewCategory] = useState('')
    const [showCatInput, setShowCatInput] = useState(false)
    const [sortBy, setSortBy] = useState('first name')
    const [loading, setLoading] = React.useState(true);
    const [employeeMax, setEmployeeMax] = useState(0)
  
    //Add Single Learner Variables
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [learnerCategory, setLearnerCategory] = useState('')
    const [currentAmmount, setCurrentAmmount] = useState(0)

    // Add Mulitple Learners functions and variables
    const[ result, setResult] = useState([])
    const[ displayAddBtn, setDisplayAddBtn] = useState(false)
    const [displayBack, setDisplayBack] = React.useState('none');
    const[ addMultipleEmployeeDisplay, setAddMultipleEmployeeDisplay] = useState(false)
    const[ uploadSelectDisplay, setUploadSelectDisplay] = useState(true)

    const addEmployees = async ()=>{
      console.log(data.id)
      
      
 
      if(currentAmmount + result.length > employeeMax) 
      {
         window.alert('If you upload these learners it will exceed the ammount of learners for this account.')
      }
      else 
    {
        
        
    let resp = await  axios.post(API+'add-multiple-learners',{id:data.id, learners:result})
    console.log(resp.data)
    if(resp.data === 'learners added'){
      handleClose()
      setDisplayBack('none')
      setAddMultipleEmployeeDisplay(false)
      setUpdate(!update)
      setShowMenu(true)
      setResult([])
      setDisplayMultiple(false)
    }
    
  }

    // handleClose()
    }
    
    const changeHandler = (event) => {
      console.log(event.target.files[0])
      Papa.parse(event.target.files[0], {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            console.log(results.data)
             let arr = []
    
             results.data.forEach(i=>{
              let group = i.Group.toLowerCase();
               console.log(group)
              let groupFinal = capitalizeFirstLetter(group)
             
                  arr.push({
                      id: uuid(),
                      firstName:i.FirstName,
                      lastName: i.LastName,
                      name: `${i.FirstName} ${i.LastName}`,
                      email: i.Email,
                      group:groupFinal
    
                  })
                
             })
            if(arr.length === results.data.length){
              console.log(arr.length)
              setResult(arr)
              setDisplayAddBtn(true)
            } else{
    
            }
       
    
            console.log(arr.length)
          },
        });
    };

    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
///
//REMOVE LEARNER
const removeLearner = (a)=>{
  const conf = window.confirm('This action will delete this person and all of their review data. Are you sure you would like to do this?')
  if(conf){
   
   axios.post(API+'delete-learner',{userId:data.id, learnerId: a}).then((response)=>{
   return response
  


     }).then((response)=>{
       console.log(response.data)
       setMainData(response.data[0])
       setUpdate(!update)
     })

  }
 }

 //EDIT LEARNER
 const EditLearner = async ()=>{

  let resp = await axios.post(API+'edit-learner',{
    userId: data.id,
    id: editId,
    firstName: editFirstName,
    lastName: editLastName,
    name: editFirstName + ' ' + editLastName,
    email: editEmail,
    group: editCategory
  })

  console.log(resp.data)
if(resp.data === 'error'){
   window.alert('The learner was not updated. Please try again.')
} else{
  window.alert(`The update for ${editFirstName + ' ' + editLastName} was successful!`)
  setUpdate(!update)
  handleCloseEdit()
}
 }


// Initial Load and Update
    useEffect(()=>{
      async function fetchData() {
     const response = await axios.post(API + 'get-data',{id:data.id})
     
     
            console.log(response.data)
            if(response.data.membershipType === 'Employee Training Tool-kit - Pro'){
              setEmployeeMax(150)
              console.log(employeeMax)
            }

            if(response.data.membershipType === 'Employee Training Tool-kit - Basic'){
              setEmployeeMax(50)
            }

            if(response.data.membershipType === 'Employee Training Tool-kit - Enterprise'){
              setEmployeeMax(99999)
            }
            setCurrentAmmount(response.data.learners.length)
            setMainData(response.data)
            setCategories(response.data.learnerCategories)
            setLoading(false)
          
      }
      fetchData()
    },[update])

    //Edit State Variables
    const [editFirstName, setEditFirstName] = useState('');
    const [editLastName, setEditLastName] = useState('');
    const [editEmail, setEditEmail] = useState('');
    const [editCategory, setEditCategory] = useState('');
    const [editId, setEditId] = useState('')
    const [showEdit, setShowEdit] = useState(false);
    const [searchValue, setSearchValue] = useState('')
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const LoadEditLearner = (a)=>{
      let filter = mainData.learners.filter(i =>{return (i.id === a)})
       setEditFirstName(filter[0].firstName)
       setEditLastName(filter[0].lastName)
       setEditEmail(filter[0].email)
       setEditCategory(filter[0].group)
       setEditId(a)
       handleShowEdit()
      //Tie to API


    }


    const AddNewCategory = async ()=>{
      if(newCategory !== ''){
        let arr = mainData.learnerCategories.map(v => v.cat.toLowerCase());
        console.log(arr)
        if(arr.includes(newCategory.toLowerCase())){
          window.alert('This category has already been added.')
          setNewCategory('')
        } else {
        

          const finalSentence = newCategory.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());

           console.log(finalSentence)
          const resp = await axios.post(API + 'add-learner-category', {id:data.id, category: {
            id: uuid(),
            cat: finalSentence
          }})
         if(resp.data === 'error'){

          alert('Something went wrong. Please try again.')
         } else {
          setCategories(resp.data.learnerCategories)
         setUpdate(!update)
          setShowCatInput(false)
          setEditCategory(newCategory)
          alert('Category was added. If you would like to remove any categories you can go to the settings section and do so and then edit the learners category to assign a new category.')
          setNewCategory('')

         }
        }
      

    } else {
      alert('No category was added')
      setShowCatInput(false)
      setNewCategory('')
    }
    }

    const handleSortChange = (event) => {
      setSortBy(event.target.value);
      console.log(sortedData)

    };

    const sortedData = mainData.learners.slice().sort((a, b) => {
      if (sortBy === "last name") {
       
        return (a.lastName > b.lastName) ? 1 : ((b.lastName > a.lastName) ? -1 : 0)

      } else if (sortBy === "first name") {
        return (a.firstName > b.firstName) ? 1 : ((b.firstName > a.firstName) ? -1 : 0)
      } else if (sortBy === "group") {
        return (a.group > b.group) ? 1 : ((b.group > a.group) ? -1 : 0)
      }
    });


    
 
    if(!loading){
      
    
    return (
       <div>
        <div style={{textAlign:'end'}}>
         <Button variant="contained" onClick={handleShow}>
      <b>Add Learners +</b>
      </Button>
      <br></br>
   
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop:3 }}>
      <Form.Select style={{width:'200px'}} value={sortBy} onChange={(e) => handleSortChange(e)}>
        <option>---sort---</option>
        <option value='last name'>Last Name</option>
        <option value='first name'>First Name</option>
        <option value='group'>Group</option>
      </Form.Select>
    
        <SearchBox
      onChange={(e)=>setSearchValue(e.target.value)}
      value={searchValue}
      placeholder="Search"
      />
    </Box>
  
      <TableContainer sx={{padding:'10px', marginTop:3}} component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
<TableHead>
          <TableRow>
   
          <TableCell><b>Name</b></TableCell>
          <TableCell><b>Email</b></TableCell>
          <TableCell><b>Group</b></TableCell>
          <TableCell align="center"><b>Actions</b></TableCell>
          </TableRow>
        </TableHead>
      <TableBody>

        {
          sortedData.filter((i) =>
          i.lastName.toLowerCase().includes(searchValue.toLowerCase()) ||
          i.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
          i.email.toLowerCase().includes(searchValue.toLowerCase()) ||
          i.group.toLowerCase().includes(searchValue.toLowerCase())
        ).map(i=>{
            let trainings = []
            mainData.activeTrainings.forEach((o)=>{
              // console.log(o)
                 let filter = o.learners.filter(e=>{
                  return i.id === e.id
                 })
                //  console.log(filter)
                 filter.forEach(f=>{
                  trainings.push(
                    {
                      learner:f,
                      training:o
                    }
                    )
                 })
                
            })

            mainData.archivedTrainings.forEach((o)=>{
              // console.log(o)
                 let filter = o.learners.filter(e=>{
                  return i.id === e.id
                 })
                //  console.log(filter)
                 filter.forEach(f=>{
                  trainings.push(
                    {
                      learner:f,
                      training:o
                    }
                    )
                 })
                
            })
            
            return (
            <TableRow>
            <TableCell>{i.firstName} {i.lastName}<IconButton title='Edit Learner' sx={{padding:0.5}} onClick={()=>LoadEditLearner(i.id)}><EditIcon htmlColor='#43A7E5' sx={{cursor:'pointer', width:'20px'}} /></IconButton></TableCell>
            <TableCell>{i.email}</TableCell>
            <TableCell>{i.group}</TableCell>
            <TableCell align="center">
            <Tooltip title="Learner Details"><IconButton onClick={()=>{
              console.log(trainings)
              if(trainings.length > 0) {
                navigate('/learner-details', { replace: true, state:{trainings} })

              } else {
                window.alert('This person has no training data yet.')
              }
                          }} sx={{padding:0.5}}> <InfoIcon  htmlColor='#3FA9E6' sx={{cursor:'pointer',width:'30px'}} /></IconButton></Tooltip>
           <Tooltip title="Delete Learner"><IconButton onClick={()=>removeLearner(i.id)}><DeleteIcon htmlColor="tomato"/></IconButton></Tooltip> 
            </TableCell>
            </TableRow>
            // <CollapsibleTableItemLearners
            // Name={`${i.firstName} ${i.lastName}`}
            // Email={i.email}
            // Trainings={trainings}
            // Group={i.category}
            // Edit={()=>LoadEditLearner(i.id)}
            // Delete={()=>removeLearner(i.id)}
            // />
            
            )
          })  
        }
       
        
       </TableBody>
      </Table>
    </TableContainer>

      </div>
      <Modal size="lg" style={{zIndex:100000}} show={show} onHide={handleClose}>
    
        <Modal.Body>
        <div style={{display:showMenu ? 'block': 'none'}}>
        <Row>
        <Col xs={12} md={6}>
        <Card className="card" sx={{display:'block', width: '100%', height:'100%', textAlign:'center', marginTop:'5px'}}>
     
     <CardActions onClick={()=>{setShowMenu(!showMenu); setDisplaySingle(true)}} sx={{display:'block'}}>
       <div style={{display:'block', textAlign:'center'}}>
       <img src={singleUserIcon} width={200} height={200}/>
       <br></br>
       <Typography sx={{ fontSize:20}} variant="button">Add Single Learner</Typography>
       <br></br>
       <Typography sx={{ fontSize:16}} variant="subtitle">Here you can add a single employee by entering their information.</Typography>

       </div>
     </CardActions>
   </Card>
        </Col>
        <Col xs={12} md={6}>
        <Card className="card" sx={{display:'block', width: '100%', height:'100%',textAlign:'center', marginTop:'5px'}}>
     
      <CardActions onClick={()=>{setShowMenu(!showMenu); setDisplayMultiple(true)}} sx={{display:'block'}}>
        <div style={{display:'block', textAlign:'center'}}>
        <img src={singleUserIcon} width={200} height={200}/>
        <br></br>
        <Typography sx={{ fontSize:20}} variant="button">Add Multiple Learners</Typography>
        <br></br>
        <Typography sx={{ fontSize:16}} variant="caption">You can upload multiple learners by uploading a CSV file.</Typography>

        </div>
      </CardActions>
    </Card>
        </Col>
      </Row>
      </div>

      <div style={{display:showMenu ? 'none':'block'}}>
       <Button sx={{paddingLeft:0}} onClick={()=>{setShowMenu(!showMenu); setDisplayMultiple(false); setDisplaySingle(false)}}  variant="text"><ChevronLeftIcon/>Back</Button>
       <AddSingleLearnerForm 
       PersonsFirstNameValue={firstName}
       PersonsFirstName={(e)=> setFirstName(e.target.value)}
       PersonsLastNameValue={lastName}
       PersonsLastName={(e)=>{setLastName(e.target.value)}}
       PersonsEmailValue={email}
       PersonsEmail={(e)=>{setEmail(e.target.value)}}
       CatSelectValue={editCategory}
         ChangeCatSelect={(e)=>setEditCategory(e.target.value)}
      
       Categories={categories.map(i=>{
        return (<option value={i.cat}>{i.cat}</option>)
       })}
         ShowCatInput={showCatInput ? 'flex' : 'none'}
         OnclickShowCatInput={()=>setShowCatInput(true)}
         CloseCategoryClick={()=>setShowCatInput(false)}
       Display={displaySingle?'block':'none'}
       OnChangeCategoryInput={(e)=> {setNewCategory(e.target.value); console.log(newCategory)}}
       AddCategoryClick={()=>AddNewCategory()}
       CategoryChangeValue={newCategory}
       OnSubmit={async()=>{

    

        console.log('current Ammount:'+ currentAmmount)
        if((currentAmmount + 1) > employeeMax){
          window.alert('Adding this learner will exceed the ammount of learners you are allowed to have with this memebership level.')
        } else {
          
        console.log(firstName + " " + lastName + " " + email)
        console.log(newCategory)
        let learnerObject = {
          id: uuid(),
          firstName: firstName,
          lastName: lastName,
          name: `${firstName} ${lastName}`,
          email:email,
          group:editCategory
        }
       

       const response = await axios.post(API+'add-learner', {id:data.id, learner: learnerObject})

       if(response.data == 'learner added'){
         setUpdate(!update)
         setShowMenu(true)
         setLastName('')
         setFirstName('')
         setEmail('')
         setShowMenu(true); 
         setDisplayMultiple(false); 
         setDisplaySingle(false)
         handleClose()
       }
        console.log(learnerObject)
       }}

      }
       />
       <AddMultipleLearners 
       Display={displayMultiple?'block':'none'}
       OnFileChange={changeHandler}
       AddEmployees={addEmployees}
       TableBody={result}
       ClickClear={()=>setResult([])}
       AddDisplayBtn={displayAddBtn ? 'block': 'none'}
       />


      </div>

   
        </Modal.Body>
        
      </Modal>

      <Modal size="lg" style={{zIndex:100000}} show={showEdit} onHide={handleCloseEdit}>
     
        <Modal.Body>
         <EditLearnerForm
         ChangePersonsFirstName={(e)=>setEditFirstName(e.target.value)}
         PersonsFirstNameValue={editFirstName}
         ChangePersonsLastName={(e)=>setEditLastName(e.target.value)}
         PersonsLastNameValue={editLastName}
         ChangePersonsEmail={(e)=>setEditEmail(e.target.value)}
         PersonsEmailValue={editEmail}
         CatSelectValue={editCategory}
         ChangeCatSelect={(e)=>setEditCategory(e.target.value)}
         Categories={categories.map(i=>{
          return (<option value={i.cat}>{i.cat}</option>)
         })}
         OnSubmit={()=>EditLearner()}
         OnChangeCategoryInput={(e)=> setEditCategory(e.target.value)}
         CategoryChangeValue={newCategory}
         AddCategoryClick={()=>AddNewCategory()}
         ShowCatInput={showCatInput ? 'flex' : 'none'}
         OnclickShowCatInput={()=>setShowCatInput(true)}
         CloseCategoryClick={()=>setShowCatInput(false)}

         />

   
        </Modal.Body>
        
      </Modal>
       </div>
    )

  } else {
   return <Box sx={{textAlign:'center', marginTop:20}}>
    <CircularProgress/>
    <Typography><b>Loading</b></Typography>
  </Box>
  }
}